import React from "react";
import cx from "classnames";
import { Contract } from "../../../data/dataMerchant";
import { Checkboxes } from "../../../components/interactions/Checkboxes/Checkboxes";
import { Dynamic } from "../../../components/animate/Dynamic";
import { TextInput } from "../../../components/form/TextInput";
import { RequiredValidator } from "../../../components/form/validators/RequiredValidator";
import { ProductType } from "../../../data/models/ContractTypes";
import { allowForBax } from "./Financials";
import styles from "./Financials.module.scss";
import { useTranslation } from "react-i18next";
import { Psp } from "../../../data/dataBusinessRisk";

interface Props {
  contract: Contract;
  setBax: (value: string) => void;
  bax: string;
  setEnableBax: (value: boolean) => void;
  enableBax: boolean;
  businessRisk: Psp;
}

export const Bax: React.FunctionComponent<Props> = (props) => {
  if (!allowForBax(props.contract, props.businessRisk)) {
    return null;
  }

  if (props.contract.productType === ProductType.ACCEPTANCE_INSTORE) {
    return <BaxAcceptanceInstore {...props} />;
  }

  return <BaxWorldlineOne {...props} />;
};

const BaxWorldlineOne: React.FunctionComponent<Props> = ({
  bax,
  setBax,
  setEnableBax,
  enableBax,
}) => {
  const { t } = useTranslation();

  return (
    <div className={cx("m-bottom-10")}>
      <Checkboxes
        className="compact"
        label="Allow BankAxept bank credit card"
        values={enableBax ? ["true"] : []}
        onChange={(values) => {
          if (values.length) {
            setEnableBax(true);
          } else {
            setEnableBax(false);
          }
        }}
        alternatives={[
          {
            value: "true",
            text: t("Yes"),
          },
        ]}
      />

      <Dynamic name={enableBax ? "one" : "two"}>
        {enableBax ? (
          <div className={styles.amex}>
            <TextInput
              label="Enter BankAxept integration number"
              validators={[
                new RequiredValidator("Integration number is required"),
              ]}
              value={bax}
              onChange={(value) => setBax(value)}
            />
          </div>
        ) : null}
      </Dynamic>
    </div>
  );
};

const BaxAcceptanceInstore: React.FunctionComponent<Props> = ({
  bax,
  setBax,
}) => {
  return (
    <div>
      <TextInput
        label="Enter BankAxept integration number"
        validators={[new RequiredValidator("Integration number is required")]}
        value={bax}
        onChange={(value) => setBax(value)}
      />
    </div>
  );
};
