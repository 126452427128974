import { QueryOptions } from "@tanstack/react-query";
import { API } from "../network/API";
import { LinkId } from "./models/ContractTypes";
import { Access, getProxy } from "./proxy";

const BASE_PATH = "/api/merchant";

export interface FinancialData {
  estimatedTransactionsPerYear?: number;
  estimatedTransactionsByCardPerYear?: number;
  estimatedAnnualTurnover?: number;
  estimatedRangeOfTransactionValueMin?: number;
  estimatedRangeOfTransactionValueMax?: number;

  productDescription?: string;
  businessModel?: string;

  // licensed
  licensedEntity?: boolean;
  licensingBodyName?: string;

  // listed
  listedEntity?: boolean;
  listedTickerName?: string;
  listedMarket?: string;
  mcc: string;

  thirdPartyPayments: boolean;
}

enum WhitelistedEndpoint {
  FETCH_FINANCIAL = "fetchFinancial",
}

const Financial = {
  [WhitelistedEndpoint.FETCH_FINANCIAL]: (
    linkId: LinkId
  ): QueryOptions<FinancialData> => ({
    queryKey: Financial.getFinancialKey(linkId),
    queryFn: () => API.get<FinancialData>(`${BASE_PATH}/${linkId}/financial`),
  }),

  getFinancialKey: (linkId: LinkId) => {
    return ["financial", linkId];
  },

  postFinancial: (linkId: LinkId, data: FinancialData) =>
    API.post(`${BASE_PATH}/${linkId}/financial`, data),

  requestVerificationCode: (linkId: LinkId, email: string) =>
    API.post(`${BASE_PATH}/${linkId}/invoice/verify-email`, {
      email,
    }),

  confirmInvoiceVerificationCode: (linkId: LinkId, code: string) =>
    API.post(`${BASE_PATH}/${linkId}/invoice/email`, {
      code,
    }),

  confirmPrimaryEmailAsInvoiceEmail: (linkId: LinkId) =>
    API.post(`${BASE_PATH}/${linkId}/invoice/my-email`),
};

let financial: typeof Financial;

export function dataFinancial(access: Access) {
  if (!financial) {
    financial = getProxy(Financial, access, Object.values(WhitelistedEndpoint));
  }

  return financial;
}
