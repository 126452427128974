import { motion } from "framer-motion";
import React from "react";
import { PropsWithChildren } from "react";
import { useAutoHeightAnimation } from "../../hooks/useAutoHeightAnimation";

interface Props {
  className?: string;
  /**
   * dependencies to watch for changes and trigger heigth animation
   * @default []
   * */
  dependencies?: any[];
  opacity?: boolean;
}

export const AnimateHeightAuto: React.FunctionComponent<
  PropsWithChildren<Props>
> = ({ className, dependencies = [], children, opacity = true }) => {
  const [controls, ref] = useAutoHeightAnimation({
    deps: dependencies,
    opacity,
  });

  return <motion.div animate={controls} {...{ children, className, ref }} />;
};
