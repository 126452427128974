import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Status } from "../../data/types";
import { DEFAULT_TRANSLATION_NAMESPACE } from "../../i18n";
import { Button } from "../interactions/Buttons/Button";
import { TranslationAlternative } from "./TranslationManagement";
import { TranslationManagementOverlayAlternatives } from "./TranslationManagementOverlayAlternatives";
import { ServerError } from "../../network/API";
import { dataTranslation, TranslationText } from "./dataTranslation";
import "./TranslationManagementOverlay.scss";

interface Props {
  selected: string;
  alternatives?: TranslationAlternative[];
  translationText?: TranslationText;
  onClose: (updated: boolean) => void;
  onRefresh: () => Promise<void>;
}

export const TranslationManagementOverlay: React.FunctionComponent<Props> = ({
  selected,
  alternatives,
  translationText,
  onClose,
  onRefresh,
}) => {
  const [status, setStatus] = useState<Status>(Status.DEFAULT);
  const { i18n } = useTranslation();
  const [translatedText, setTranslatedText] = useState<string>(
    translationText?.value ?? selected
  );

  useEffect(() => {
    if (translationText) {
      setTranslatedText(translationText?.value || selected);
    }
  }, [selected, translationText]);

  const handleAddTranslation = () => {
    setStatus(Status.PENDING);
    return dataTranslation
      .addTranslationLabel({
        namespace: DEFAULT_TRANSLATION_NAMESPACE,
        key: selected,
      })
      .then(async () => {
        await onRefresh();
        setStatus(Status.DEFAULT);
      })
      .catch(() => {
        setStatus(Status.DEFAULT);
      });
  };

  const handleUpdateTranslation = (close = false) => {
    if (!translationText) {
      return;
    }

    setStatus(Status.PENDING);
    dataTranslation
      .updateTranslation(translationText.id, translatedText)
      .then(async () => {
        await i18n.reloadResources(i18n.language);
        setStatus(Status.DEFAULT);
        if (close) {
          onClose(true);
        } else {
          onRefresh();
        }
      })
      .catch((error: ServerError<unknown>) => {
        setStatus(Status.DEFAULT);
        console.error(error);
      });
  };

  const translationHasTags = selected.includes("<") || selected.includes("{{");

  if (typeof translationText === "undefined") {
    return (
      <div className="translation-editor-overlay">
        <h4>Edit translation</h4>

        <div className="translation-columns">
          <div>
            <p>No translation available for the selected text</p>
            <Button onClick={handleAddTranslation} status={status}>
              Add translation
            </Button>
          </div>
          {alternatives && (
            <TranslationManagementOverlayAlternatives
              alternatives={alternatives}
              selected={selected}
            />
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="translation-editor-overlay">
      <form onSubmit={(event) => event.preventDefault()}>
        <h4>Edit translation</h4>
        <div className="translation-columns">
          <div>
            <p className="m-bottom-20">{selected}</p>
            <label htmlFor="textValue" className="input input-text m-bottom-10">
              <div className="input-frame">
                <textarea
                  id="textValue"
                  rows={3}
                  value={translatedText}
                  onChange={(e) => {
                    const value = !e.target.value ? "" : "" + e.target.value;
                    setTranslatedText(value);
                  }}
                ></textarea>
              </div>
              <div className="input-messages">
                <div className="input-hint">
                  {translationHasTags
                    ? "Make sure to have the same tags as shown in the original text"
                    : null}
                </div>
              </div>
            </label>

            <div className="button-row">
              {alternatives && (
                <Button
                  status={translatedText ? status : Status.DISABLED}
                  onClick={() => handleUpdateTranslation(false)}
                >
                  Save
                </Button>
              )}
              <Button
                status={translatedText ? status : Status.DISABLED}
                onClick={() => handleUpdateTranslation(true)}
              >
                Save & close
              </Button>
            </div>
          </div>
          {alternatives && (
            <TranslationManagementOverlayAlternatives
              alternatives={alternatives}
              selected={selected}
            />
          )}
        </div>
      </form>
    </div>
  );
};
