import { API } from "../network/API";
import { SigningMethod } from "./dataMerchant";
import { LinkId } from "./models/ContractTypes";
import { Access, getProxy } from "./proxy";

export interface Params {
  salesLocation: string;
  onComplete: string;
  onReject: string;
  onPostpone: string;
}

export enum SigningStatus {
  INITIATED = "INITIATED",
  PENDING = "PENDING",
  ABORTED = "ABORTED",
  ERROR = "ERROR",
  REJECTED = "REJECTED",
  COMPLETED = "COMPLETED",
}

export interface SigningResponse {
  autoStartToken: string;
  orderRef: string;
  code: string;
  status?: SigningStatus;
}

export interface SigningRequest {
  method: SigningMethod;
  onComplete: string;
  onReject: string;
  onPostpone: string;
}

export const Signing = {
  sign: (linkId: LinkId, request: SigningRequest) => {
    return API.post<{ signUrl: string }>(
      `/api/signicat/${linkId}/signapi/sign`,
      request
    );
  },

  poll: (linkId: LinkId) => {
    return API.get<SigningResponse>(`/api/signicat/${linkId}/signapi/poll`);
  },
};

let signing: typeof Signing;

export function dataSigning(access: Access) {
  if (!signing) {
    signing = getProxy(Signing, access, []);
  }

  return signing;
}
