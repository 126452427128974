import React, { useState, useEffect, useCallback } from "react";
import { Associate, AssociateRole, Id } from "../../../../data/dataMerchant";
import { useRecoilValue } from "recoil";
import { useLinkId } from "../../../../hooks/useLinkId";
import { routeState } from "../../../../state/routeState";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AssociateId, Contact } from "../../../../data/models/ContractTypes";
import { PersistentOverlay } from "../../../../components/overlay/PersistantOverlay";
import { Steps } from "../../../../components/steps/Steps";
import { HEADERS } from "../components/SignatoryOverlay";
import { AssociateContactFields } from "../components/forms/AssociateContactFields";
import { AssociateIdFields } from "../components/forms/AssociateIdFields";
import { Done } from "../components/forms/Done";
import { cleanNonDigits } from "../../../../components/utils";
import { dataAssociates } from "../../../../data/dataAssociates";

interface Props {
  id: string | null;
  setId: React.Dispatch<React.SetStateAction<string | null>>;
  associates: Associate[];
  onChange: (associate: Associate) => void;
}

function getDefaultSignee(id: AssociateId) {
  return {
    associateId: id,
    roles: [AssociateRole.SIGNATORY],
    contact: {} as Contact,
    id: {} as Id,
  };
}

export function addRolesToSignee(associate: Associate) {
  const roles = associate.roles.filter((role) => {
    if (AssociateRole.SELECTED_SIGNATORY === role) {
      return false;
    }

    if (AssociateRole.SIGNATORY === role) {
      return false;
    }

    return true;
  });

  return {
    ...associate,
    roles: [
      ...roles,
      AssociateRole.SIGNATORY,
      AssociateRole.SELECTED_SIGNATORY,
    ],
  };
}

function getIdentificationMatches(id: string, associates: Associate[]) {
  const strippedId = cleanNonDigits(id);
  return associates.filter(
    (associate) => associate.id?.nationalPersonId === strippedId
  );
}

export const NewSignatory: React.FunctionComponent<Props> = ({
  id,
  setId,
  associates,
}) => {
  const linkId = useLinkId();
  const queryClient = useQueryClient();
  const { access } = useRecoilValue(routeState);
  const [isOpen, setIsOpen] = useState(!!id);
  const [index, setIndex] = useState<number>(0);
  const [signee, setSignee] = useState<Associate>(
    getDefaultSignee((id || "") as AssociateId)
  );

  useEffect(() => {
    if (id) {
      setSignee(getDefaultSignee((id || "") as AssociateId));
      setIsOpen(true);
    }
  }, [id]);

  const {
    mutate: onSave,
    isLoading: isSaving,
    isError: isSaveError,
    reset,
  } = useMutation(
    () => {
      const matches = getIdentificationMatches(
        signee.id.nationalPersonId || "",
        associates
      );

      if (matches.length > 0) {
        const newSignee = addRolesToSignee(matches[0]);
        return dataAssociates(access).saveAssociate(linkId, newSignee);
      } else {
        const newSignee = addRolesToSignee(signee);
        return dataAssociates(access).saveAssociate(linkId, newSignee, true);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["associates", linkId]);
        setIsOpen(false);
        setTimeout(() => {
          setIndex(0);
          setId(null);
        }, 350);
      },
    }
  );

  const onClose = useCallback(() => {
    setIsOpen(false);
    setId(null);
    setTimeout(() => {
      setIndex(0);
      reset();
    }, 350);
  }, [reset, setId]);

  const onRetry = useCallback(() => {
    reset();
    setTimeout(onSave, 300);
  }, [reset, onSave]);

  return (
    <div>
      <PersistentOverlay
        open={isOpen}
        onClose={onClose}
        className="full-height"
        local={true}
      >
        {isOpen ? (
          <Steps
            index={index}
            header={HEADERS}
            length={HEADERS.length}
            insideOverlay
          >
            <AssociateContactFields
              associate={signee}
              onChange={(value) => {
                setSignee(value);
              }}
              setIndex={setIndex}
            />

            <AssociateIdFields
              associate={signee}
              onChange={(value) => {
                setSignee(value);
              }}
              setIndex={setIndex}
            />

            <Done
              index={index}
              setIndex={setIndex}
              onSave={onSave}
              onRetry={onRetry}
              isLoading={isSaving}
              isError={isSaveError}
            />
          </Steps>
        ) : null}
      </PersistentOverlay>
    </div>
  );
};
