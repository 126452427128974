import { QueryOptions } from "@tanstack/react-query";
import { Currency, LinkId } from "./models/ContractTypes";
import { API } from "../network/API";
import { Access, getProxy } from "./proxy";

export enum Brand {
  VISA = "VISA",
  VISA_CREDIT = "VISA_CREDIT",
  VPAY = "VPAY",
  MASTERCARD = "MASTERCARD",
  MASTERCARD_CREDIT = "MASTERCARD_CREDIT",
  MAESTRO = "MAESTRO",
  DINERS = "DINERS",
  UNION = "UNION",
  JCB = "JCB",
  ALIPAY = "ALIPAY",
}

export enum PricingModel {
  BLENDED = "Blended",
  MONTHLY_FEE_STEP = "MonthlyFeeStep",
  IC_PLUS_PLUS = "IC++",
}

export interface PriceData {
  label: string;
  displayLabel: string;
  unitType: UnitType;
  value: number;
}

export interface PricePlan {
  pricingModel: PricingModel;
  currency: Currency;
  displayName: string;
  prices: PriceData[];
}

export enum UnitType {
  CURRENCY = "Currency",
  PERCENT = "Percent",
}

const BASE_PATH = "/api/merchant";

enum WhitelistedEndpoint {
  FETCH_PRICING = "fetchPricing",
  GET_PRICING = "getPricing",
  GET_PRICING_KEY = "getPricingKey",
}

export const Pricing = {
  [WhitelistedEndpoint.GET_PRICING]: (linkId: LinkId) =>
    API.get<PricePlan>(`${BASE_PATH}/${linkId}/pricing`),

  [WhitelistedEndpoint.FETCH_PRICING]: (
    linkId: LinkId
  ): QueryOptions<PricePlan> => ({
    queryKey: Pricing.getPricingKey(linkId),
    queryFn: () => Pricing.getPricing(linkId),
  }),

  [WhitelistedEndpoint.GET_PRICING_KEY]: (linkId: LinkId) => {
    return ["pricing", linkId];
  },
};

let pricing: typeof Pricing;

export function dataPricing(access: Access) {
  if (!pricing) {
    pricing = getProxy(Pricing, access, Object.values(WhitelistedEndpoint));
  }

  return pricing;
}
