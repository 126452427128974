import { useCallback, useMemo, useRef, useState } from "react";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import { Form } from "../../../../../components/form/Form";
import { Select } from "../../../../../components/form/Select";
import { RequiredValidator } from "../../../../../components/form/validators/RequiredValidator";
import { Button } from "../../../../../components/interactions/Buttons/Button";
import { T } from "../../../../../components/translation/T";
import { getCountryOpts } from "../../../../../components/utils";
import { Id, PEP_TYPE, Pep } from "../../../../../data/dataMerchant";
import { Country } from "../../../../../data/models/ContractTypes";
import { Dropdown } from "../../../../../components/interactions/Dropdown/Dropdown";
import { CountryChip } from "../../../../../components/chip/CountryChip";
import { HiddenInput } from "../../../../../components/form/HiddenInput";
import { Status } from "../../../../../data/types";
import styles from "../PepOverlay.module.scss";

interface Props {
  id?: Id;
  onChange: (id: Id) => void;
  onSubmit: () => void;
  onBack: () => void;
  isViewer: boolean;
  pep: Pep;
}

function getLabels(pep: Pep) {
  if (pep.type === PEP_TYPE.NOT_PEP) {
    return [
      "Add residence of person",
      "Place of birth (Country) of person",
      "Add all citizenships of person",
    ];
  }

  return [
    "Add residence of PEP",
    "Place of birth (Country) of PEP",
    "Add all citizenships of PEP",
  ];
}

export const PepResidence: React.FunctionComponent<Props> = ({
  id,
  pep,
  onChange,
  onSubmit,
  onBack,
}) => {
  const formRef = useRef<HTMLFormElement>(null);
  const [values, setValues] = useState<Id>({
    countryOfBirth: "" as Country,
    citizenships: [],
    countryOfResidence: "" as Country,
    ...id,
  });
  const { i18n, t } = useTranslation();

  const countries = useMemo(
    () =>
      getCountryOpts(
        t,
        i18n.language,
        false,
        undefined,
        undefined,
        values.citizenships
      ),
    [values.citizenships, t, i18n.language]
  );

  const residences = useMemo(
    () => getCountryOpts(t, i18n.language, false, null),
    [i18n.language, t]
  );

  const onRemoveCountry = useCallback((value: string) => {
    setValues((prev) => ({
      ...prev,
      citizenships: (prev?.citizenships ?? []).filter(
        (citizenship) => citizenship !== value
      ),
    }));
  }, []);

  const labels = useMemo(() => {
    return getLabels(pep);
  }, [pep]);

  const numberOfCitizenships = values.citizenships?.length ?? 0;

  return (
    <div className="m-top-30">
      <Form
        name="pepPosition"
        ref={formRef}
        onSubmit={(_, form) => {
          if (form.isInvalid) {
            return;
          }

          onChange({
            ...id,
            ...values,
          });
          onSubmit();
        }}
      >
        <div className="m-top-20">
          <Select
            onChange={(value) => {
              setValues({
                ...values,
                countryOfResidence: value as Country,
              });
            }}
            name="countryOfResidence"
            label={labels[0]}
            alternatives={residences}
            value={values.countryOfResidence}
            validators={[
              new RequiredValidator("You must select country of residence"),
            ]}
          />

          <div className="m-top-10">
            <Select
              name="countryOfBirth"
              label={labels[1]}
              alternatives={residences}
              value={values.countryOfBirth}
              onChange={(value) => {
                setValues({
                  ...values,
                  countryOfBirth: value as Country,
                });
              }}
              validators={[
                new RequiredValidator("You must select a place of birth"),
              ]}
            />
          </div>

          <div className="m-top-20">
            <Dropdown
              required={values.citizenships?.length ? false : true}
              onChange={(value) => {
                setValues((prev) => ({
                  ...prev,
                  citizenships: [...(values?.citizenships ?? []), value],
                }));
              }}
              name="citizenships"
              label={labels[2]}
              className={cx("compact", {
                "was-successful":
                  numberOfCitizenships > 0 && numberOfCitizenships !== 3,
              })}
              alternatives={countries}
              value=""
              status={
                numberOfCitizenships === 3 ? Status.DISABLED : Status.DEFAULT
              }
            />

            <div className={styles.chips}>
              {values.citizenships?.map((citizenship) => {
                return (
                  <CountryChip
                    key={citizenship}
                    country={citizenship as Country}
                    onRemove={onRemoveCountry}
                    data={citizenship}
                  />
                );
              })}
            </div>

            <HiddenInput
              label="Citizenship"
              value={values.citizenships?.length ? true : undefined}
              validators={[
                new RequiredValidator(
                  "You must select at least one citizenship"
                ),
              ]}
              scrollToRef={formRef}
            />
          </div>
        </div>
        <div className="m-top-30 columns">
          <div>
            <Button onClick={onBack} ghost>
              <T>Back</T>
            </Button>
          </div>
          <div className="text-right">
            <Button type="submit">
              <T>Next</T>
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
};
