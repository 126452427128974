import React from "react";
import cx from "classnames";
import styles from "./Signature.module.scss";
import i18n from "../../../i18n";
import { Associate } from "../../../data/dataMerchant";
import { Beacon } from "../../../components/beacon/Beacon";
import { T } from "../../../components/translation/T";
import { getIntlDate } from "../../../components/utils";
import { Language } from "../../../data/models/ContractTypes";
import { Validity } from "../../../data/types";
import { Field } from "./Field";
import { getNameWithPosition } from "./Signatures";

interface Props {
  signee: Associate;
}

export const Signature: React.FunctionComponent<Props> = ({ signee }) => {
  const hasSigned = signee.signatory?.signed;

  if (hasSigned) {
    return (
      <li className={cx(styles.signee)}>
        <div className={styles.signeeName}>
          <Beacon
            validity={Validity.VALID}
            className={cx("mini", styles.beacon)}
          />
          {getNameWithPosition(signee as Associate)}
        </div>

        <div className={styles.row}>
          <div className={cx(styles.name)}>
            <Field name={<T>Name</T>}>
              <div className={cx(styles.hand, "truncate")}>
                {signee.contact.firstName} {signee.contact.lastName}
              </div>
            </Field>
          </div>

          <div className={cx(styles.date, styles.signed)}>
            <Field name={<T>Date</T>}>
              <div className={cx("text-small", styles.hand)}>
                {getIntlDate(
                  signee.signatory?.signed,
                  i18n.language as Language
                )}
              </div>
            </Field>
          </div>
        </div>
      </li>
    );
  }

  return (
    <li key={signee.associateId} className={cx(styles.signee)}>
      <div className={styles.signeeName}>
        {getNameWithPosition(signee as Associate)}
      </div>

      <div className={styles.row}>
        <div className={cx(styles.name)}>
          <Field name={<T>Name</T>} />
        </div>

        <div className={cx(styles.date)}>
          <Field name={<T>Date</T>} />
        </div>
      </div>
    </li>
  );
};
