import { Associate, AssociateContractViewer } from "../data/dataMerchant";

export const useViewerAsAssociate = (
  associates: Associate[],
  viewer: AssociateContractViewer
) => {
  return associates.find(
    (associate) => associate.associateId === viewer.associateId
  );
};
