import React, { useCallback, useEffect, useState } from "react";
import { Retry } from "../../components/retry/Retry";
import { T } from "../../components/translation/T";
import {
  Supplement as SupplementType,
  Supplements,
  dataMerchant,
} from "../../data/dataMerchant";
import { DocumentId } from "../../data/models/ContractTypes";
import { Status } from "../../data/types";
import { useLinkId } from "../../hooks/useLinkId";
import { Supplement } from "./Supplement";
import { Logo } from "../../components/images/Logo";
import { AnimateHeight } from "../../components/animate/AnimateHeight";
import { SuccessBox } from "../../components/boxes/SuccessBox";
import styles from "./SupplementsCollection.module.scss";
import { Access } from "../../data/proxy";

interface Props {}

export const SUPPLEMENTS_STORY_URL = "/:linkId/supplements";

// upload
// /api/merchant/{linkId}/supplements/{documentId}

export const SupplementsCollection: React.FunctionComponent<Props> = () => {
  const linkId = useLinkId();
  const [status, setStatus] = useState<Status>(Status.PENDING);
  const [supplements, setSupplements] = useState<Supplements>();
  const [uploads, setUploads] = useState<Record<string, boolean>>();
  const [docs, setDocs] = useState<SupplementType[]>([]);

  const allUploaded = uploads
    ? Object.values(uploads).every((item) => !!item)
    : false;

  useEffect(() => {
    document.title = "Supplements | Worldline";
  }, []);

  const load = useCallback(() => {
    dataMerchant(Access.VIEW_AND_EDIT)
      .getSupplements(linkId)
      .then((data) => {
        const initial: Record<string, boolean> = {};
        const documents = data.documents.filter((doc) => !doc.confirmed);
        const initialUploads = documents
          .filter((doc) => !doc.confirmed)
          .reduce((acc, cur) => {
            acc[`key-${cur.documentId}`] = cur.uploaded;
            return acc;
          }, initial);
        setUploads(initialUploads);
        setDocs(documents);
        setSupplements(data);
        setStatus(Status.SUCCESS);
      })
      .catch((err) => setStatus(Status.ERROR));
  }, [linkId]);

  useEffect(load, [load]);

  const retry = useCallback(() => {
    setStatus(Status.PENDING);
    setTimeout(load, 500);
  }, [load]);

  const onUploadChange = useCallback((docId: DocumentId, uploaded: boolean) => {
    setUploads((prev) => ({
      ...prev,
      [`key-${docId}`]: uploaded,
    }));
  }, []);

  return (
    <section>
      <article>
        <Retry status={status} retry={retry}>
          <div className={styles.wrapper}>
            <div className="logo-wrapper">
              <Logo />
            </div>
            <div className={styles.header}>
              <h2>
                <T
                  id="Hi, {{name}}!"
                  options={{
                    name: supplements?.contactName,
                  }}
                />
              </h2>
            </div>

            <p>
              <T>
                We need som additional uploads from you before completing the
                application.
              </T>
            </p>

            {docs.map((doc) => {
              return (
                <Supplement
                  linkId={linkId}
                  onUploadChange={onUploadChange}
                  supplement={doc}
                  key={doc.documentId}
                  disabled={allUploaded}
                />
              );
            })}

            <div className="m-top-40">
              <AnimateHeight name={allUploaded ? "done" : "no"}>
                <div>
                  {allUploaded ? (
                    <>
                      <SuccessBox relative>
                        <b className="text-large">
                          <T>You're done!</T>
                        </b>
                        <p>
                          <T>
                            All is set. As soon as your application has been
                            reviewed you will receive a confirmation email.
                          </T>
                        </p>
                      </SuccessBox>
                    </>
                  ) : (
                    <div />
                  )}
                </div>
              </AnimateHeight>
            </div>
          </div>
        </Retry>
      </article>
    </section>
  );
};
