import { MaxValidator } from "../../../components/form/validators/MaxValidator";
import { MinValidator } from "../../../components/form/validators/MinValidator";
import { T } from "../../../components/translation/T";
import { getIntlNumberFormat } from "../../../components/utils";
import { Country, Language } from "../../../data/models/ContractTypes";
import { CurrencyByCountry } from "../../../i18n";
import { FinancialSave } from "./Financials";

export function getMaxValidator(
  country: Country,
  language: Language,
  data: FinancialSave
): MaxValidator | undefined {
  if (!data.estimatedTransactionsPerYear) {
    return;
  }

  if (!data.estimatedRangeOfTransactionValueMax) {
    return;
  }

  const val =
    data.estimatedTransactionsPerYear *
    data.estimatedRangeOfTransactionValueMax;

  const formattedVal = getIntlNumberFormat(language, val);

  const text = (
    <T
      id="Value can not exceed {{formattedVal}} {{currency}} (max transaction value * number of transactions)"
      options={{
        formattedVal,
        currency: CurrencyByCountry[country],
      }}
    />
  );
  return new MaxValidator(val, text);
}

export function getMinValidator(
  country: Country,
  language: Language,
  data: FinancialSave
): MinValidator | undefined {
  if (!data.estimatedTransactionsPerYear) {
    return;
  }

  if (!data.estimatedRangeOfTransactionValueMin) {
    return;
  }

  const val =
    data.estimatedTransactionsPerYear *
    data.estimatedRangeOfTransactionValueMin;

  const formattedVal = getIntlNumberFormat(language, val);

  const text = (
    <T
      id={
        "Value must exceed {{formattedVal}} {{currency}} (min transaction value * number of transactions)"
      }
      options={{
        formattedVal,
        currency: CurrencyByCountry[country],
      }}
    />
  );
  return new MinValidator(val, text);
}

export function getHint(
  country: Country,
  language: Language,
  data: FinancialSave
) {
  const minHint =
    data.estimatedTransactionsPerYear &&
    data.estimatedRangeOfTransactionValueMin
      ? data.estimatedTransactionsPerYear *
        data.estimatedRangeOfTransactionValueMin
      : 0;

  const maxHint =
    data.estimatedTransactionsPerYear &&
    data.estimatedRangeOfTransactionValueMax
      ? data.estimatedTransactionsPerYear *
        data.estimatedRangeOfTransactionValueMax
      : 0;

  if (minHint && maxHint) {
    return (
      <T
        id="Between {{min}} {{currency}} and {{max}} {{currency}}"
        options={{
          currency: CurrencyByCountry[country],
          min: getIntlNumberFormat(language, minHint),
          max: getIntlNumberFormat(language, maxHint),
        }}
      />
    );
  }

  if (minHint) {
    return (
      <T
        id="From {{min}} {{currency}}"
        options={{
          currency: CurrencyByCountry[country],
          min: getIntlNumberFormat(language, minHint),
        }}
      />
    );
  }

  if (maxHint) {
    return (
      <T
        id="Up to {{max}} {{currency}}"
        options={{
          currency: CurrencyByCountry[country],
          max: getIntlNumberFormat(language, maxHint),
        }}
      />
    );
  }
}
