import React, { ReactNode } from "react";
import cx from "classnames";
import "./Visible.scss";

interface Props {
  show: boolean;
  children: ReactNode;
}

export const Visible: React.FunctionComponent<Props> = ({ children, show }) => {
  return <div className={cx("visible-component", { show })}>{children}</div>;
};
