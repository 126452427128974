import { ValidationResponse, VALIDATION_STATE } from "../hooks/useValidation";
import { BaseValidator } from "./BaseValidator";

export class MaxDateValidator extends BaseValidator {
  name = "MaxDateValidator";
  private max: Date;

  constructor(max: Date, error: string) {
    super(error);
    this.max = max;
  }

  validate(value: Date): Promise<ValidationResponse> {
    return new Promise<ValidationResponse>((resolve) => {
      let modifiedValue = value;
      if (typeof value === "string") {
        modifiedValue = new Date(value);
      }

      if (modifiedValue <= this.max) {
        resolve({ status: VALIDATION_STATE.SUCCESS });
      } else {
        resolve({
          status: VALIDATION_STATE.FAILED,
          message: this.error,
        });
      }
    });
  }
}
