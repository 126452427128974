import { ValidationResponse, VALIDATION_STATE } from "../hooks/useValidation";
import { BaseValidator } from "./BaseValidator";

export const PHONE_REGEX = /^\+([\d]+(\s?)*)+$/;
export const SSN_REGEX = /^([\d]+)$/;

export class RegexValidator extends BaseValidator {
  name = "RegexValidator";
  private regex: RegExp;

  constructor(regex: RegExp, error: string) {
    super(error);
    this.regex = regex;
  }

  validate(value: string): Promise<ValidationResponse> {
    return new Promise<ValidationResponse>((resolve) => {
      if (!value || this.regex.test(value)) {
        resolve({ status: VALIDATION_STATE.SUCCESS });
      } else {
        resolve({
          status: VALIDATION_STATE.FAILED,
          message: this.error,
        });
      }
    });
  }
}
