import React from "react";
import cx from "classnames";
import { Image } from "../../components/images/Image";

interface Props {
  className?: string;
  width?: number;
}

export const LogoVertical: React.FunctionComponent<Props> = ({
  className,
  width = 42,
}) => {
  return (
    <div className={cx("logo", className)} style={{ width }}>
      <Image
        src="/images/logo-worldline-vertical.svg"
        ratio={1175 / 840}
        alt="Worldline logo"
      />
    </div>
  );
};
