import { ReactNode } from "react";
import { ValidationResponse, VALIDATION_STATE } from "../hooks/useValidation";
import { BaseValidator } from "./BaseValidator";

export class MinLengthValidator extends BaseValidator {
  name = "MinLengthValidator";

  constructor(private min: number, error: string | ReactNode) {
    super(error);
  }

  validate(value: string): Promise<ValidationResponse> {
    return new Promise<ValidationResponse>((resolve) => {
      if (value.length === 0 || value.length >= this.min) {
        resolve({ status: VALIDATION_STATE.SUCCESS });
      } else {
        resolve({
          status: VALIDATION_STATE.FAILED,
          message: this.error,
        });
      }
    });
  }
}
