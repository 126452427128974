import ReactGA from "react-ga4";
import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { contractState } from "./state/contractState";

const EVENT_NAME = "progress";

function getKey(route: string, linkId: string) {
  return `${route}${linkId}`;
}

export function Analytics() {
  const location = useLocation();
  const initialized = useRef<boolean>(false);
  const { contractData } = useRecoilValue(contractState);
  const visited = useRef<Record<string, boolean>>({});

  useEffect(() => {
    if (!process.env.REACT_APP_ANALYTICS_ID) {
      return;
    }

    if (!initialized.current) {
      ReactGA.initialize(process.env.REACT_APP_ANALYTICS_ID);
      initialized.current = true;
    }

    ReactGA.send("pageview");

    const subRoutes = location.pathname.split("/");

    const route = subRoutes.pop();
    const linkId = subRoutes.pop();

    if (!route) {
      return;
    }

    if (!linkId) {
      return;
    }

    // ugly way to check that the contract is loaded
    if (!contractData.companyName) {
      return;
    }

    const key = getKey(route, linkId);
    if (visited.current[key]) {
      return;
    }

    visited.current[key] = true;
    ReactGA.event(EVENT_NAME, {
      category: contractData.legalEntityType,
      route,
      linkId,
    });
  }, [location, contractData]);

  return null;
}
