import React, { ReactNode } from "react";
import cx from "classnames";
import styles from "./ContractCell.module.scss";

interface Props {
  children: ReactNode;
  name: ReactNode;
  split?: number;
  first?: boolean;
}

export const ContractCell: React.FunctionComponent<Props> = ({
  children,
  name,
  split = 0.5,
  first = false,
}) => {
  return (
    <div
      className={cx(styles.cell, {
        [styles.w80]: split === 4 / 5,
        [styles.w75]: split === 3 / 4,
        [styles.w66]: split === 2 / 3,
        [styles.w50]: split === 1 / 2,
        [styles.w33]: split === 1 / 3,
        [styles.w25]: split === 1 / 4,
        [styles.w20]: split === 1 / 5,
        [styles.first]: first,
      })}
    >
      <div className={cx(styles.name, "text-mini")}>{name}</div>
      <div className={cx(styles.value, "truncate")}>{children}</div>
    </div>
  );
};
