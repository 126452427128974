import React, {
  useState,
  useCallback,
  Dispatch,
  SetStateAction,
  MutableRefObject,
  useRef,
} from "react";
import cx from "classnames";
import {
  Address,
  Address as AddressInterface,
} from "../../../../data/models/ContractTypes";
import { useLinkId } from "../../../../hooks/useLinkId";
import { InvoiceEmailStatus } from "../Financials";
import { dataFinancial } from "../../../../data/dataFinancial";
import { Associate } from "../../../../data/dataMerchant";
import { TextInput } from "../../../../components/form/TextInput";
import { T } from "../../../../components/translation/T";
import { EmailValidator } from "../../../../components/form/validators/EmailValidator";
import { MaxLengthValidator } from "../../../../components/form/validators/MaxLengthValidator";
import { EmailBackendValidator } from "../../../../components/form/validators/EmailBackendValidator";
import { Pending } from "../../../../components/icons/Pending";
import { AnimateHeight } from "../../../../components/animate/AnimateHeight";
import { ErrorBox } from "../../../../components/boxes/ErrorBox";
import { Button } from "../../../../components/interactions/Buttons/Button";
import { Or } from "../../../../components/or/Or";
import { AnimateHeightMotion } from "../../../../components/animate/AnimateHeightMotion";
import { RequiredValidator } from "../../../../components/form/validators/RequiredValidator";
import { InfoBox } from "../../../../components/boxes/InfoBox";
import { SuccessBox } from "../../../../components/boxes/SuccessBox";
import styles from "./InvoiceAddress.module.scss";
import { Trans } from "react-i18next";
import { useRecoilValue } from "recoil";
import { routeState } from "../../../../state/routeState";
import { FormContainer } from "../../../../components/form/Form";

export interface Props {
  address: AddressInterface;
  hasSameInvoiceAddress: boolean | undefined;
  setHasSameInvoiceAddress: (value: boolean) => void;
  invoiceAddress: Address;
  primary?: Associate;
  setInvoiceAddress: Dispatch<SetStateAction<Address>>;
  invoiceEmail?: string;
  setInvoiceEmail: (value: string) => void;
  code: string;
  setCode: (value: string) => void;
  setInvoiceStatus: Dispatch<SetStateAction<InvoiceEmailStatus>>;
  invoiceStatus: InvoiceEmailStatus;
  latestVerifiedInvoiceEmail: MutableRefObject<string>;
  formContainer: MutableRefObject<FormContainer | undefined>;
  setCodeWarning: Dispatch<SetStateAction<boolean>>;
}

export const InvoiceEmail: React.FunctionComponent<Props> = ({
  hasSameInvoiceAddress,
  setHasSameInvoiceAddress,
  invoiceAddress,
  setInvoiceAddress,
  address,
  primary,
  invoiceStatus,
  setInvoiceStatus,
  invoiceEmail,
  setInvoiceEmail,
  code,
  setCode,
  latestVerifiedInvoiceEmail,
  formContainer,
  setCodeWarning,
}) => {
  const linkId = useLinkId();
  const [errorTrigger, setErrorTrigger] = useState<boolean>(false);
  const { access } = useRecoilValue(routeState);

  const ref = useRef<HTMLDivElement>(null);

  const verifyCode = useCallback(() => {
    if (!invoiceEmail) {
      return;
    }

    if (!code) {
      return;
    }

    setCodeWarning(false);
    setInvoiceStatus(InvoiceEmailStatus.PENDING);

    dataFinancial(access)
      .confirmInvoiceVerificationCode(linkId, code)
      .then(() => {
        latestVerifiedInvoiceEmail.current = invoiceEmail;
        setInvoiceStatus(InvoiceEmailStatus.CONFIRMED_WITH_CODE);
      })
      .catch((err) => {
        setInvoiceStatus(InvoiceEmailStatus.ERROR);
      });
  }, [
    invoiceEmail,
    code,
    latestVerifiedInvoiceEmail,
    setInvoiceStatus,
    linkId,
    access,
    setCodeWarning,
  ]);

  const requestCode = useCallback(() => {
    if (!invoiceEmail) {
      setErrorTrigger(true);
      return;
    }

    setCodeWarning(false);
    setInvoiceStatus(InvoiceEmailStatus.PENDING);
    dataFinancial(access)
      .requestVerificationCode(linkId, invoiceEmail)
      .then((data) => {
        setTimeout(() => {
          setInvoiceStatus(InvoiceEmailStatus.CONFIRM_CODE);
        }, 500);
      })
      .catch((err) => {
        setInvoiceStatus(InvoiceEmailStatus.ERROR);
      });
  }, [linkId, invoiceEmail, access, setCodeWarning, setInvoiceStatus]);

  return (
    <div className="m-top-20">
      <h5>
        <T>Invoices by email</T>
      </h5>

      <div className={styles.invoice}>
        <div>
          <TextInput
            onChange={(value) => {
              setInvoiceEmail(value);
              setErrorTrigger(false);
            }}
            name="invoiceEmail"
            label="Email where to send the invoices"
            value={invoiceEmail}
            disabled={invoiceStatus !== InvoiceEmailStatus.REQUEST_CODE}
            validators={[
              new RequiredValidator("Email is missing"),
              new EmailValidator("Email is not valid"),
              new MaxLengthValidator(
                50,
                "Email must be less than 50 characters"
              ),
              new EmailBackendValidator(linkId, "Email is not valid"),
            ]}
            hint={primary?.contact.email ? "Leave empty if not requested" : ""}
          />
        </div>

        <div ref={ref} />

        <AnimateHeight name={invoiceStatus}>
          <div>
            {invoiceStatus === InvoiceEmailStatus.PENDING ? (
              <div className="p-bottom-10">
                <div className="center p-top-10">
                  <Pending />
                </div>
              </div>
            ) : null}

            {invoiceStatus === InvoiceEmailStatus.ERROR ? (
              <div className="p-top-10">
                <ErrorBox relative>
                  <T>Something went wrong. Try again?</T>
                </ErrorBox>

                <Button
                  block
                  ghost
                  className="m-top-30"
                  onClick={() => {
                    setInvoiceStatus(InvoiceEmailStatus.REQUEST_CODE);
                    setCode("");
                    setInvoiceEmail("");
                  }}
                >
                  <T>Reset</T>
                </Button>
              </div>
            ) : null}

            {invoiceStatus === InvoiceEmailStatus.REQUEST_CODE ? (
              <div className="p-bottom-20">
                {primary?.contact.email ? (
                  <Or className="white-bg compact">
                    <div className="p-top-10">
                      <p className="text-smalld">
                        <Trans>
                          We will have to verify the email associated with the
                          invoices. We will <u>send you a code</u> to the given
                          email address, and once you have entered and verified
                          the code, you will be able to proceed.
                        </Trans>
                      </p>
                      <AnimateHeightMotion presence>
                        {errorTrigger && (
                          <div className={`${styles.error} input-messages`}>
                            <div className={styles.emailError}>
                              <T>Email must be provided</T>
                            </div>
                          </div>
                        )}
                      </AnimateHeightMotion>

                      <div className={styles.invoiceButtons}>
                        <Button block onClick={requestCode}>
                          <T>Request verification code</T>
                        </Button>
                      </div>
                    </div>
                    <div>
                      <div className={cx(styles.primaryEmail)}>
                        <T>use</T>{" "}
                        <button
                          className="as-link"
                          onClick={(ev) => {
                            ev.preventDefault();
                            setInvoiceStatus(
                              InvoiceEmailStatus.CONFIRMED_AS_PRIMARY
                            );
                            setInvoiceEmail(primary?.contact.email ?? "");
                          }}
                        >
                          {primary?.contact.email}
                        </button>
                      </div>
                    </div>
                  </Or>
                ) : (
                  <div className="p-top-10">
                    <p className="text-smalld">
                      <Trans>
                        We will have to verify the email associated with the
                        invoices. We will <u>send you a code</u> to the given
                        email address, and once you have entered and verified
                        the code, you will be able to proceed.
                      </Trans>
                    </p>
                    <AnimateHeightMotion presence>
                      {errorTrigger && (
                        <div className={`${styles.error} input-messages`}>
                          <div className={styles.emailError}>
                            <T>Email must be provided</T>
                          </div>
                        </div>
                      )}
                    </AnimateHeightMotion>

                    <div className={styles.invoiceButtons}>
                      <Button block onClick={requestCode}>
                        <T>Request verification code</T>
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            ) : null}

            {invoiceStatus === InvoiceEmailStatus.CONFIRM_CODE ? (
              <div className="p-bottom-20">
                <div className="p-top-10">
                  <TextInput
                    onChange={(value) => {
                      setCode(value);
                      setCodeWarning(false);
                    }}
                    name="code"
                    label="Enter code sent to email"
                    value={code}
                    validators={[new RequiredValidator("Code is required")]}
                  />

                  <Button block className="m-top-10" onClick={verifyCode}>
                    <T>Verify code</T>
                  </Button>

                  <Button
                    block
                    ghost
                    className="m-top-10"
                    onClick={() => {
                      setInvoiceStatus(InvoiceEmailStatus.REQUEST_CODE);
                      setCode("");
                      setInvoiceEmail("");
                    }}
                  >
                    <T>Reset</T>
                  </Button>
                </div>
              </div>
            ) : null}

            {invoiceStatus === InvoiceEmailStatus.CONFIRMED_AS_PRIMARY ? (
              <div className="p-bottom-20">
                <InfoBox relative>
                  <T>We will send you the invoices to</T>{" "}
                  <b>{primary?.contact.email}</b>.
                </InfoBox>
                <Button
                  block
                  ghost
                  className="m-top-30"
                  onClick={() => {
                    setErrorTrigger(false);
                    setInvoiceStatus(InvoiceEmailStatus.REQUEST_CODE);
                    setCode("");
                    setInvoiceEmail("");
                  }}
                >
                  <T>No, change</T>
                </Button>
              </div>
            ) : null}

            {invoiceStatus === InvoiceEmailStatus.CONFIRMED_WITH_CODE ? (
              <div className="p-bottom-20">
                <div className="p-top-10">
                  <SuccessBox relative>
                    <b>
                      <T>Thanks!</T>
                    </b>{" "}
                    <T>We will send you the invoices to this email.</T>
                  </SuccessBox>

                  <Button
                    block
                    ghost
                    className="m-top-30"
                    onClick={() => {
                      setInvoiceStatus(InvoiceEmailStatus.REQUEST_CODE);
                      setCode("");
                      setInvoiceEmail("");
                    }}
                  >
                    <T>Reset</T>
                  </Button>
                </div>
              </div>
            ) : null}
          </div>
        </AnimateHeight>
      </div>

      {/* <HiddenInput
        label="No verification code"
        name="test"
        value={
          latestVerifiedInvoiceEmail.current === invoiceEmail ||
          primary?.contact.email === invoiceEmail
            ? true
            : undefined
        }
        validators={[
          new RequiredValidator(
            "You must confirm the email address by the code"
          ),
        ]}
        scrollToRef={ref}
      /> */}
    </div>
  );
};
