import { matchPath, useLocation } from "react-router";

export function useStoryValues(
  baseRoute: string,
  routes: Record<string, string>
): [number, number, string] {
  const location = useLocation();

  const currentIndex = Object.values(routes).findIndex((path) => {
    return matchPath(`${baseRoute}${path}`, location.pathname) !== null;
  });

  const progress = (100 / Object.values(routes).length) * (currentIndex + 1);

  const previousRoute =
    currentIndex === 0
      ? Object.values(routes)[0]
      : Object.values(routes)[currentIndex - 1];

  return [currentIndex, progress, previousRoute];
}
