import styles from "../PricePlan.module.scss";
import { FunctionComponent } from "react";
// components
import { T } from "../../translation/T";
// types
import { Currency, Language } from "../../../data/models/ContractTypes";
import { PriceData, UnitType } from "../../../data/dataPricing";
import { getIntlCost, getIntlPercent } from "../../utils";
import { useTranslation } from "react-i18next";

interface Props {
  prices: PriceData[];
  currency: Currency;
}

export const PricePlanBody: React.FunctionComponent<Props> = ({
  prices,
  currency,
}) => {
  const { i18n } = useTranslation();

  return (
    <table>
      <tbody>
        {prices.map(
          ({ displayLabel, label, value, unitType = null }, index) => (
            <TableRow
              language={i18n.language as Language}
              key={index}
              label={displayLabel || label}
              value={value}
              currency={currency}
              unitType={unitType}
            />
          )
        )}
      </tbody>
    </table>
  );
};

interface TableRowProps {
  language: Language;
  label: string;
  value: number;
  currency: Currency;
  unitType: UnitType | null;
}

const TableRow: FunctionComponent<TableRowProps> = ({
  language,
  label,
  value,
  currency,
  unitType = null,
}) => {
  return (
    <tr className={styles.row}>
      <td className={styles.tdLabel}>
        <div className={styles.label}>
          <T>Description</T>
        </div>
        <div className={styles.displayValue}>
          <T>{label}</T>
        </div>
      </td>
      <td className={styles.tdValue}>
        <div className={styles.label}>
          <T>Price</T>
        </div>
        <div className={styles.displayValue}>
          {unitType === UnitType.CURRENCY
            ? getIntlCost(language, value, currency, 2)
            : getIntlPercent(language, value, 2)}
        </div>
      </td>
    </tr>
  );
};
