import { T } from "../../../../../../components/translation/T";
import styles from "../Offering.module.scss";
import { FunctionComponent, ReactElement } from "react";

interface Props {
  icon?: ReactElement;
  name: string;
  description: string;
}

export const ProductCard: FunctionComponent<Props> = ({
  icon,
  name,
  description,
}) => {
  return (
    <div className={styles.productCard}>
      <div className={styles.iconContainer}>{icon}</div>
      <div className={styles.productInfo}>
        <span className={styles.productName}>{name}</span>
        <span className={styles.secondaryText}>
          <T>{description}</T>
        </span>
      </div>
    </div>
  );
};
