import { DocumentType } from "./ContractTypes";

export function documentTypeDisplay(type: DocumentType) {
  if (!type) {
    return "Missing document type";
  }

  switch (type) {
    case DocumentType.TAXI_LICENSE:
      return "Taxi License";
    case DocumentType.POWER_OF_ATTORNEY:
      return "Power of attorney";
    case DocumentType.PROOF_OF_ACCREDITATION:
      return "Proof of accreditation";
    case DocumentType.FINANCIAL_STATEMENT:
      return "Financial statement";
    case DocumentType.BANK_STATEMENT:
      return "Bank statement";
    case DocumentType.ID_DOCUMENT:
      return "Document of identification";
    case DocumentType.CONTROL_STRUCTURE:
      return "Document of control structure";
    case DocumentType.RECORD_OF_NOMINEE_SHAREHOLDERS:
      return "Record of nominee shareholders";
    case DocumentType.LETTER_OF_SHAREHOLDER_CUSTODIAN:
      return "Letter of shareholder custodian";
    case DocumentType.APP_SCREENSHOT:
    case DocumentType.ECOM_APPLICATION_SCREENSHOT:
      return "Image or screenshot of merchant application";
    case DocumentType.SALES_CUSTOM_REQUEST:
      return "A custom document request";
  }
  return "Unknown document type";
}

export function documentTypeDescription(type: DocumentType) {
  switch (type) {
    case DocumentType.TAXI_LICENSE:
      return "Proof of accreditation/taxi license";
    case DocumentType.POWER_OF_ATTORNEY:
      return "Copy of signed document showing the power to stand in for a representative of the company";
    case DocumentType.PROOF_OF_ACCREDITATION:
      return "Excerpt or copy or relevant accreditation documentation";
    case DocumentType.FINANCIAL_STATEMENT:
      return "The latest audited financial statement/annual report";
    case DocumentType.BANK_STATEMENT:
      return "Proof of Bank account details. Must include name of the bank account holder, bank account number, name of the bank (can also be logo, URL, or BIC), and date showing when the document was created. Must not be older than two months.";
    case DocumentType.CONTROL_STRUCTURE:
      return "Signed ownership and control structure document, Control by other Means, Partnership agreement, organizational chart or other signed (by authorized signatory) document showing the control structure of the company";
    case DocumentType.RECORD_OF_NOMINEE_SHAREHOLDERS:
      return "Official letter showing bearer or nominee shareholder (s)";
    case DocumentType.LETTER_OF_SHAREHOLDER_CUSTODIAN:
      return "Document showing the custodian shareholder (s)";
    case DocumentType.APP_SCREENSHOT:
    case DocumentType.ECOM_APPLICATION_SCREENSHOT:
      return "Images or screenshot of merchant application, including contact information, products/services, checkout, privacy policy and terms and conditions";
    case DocumentType.SIGNED_CONTRACT:
      return "The signed contract";
  }
  return "(Description missing for " + type.toString() + ")";
}
