import React, { ReactNode } from "react";
import { ErrorBox } from "../boxes/ErrorBox";
import { T } from "../translation/T";
import styles from "./GenericError.module.scss";

interface Props {
  children?: ReactNode;
}

export const GenericError: React.FunctionComponent<Props> = ({ children }) => {
  return (
    <div className={styles.error}>
      <ErrorBox relative>
        <b>
          <T>Oh no!</T>
        </b>{" "}
        {children || <T>Something went wrong. Try again?</T>}
      </ErrorBox>
    </div>
  );
};
