import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { useMutation, useQueries, useQueryClient } from "@tanstack/react-query";
import cx from "classnames";
import styles from "./SignatoriesFromCombinations.module.scss";
import { useState } from "react";
import { Card } from "../../../../components/cards/Card";
import { StoryStepProps } from "../../../../components/story/Story";
import { StoryContinueButton } from "../../../../components/story/StoryContinueButton";
import { T } from "../../../../components/translation/T";
import { Associate, AssociateRole } from "../../../../data/dataMerchant";
import { AssociateId } from "../../../../data/models/ContractTypes";
import { SignatoryOverlay } from "../components/SignatoryOverlay";
import { MultiForm } from "../../../../components/form/MultiForm";
import { useMultiForm } from "../../../../components/form/MultiFormContext";
import { HiddenInput } from "../../../../components/form/HiddenInput";
import { RequiredValidator } from "../../../../components/form/validators/RequiredValidator";
import { Form } from "../../../../components/form/Form";
// import { id as generateId } from "../../../components/utils";
import { useLinkId } from "../../../../hooks/useLinkId";
import {
  dataAssociates,
  Combination,
  hasRole,
} from "../../../../data/dataAssociates";
import { AnimateHeight } from "../../../../components/animate/AnimateHeight";
import { GenericError } from "../../../../components/Errors/GenericError";
import { useTranslation } from "react-i18next";
import { routeState } from "../../../../state/routeState";
import { useRecoilValue } from "recoil";
import { Access } from "../../../../data/proxy";

interface MappedAssociate {
  [key: AssociateId]: Associate;
}

function allIsSetAsSignees(signees: MappedAssociate, group: Combination) {
  for (let idx = 0; idx < group.associateIds.length; idx++) {
    const signee = signees[group.associateIds[idx]];
    if (!hasRole(AssociateRole.SELECTED_SIGNATORY, signee)) {
      return false;
    }
  }

  return true;
}

function getSelectedIndex(signees: MappedAssociate, combo: Combination[]) {
  for (let idx = 0; idx < combo.length; idx++) {
    const ids = combo[idx];
    if (allIsSetAsSignees(signees, ids)) {
      return idx;
    }
  }

  return undefined;
}

export function filterSignees(associates: Associate[]) {
  return associates.filter((associate) =>
    associate.roles.includes(AssociateRole.SIGNATORY)
  );
}

export function filterOwners(associates: Associate[]) {
  return associates.filter((associate) =>
    associate.roles.includes(AssociateRole.BENEFICIAL_OWNER)
  );
}

export const SignatoriesFromCombinations: React.FunctionComponent<
  StoryStepProps
> = ({ next }) => {
  const linkId = useLinkId();
  const queryClient = useQueryClient();
  const hasBeenInitiated = useRef<boolean>(false);
  const ref = React.useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const { access } = useRecoilValue(routeState);
  const [signees, setSignees] = useState<Associate[]>([]);
  const [selected, setSelected] = useState<number | undefined>();

  const [{ data: potentialSignees = [] }, { data: signingCombinations = [] }] =
    useQueries({
      queries: [
        dataAssociates(access).fetchAssociates(linkId),
        dataAssociates(access).fetchSignatoryCombinations(linkId),
      ],
    });

  useEffect(() => {
    setSignees(filterSignees(potentialSignees));
  }, [potentialSignees]);

  const associatesMappedById = useMemo(() => {
    const associatesMappedById: MappedAssociate = {};
    return (potentialSignees as Associate[]).reduce((acc, curr) => {
      acc[curr.associateId] = curr;
      return acc;
    }, associatesMappedById);
  }, [potentialSignees]);

  useEffect(() => {
    if (hasBeenInitiated.current) {
      return;
    }

    if (!signingCombinations.length) {
      return;
    }

    hasBeenInitiated.current = true;

    // fugly, prevent visual artifact
    setTimeout(() => {
      setSelected(getSelectedIndex(associatesMappedById, signingCombinations));
    }, 150);
  }, [associatesMappedById, signingCombinations]);

  const {
    mutate: onSave,
    isLoading,
    isError,
  } = useMutation(
    () => {
      if (selected === undefined) {
        return Promise.reject();
      }

      const activeAssociates = [...signees];
      const updatePromises: Promise<Associate>[] = [];
      const activeCombo = signingCombinations[selected];

      activeAssociates.forEach((associate) => {
        if (!hasRole(AssociateRole.SELECTED_SIGNATORY, associate)) {
          return;
        }

        if (activeCombo.associateIds.includes(associate.associateId)) {
          return;
        }

        updatePromises.push(
          dataAssociates(access).saveAssociate(linkId, {
            ...associate,
            roles: associate.roles.filter(
              (role) => role !== AssociateRole.SELECTED_SIGNATORY
            ),
          })
        );
      });

      activeCombo.associateIds.forEach((id) => {
        const associate = associatesMappedById[id];

        if (hasRole(AssociateRole.SELECTED_SIGNATORY, associate)) {
          return;
        }

        updatePromises.push(
          dataAssociates(access).saveAssociate(linkId, {
            ...associate,
            roles: associate.roles.concat(AssociateRole.SELECTED_SIGNATORY),
          })
        );
      });

      return Promise.all(updatePromises);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["associates", linkId]);
        next();
      },
    }
  );

  const updateSignatory = (associate: Associate) => {
    const idx = signees.findIndex(
      (a) => a.associateId === associate.associateId
    );

    if (idx === -1) {
      return;
    }

    const newAssociates = [...signees];
    newAssociates[idx] = associate;
    setSignees(newAssociates);
  };

  const isSingleSignee = signees.length === 1;

  const CardHeader = useCallback(
    (idx: number) => {
      if (isSingleSignee) {
        return <>{t("Signee")}</>;
      }

      return (
        <>
          {t("Combination")} {idx + 1}
        </>
      );
    },
    [isSingleSignee, t]
  );

  return (
    <>
      <h2>
        <T>Signatories</T>
      </h2>
      <p>
        <T>
          Below you will find the persons and/or combinations of persons who
          have the right to sign agreements for your company. Please select the
          option that will apply to the agreement with Worldline, so that we
          send the agreements correctly.
        </T>
      </p>

      <MultiForm>
        <div className="m-top-20">
          <div className="flex-columns gap-20 m-bottom-20" ref={ref}>
            {signingCombinations.map((signingCombination, idx) => (
              <div key={idx} onClick={() => setSelected(idx)}>
                <Card
                  header={
                    idx === selected ? (
                      <span className="flex-rows space-between">
                        <span>
                          <>{CardHeader(idx)}</>
                        </span>
                        <span className="capitalized text-passive">
                          <T>Selected</T>
                        </span>
                      </span>
                    ) : (
                      <>
                        <>{CardHeader(idx)}</>
                      </>
                    )
                  }
                  className={styles.selectCard}
                  bodyStyle={{
                    transition: "all 150ms ease-in-out",
                    border:
                      idx === selected
                        ? "2px solid #007b7c"
                        : "2px solid transparent",
                  }}
                >
                  <div className={styles.selectCardBody}>
                    <div
                      className={cx(styles.tickbox, {
                        [styles.selected]: idx === selected,
                      })}
                    />
                    <div className="flex-columns gap-10 flex-1">
                      {signingCombination.associateIds.map((id) => {
                        const signatory = associatesMappedById[id];

                        if (!signatory) {
                          return null;
                        }

                        return (
                          <SignatoryOverlay
                            key={signatory.associateId}
                            signatory={signatory}
                            onChange={updateSignatory}
                            render={selected === idx}
                          />
                        );
                      })}
                    </div>
                  </div>
                </Card>
              </div>
            ))}
          </div>
        </div>

        <Form name="signatoryValidation">
          <HiddenInput
            label="Missing signatories"
            value={(potentialSignees as Associate[]).length ? true : undefined}
            validators={[
              new RequiredValidator("There must be at least one signatory"),
            ]}
            scrollToRef={ref}
          />
          <HiddenInput
            label="Missing selection"
            value={
              typeof selected !== "undefined" &&
              selected <= signingCombinations.length
                ? true
                : undefined
            }
            validators={[
              new RequiredValidator("Please select a signing combination"),
            ]}
            scrollToRef={ref}
          />
        </Form>
        <div style={{ padding: "1px 0" }}>
          <AnimateHeight name={isError ? "error" : ""}>
            <div>
              {isError && (
                <div className={styles.error}>
                  <GenericError />
                </div>
              )}
            </div>
          </AnimateHeight>
        </div>
        <div className="m-top-40">
          <SubmitButton
            isLoading={isLoading}
            onClick={onSave}
            access={access}
          />
        </div>
      </MultiForm>
    </>
  );
};

const SubmitButton = ({
  onClick,
  isLoading,
  access,
}: {
  onClick: () => void;
  isLoading: boolean;
  access: Access;
}) => {
  const multiForm = useMultiForm();

  const handleClick = () => {
    if (!multiForm || multiForm.isInvalid) {
      // TODO: perhaps validate inner multiForms as well, not sure if needed.
      multiForm?.forceValidation();
      return;
    }

    onClick();
  };

  return (
    <StoryContinueButton
      type="button"
      disabled={access === Access.VIEW}
      onClick={handleClick}
      isLoading={isLoading}
    >
      <T>Continue</T>
    </StoryContinueButton>
  );
};
