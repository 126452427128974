import { QueryKey, useQueries } from "@tanstack/react-query";
import {
  SuspendedUseQueryOptions,
  SuspendedUseQueryResultOnSuccess,
  SuspendedUseQueryResultOnIdle,
  BaseSuspendedUseQueryResult,
} from "./useSuspendedQuery";

export function useSuspendedQueries<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
>({
  queries,
}: {
  queries: Omit<
    SuspendedUseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
    "enabled"
  >[];
}): SuspendedUseQueryResultOnSuccess<TData>[];

export function useSuspendedQueries<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
>({
  queries,
}: {
  queries: (Omit<
    SuspendedUseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
    "enabled"
  > & {
    enabled?: true;
  })[];
}): SuspendedUseQueryResultOnSuccess<TData>[];

export function useSuspendedQueries<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
>({
  queries,
}: {
  queries: (Omit<
    SuspendedUseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
    "enabled"
  > & {
    enabled: false;
  })[];
}): SuspendedUseQueryResultOnIdle[];

export function useSuspendedQueries<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
>({
  queries,
}: {
  queries: SuspendedUseQueryOptions<TQueryFnData, TError, TData, TQueryKey>[];
}): (SuspendedUseQueryResultOnSuccess<TData> | SuspendedUseQueryResultOnIdle)[];

export function useSuspendedQueries<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
>({
  queries,
}: {
  queries: SuspendedUseQueryOptions<TQueryFnData, TError, TData, TQueryKey>[];
}) {
  return useQueries({
    queries: queries.map((query) => ({
      ...query,
      suspense: true,
      useErrorBoundary: true,
    })),
  }) as BaseSuspendedUseQueryResult<TData>[];
}
