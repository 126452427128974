import styles from "./Section.module.scss";
import cx from "classnames";
import { FunctionComponent, PropsWithChildren } from "react";

interface Props {
  highlighted?: boolean;
  classNames?: string;
}

export const Section: FunctionComponent<PropsWithChildren<Props>> = ({
  highlighted = false,
  classNames,
  children,
}) => {
  return (
    <div
      className={cx(styles.section, classNames, {
        [styles.highlighted]: highlighted,
      })}
    >
      {children}
    </div>
  );
};
