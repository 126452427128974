import styles from "./Offering.module.scss";
import { Product } from "./products/Product";
import { Terminals } from "./terminals/Terminals";
import { Pricing } from "./pricing/Pricing";
import { useRecoilValue } from "recoil";
import { Card } from "../../../../../components/cards/Card";
import { T } from "../../../../../components/translation/T";
import { contractState } from "../../../../../state/contractState";
import { isEcom } from "../../../ContractLoader";
import { dataStores } from "../../../../../data/dataStores";
import { useSuspendedQuery } from "../../../../../hooks/useSuspendedQuery";
import { useLinkId } from "../../../../../hooks/useLinkId";
import { PricePlan, dataPricing } from "../../../../../data/dataPricing";
import { TerminalType } from "../../../../../data/models/ContractTypes";
import { Store } from "../../../../../data/dataMerchant";
import { routeState } from "../../../../../state/routeState";
import { useAccess } from "../../../../../hooks/useAccess";
import { useMemo } from "react";
import { AndroidTerminals } from "./terminals/AndroidTerminals";

export const Offering = () => {
  const linkId = useLinkId();
  const contract = useRecoilValue(contractState);
  const access = useAccess();
  const { data: pricing } = useSuspendedQuery(
    dataPricing(access).fetchPricing(linkId)
  );

  if (isEcom(contract)) {
    return <OfferingEcom pricing={pricing} />;
  }

  return <OfferingLocations pricing={pricing} />;
};

function getNumberOfTerminalsByTypeForAllStores(
  stores: Store[]
): Record<TerminalType, number> {
  return stores.reduce((acc, cur) => {
    Object.values(TerminalType).forEach((type) => {
      if (acc[type]) {
        acc[type] = acc[type] + (cur[type]?.terminals ?? 0);
      } else {
        acc[type] = cur[type]?.terminals ?? 0;
      }
    });

    return acc;

    // return acc + (cur[terminalType]?.terminals ?? 0);
  }, {} as Record<TerminalType, number>);
}

export const OfferingLocations = ({ pricing }: { pricing: PricePlan }) => {
  const linkId = useLinkId();
  const { access } = useRecoilValue(routeState);
  const contract = useRecoilValue(contractState);
  const { data: stores } = useSuspendedQuery(
    dataStores(access).fetchStores(linkId)
  );

  const aggregatedNumberOfTerminals = useMemo(
    () => getNumberOfTerminalsByTypeForAllStores(stores),
    [stores]
  );

  return (
    <Card accent={true} header={<T>Product & Pricing</T>}>
      <div className={styles.root}>
        <Product productType={contract.productType} />
        <Terminals
          numberOfWiredTerminals={
            aggregatedNumberOfTerminals[TerminalType.WIRED_INTEGRATED]
          }
          numberOfWirelessIntegrated={
            aggregatedNumberOfTerminals[TerminalType.WIRELESS_INTEGRATED]
          }
          numberOfWirelessStandalone={
            aggregatedNumberOfTerminals[TerminalType.WIRELESS_STANDALONE]
          }
        />

        <AndroidTerminals
          numberOfWiredTerminals={
            aggregatedNumberOfTerminals[TerminalType.WIRED_INTEGRATED_ANDROID]
          }
          numberOfWirelessIntegrated={
            aggregatedNumberOfTerminals[
              TerminalType.WIRELESS_INTEGRATED_ANDROID
            ]
          }
          numberOfWirelessStandalone={
            aggregatedNumberOfTerminals[
              TerminalType.WIRELESS_STANDALONE_ANDROID
            ]
          }
        />
        <Pricing pricePlan={pricing} />
      </div>
    </Card>
  );
};

export const OfferingEcom = ({ pricing }: { pricing: PricePlan }) => {
  const contract = useRecoilValue(contractState);

  return (
    <Card accent={true} header={<T>Product & Pricing</T>}>
      <div className={styles.root}>
        <Product productType={contract.productType} />
        <Pricing pricePlan={pricing} />
      </div>
    </Card>
  );
};
