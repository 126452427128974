import cx from "classnames";
import { Button, ButtonProps } from "../interactions/Buttons/Button";
import { Spinner } from "../spinner/Spinner";
import styles from "./StoryContinueButton.module.scss";

type Props = ButtonProps & {
  isLoading?: boolean;
};

export const StoryContinueButton: React.FunctionComponent<Props> = ({
  isLoading = false,
  children,
  disabled,
  className,
  ...props
}) => (
  <div className={cx(className, styles.buttonWrapper)}>
    <Button {...props} block size="large" disabled={disabled || isLoading}>
      {isLoading ? <Spinner color="white" size="small" /> : children}
    </Button>
  </div>
);
