export enum Access {
  VIEW = "view",
  VIEW_AND_EDIT = "viewAndEdit",
}

export function getProxy<T extends Object>(
  api: T,
  accessType: Access,
  whitelist: string[]
) {
  return new Proxy(api, {
    get(target, prop) {
      let value = target[prop as keyof typeof api];

      if (accessType === Access.VIEW_AND_EDIT) {
        return typeof value === "function" ? value.bind(target) : value;
      }

      if (typeof value !== "function") {
        return value;
      }

      if (Object.values(whitelist).includes(prop as string)) {
        return value.bind(target);
      }

      return Promise.reject("Access Denied");
    },
  });
}
