import React, { useCallback, ReactNode, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import cx from "classnames";
import { Close } from "../icons/Close";
import { Button } from "../interactions/Buttons/Button";
import { Status } from "../../data/types";
import { motion } from "framer-motion";
import { OVERLAY_PORTAL_ID } from "../../App";
import "./Overlay.scss";

interface Props {
  open: boolean;
  children: ReactNode;
  onClose: (event?: MouseEvent) => void;
  disableClose?: boolean;
  status?: Status;
  className?: string;
  bodyClassName?: string;
  /**
   * If false, the overlay will be rendered in the current dom
   * otherwise the overlay will be rendered in the global overlay portal.
   * @default false
   * @optional
   */
  local?: boolean;
  noPadding?: boolean;
}

export const ESC = "Escape";

export const Inner: React.FunctionComponent<{
  children: ReactNode;
  tryClose: () => void;
  bodyClassName?: string;
}> = ({ children, tryClose, bodyClassName }) => {
  if (!children) {
    return null;
  }

  return (
    <>
      <motion.div className="overlay-backdrop">
        <dialog className="overlay" open>
          <Button
            type="button"
            className="overlay-close action ghost small"
            onClick={tryClose}
          >
            <Close />
          </Button>
          <div className={cx("overlay-body", bodyClassName)}>{children}</div>
        </dialog>
      </motion.div>
    </>
  );
};

export const PersistentOverlay: React.FunctionComponent<Props> = ({
  open,
  children,
  onClose,
  disableClose = false,
  status,
  className,
  bodyClassName,
  local = false,
  noPadding = false,
}) => {
  const dialogRef = useRef<HTMLDivElement>(null);
  const portalElementRef = React.useRef<Element>(
    document.getElementById(OVERLAY_PORTAL_ID)
  );

  const tryClose = useCallback(
    (event?: MouseEvent) => {
      if (disableClose) {
        return;
      }

      if (event && event.target !== event.currentTarget) {
        return;
      }

      onClose(event);
    },
    [onClose, disableClose]
  );

  useEffect(() => {
    if (!dialogRef.current) {
      return;
    }
    if (open) {
      const elem = dialogRef.current.querySelector("input");
      if (elem?.disabled) {
        return;
      }
      elem?.focus();
    }
  }, [open]);

  const handler = useCallback(
    (ev: KeyboardEvent) => {
      if (disableClose) {
        return;
      }

      if (ev.code === ESC) {
        (document.activeElement as HTMLElement).blur();
        onClose();
      }
    },
    [onClose, disableClose]
  );

  useEffect(() => {
    window.addEventListener("keydown", handler, false);
    return () => {
      window.removeEventListener("keydown", handler, false);
    };
  }, [handler]);

  const classes = cx(
    "dialog-wrapper",
    "persistent-overlay",
    status,
    className,
    {
      "is-active": open,
      "disable-close": disableClose,
      "no-padding": noPadding,
    }
  );

  if (!local && portalElementRef.current) {
    return ReactDOM.createPortal(
      <div className={classes} ref={dialogRef}>
        <Inner {...{ tryClose, bodyClassName }}>{children}</Inner>
      </div>,
      portalElementRef.current
    );
  }

  return (
    <div className={classes} ref={dialogRef}>
      <Inner {...{ tryClose, bodyClassName }}>{children}</Inner>
    </div>
  );
};
