import { API } from "../network/API";
import { cache } from "../network/Cache";
import {
  Address,
  AssociateId,
  Contact,
  ContractId,
  ContractVersion,
  Language,
  LinkId,
  NO_COUNTRY,
  UTCDate,
  Country,
  CompanyRegistrationId,
  LegalEntityType,
  ProductType,
  EcomType,
  StoreId,
  DocumentType,
  TerminalType,
} from "./models/ContractTypes";
import { Access, getProxy } from "./proxy";

// export const VERSION_ERROR_CODE = 409;

export interface SalesContact {
  name: string;
  email: string;
  phoneNumber?: string;
}

export interface Integrations {
  bax?: string;
  tof?: string;
  amex?: string;
  resurs?: string;
}

export interface ContractData {
  version: ContractVersion;
  country: Country;
  companyName: string;
  organizationNumber: CompanyRegistrationId;
  address: Address;
  invoiceAddress: Partial<Address>;
  invoiceEmail?: string;
  website?: string;
  mccIndustry: string;
  legalEntityType: LegalEntityType;
  contactPhoneNumber?: string;
}

export type TerminalOption = {
  name: string;
  value: string;
};

export interface TerminalIntegrated {
  terminals: number;
  terminalModelId: number;
  integrationId: number;
  integrationKitId: number;
  terminalOptions: TerminalOption[];
}

export interface Store {
  storeId: StoreId;
  commercialName?: string;
  address?: Address;
  phoneNumber?: string;
  [TerminalType.WIRELESS_STANDALONE]?: {
    terminals: number;
    terminalModelId: number;
  };
  [TerminalType.WIRELESS_INTEGRATED]?: TerminalIntegrated;
  [TerminalType.WIRED_INTEGRATED]?: TerminalIntegrated;
  [TerminalType.WIRELESS_STANDALONE_ANDROID]?: {
    terminals: number;
    terminalModelId: number;
  };
  [TerminalType.WIRELESS_INTEGRATED_ANDROID]?: TerminalIntegrated;
  [TerminalType.WIRED_INTEGRATED_ANDROID]?: TerminalIntegrated;
}

export enum PaymentIntegration {
  HOSTED = "HOSTED",
  SEMI = "SEMI",
  API = "API",
}

export interface AppProps {
  // screenshot here: GET /api/sales/:contractId/ecomstore/appscreen
  //Om du har objektet så finns subpropparna
  name: string;
  testName: string;
  screenshotUploaded: boolean;
  paymentIntegration?: PaymentIntegration;
}

export interface WebProps {
  testUrl?: string;
  checklist: WebsiteChecklist;
  paymentIntegration?: PaymentIntegration;
}

export interface EcomStore {
  type?: EcomType;
  url?: string;
  mainCountriesOfOperation?: string[];
  app?: AppProps;
  web?: WebProps;
  invoice?: {
    senderName?: string;
    contactEmail?: string;
    totalTurnover?: number;
    averageTurnover?: number;
  };
}

export interface WebStore extends Omit<EcomStore, "app" | "web"> {
  web: WebProps;
}

export interface AppStore extends Omit<EcomStore, "app" | "web"> {
  app: AppProps;
}

export interface WebsiteChecklist {
  hasPrivacyPolicy?: boolean;
  hasTermsOfService?: boolean;
  hasCookiePolicy?: boolean;
  hasDataProtectionPolicy?: boolean;
  hasDataSecurityPolicy?: boolean;
  hasDataRetentionPolicy?: boolean;
}

export enum AssociateRole {
  PRIMARY_CONTACT = "PRIMARY_CONTACT",
  ACCOUNT_HOLDER = "ACCOUNT_HOLDER",
  SELECTED_SIGNATORY = "SELECTED_SIGNATORY",
  SIGNATORY = "SIGNATORY",
  POWER_OF_ATTORNEY = "POWER_OF_ATTORNEY",
  BENEFICIAL_OWNER = "BENEFICIAL_OWNER",
  PORTAL_LOGIN = "PORTAL_LOGIN",
  STORE_CONTACT = "STORE_CONTACT",
}

export interface AssociateOwner {
  capitalStake?: number; //0.00 -> 100.00
  votingRightsStake?: number; //0.00 -> 100.00
  hasIndirectOwnership: boolean;
  designatedOwner?: boolean;
}

interface AssociateSignatory {
  signed?: UTCDate;
  signature?: string;
  signingMethod?: SigningMethod;
}

export enum SigningMethod {
  BANKID = "BANKID",
  NBANKID = "NBANKID",
  MITID = "MITID",
  FTN = "FTN",
}

export const defaultAddress: Address = {
  street: "",
  postalCode: "",
  countryCode: NO_COUNTRY,
  city: "",
};

export interface Id {
  nationalPersonId?: string;
  dateOfBirth?: string; // yyyy-mm-dd
  countryOfBirth?: Country;
  citizenships?: string[]; // country code
  countryOfResidence?: Country;
}

export enum PEP_POSITION {
  HEAD_OF_GOVERNMENT = "HEAD_OF_GOVERNMENT",
  MINISTER = "MINISTER",
  PARLIMENT_MEMBER = "PARLIAMENT_MEMBER",
  CENTRAL_BANK_MEMBER = "CENTRAL_BANK_MEMBER",
  BOARD_MEMBER_POLITICAL_PARTY = "BOARD_MEMBER_POLITICAL_PARTY",
  JUDGE_SUPREME_COURT = "JUDGE_SUPREME_COURT",
  JUDGE_OTHER_HIGH_COURT = "JUDGE_OTHER_HIGH_COURT",
  REVISION_INSTITUTE = "REVISION_INSTITUTE",
  AMBASSADOR = "AMBASSADOR",
  MILITARY_OFFICER = "MILITARY_OFFICER",
  GOVERNMENT_COMPANY = "GOVERNMENT_COMPANY",
  EU_HIGH_RANKING_POSITION = "EU_HIGH_RANKING_POSITION",
  HIGH_RANKING_POSITION_INTERNATIONAL_CORP = "HIGH_RANKING_POSITION_INTERNATIONAL_CORP",
}

export const PEP_POSITION_READABLE: Record<PEP_POSITION, string> = {
  [PEP_POSITION.HEAD_OF_GOVERNMENT]: "Head of Government",
  [PEP_POSITION.MINISTER]: "Minister",
  [PEP_POSITION.PARLIMENT_MEMBER]: "Parliament Member",
  [PEP_POSITION.CENTRAL_BANK_MEMBER]: "Central Bank Member",
  [PEP_POSITION.BOARD_MEMBER_POLITICAL_PARTY]:
    "Board Member of Political Party",
  [PEP_POSITION.JUDGE_SUPREME_COURT]: "Judge of Supreme Court",
  [PEP_POSITION.JUDGE_OTHER_HIGH_COURT]: "Judge of Other High Court",
  [PEP_POSITION.REVISION_INSTITUTE]: "Revision Institute",
  [PEP_POSITION.AMBASSADOR]: "Ambassador",
  [PEP_POSITION.MILITARY_OFFICER]: "Military Officer",
  [PEP_POSITION.GOVERNMENT_COMPANY]: "Government Company",
  [PEP_POSITION.EU_HIGH_RANKING_POSITION]: "EU High Ranking Position",
  [PEP_POSITION.HIGH_RANKING_POSITION_INTERNATIONAL_CORP]:
    "High Ranking Position in International Corp",
};

export enum PEP_RELATION {
  CHILD = "CHILD",
  PARENT = "PARENT",
  SIBLING = "SIBLING",
  PARTNER = "PARTNER",
  CO_WORKER = "CO_WORKER",
  CHILD_IN_LAW = "CHILD_IN_LAW",
  PARENT_IN_LAW = "PARENT_IN_LAW",
}

export const PEP_RELATION_READABLE: Record<PEP_RELATION, string> = {
  [PEP_RELATION.CHILD]: "Child",
  [PEP_RELATION.PARENT]: "Parent",
  [PEP_RELATION.SIBLING]: "Sibling",
  [PEP_RELATION.PARTNER]: "Partner",
  [PEP_RELATION.CO_WORKER]: "Co-worker",
  [PEP_RELATION.CHILD_IN_LAW]: "Child in law",
  [PEP_RELATION.PARENT_IN_LAW]: "Parent in law",
};

export enum PEP_TYPE {
  NOT_PEP = "NOT_PEP",
  PEP = "PEP",
  RELATIVE_TO_PEP = "RELATIVE_TO_PEP",
  PEP_AND_PEP_RELATIVE = "PEP_AND_PEP_RELATIVE",
}

export interface Pep {
  type: PEP_TYPE;
  relation?: PEP_RELATION;
  startedPosition?: string;
  leftPosition?: string;
  position?: PEP_POSITION;
  country?: string;
  firstName?: string;
  lastName?: string;
}

export interface Associate {
  associateId: AssociateId;
  roles: AssociateRole[];
  contractViewed?: UTCDate;
  contact: Contact;
  id: Id;
  owner?: AssociateOwner;
  signatory?: AssociateSignatory;
  pep?: Pep;
}

export enum ViewerStatus {
  INFORMATION = "INFORMATION",
  SIGNATORY = "SIGNATORY",
  PAYOUT_ACCOUNT = "PAYOUT_ACCOUNT",
  DOCUMENTS = "DOCUMENTS",
  DONE = "DONE",
}

export interface AssociateContractViewer {
  associateId: AssociateId;
  language: Language;
  status: ViewerStatus;
}

export enum ContractFeature {}

export interface Contract {
  enabledFeatures: ContractFeature[];
  confirmedContract: boolean;
  contractViewer: AssociateContractViewer;
  contractData: ContractData;
  productType: ProductType;
}

export enum IdDocumentType {
  PASSPORT = "passport",
  ID_CARD = "idCard",
}

export interface Supplement {
  documentId: number;
  documentType?: DocumentType;
  documentDescription?: string;
  uploaded: boolean;
  confirmed?: boolean;
}

export interface Supplements {
  country: string;
  companyName: string;
  contactName: string;
  documents: Supplement[];
}

export interface SaveAddress {
  address: Address;
  companyName: string;
  website?: string;
  contactPhoneNumber?: string;
}

enum WhitelistedEndpoint {
  GET_CONTRACT = "getContract",
  GET_PREVIEW_CONTRACT = "getPreviewContract",
}

export const Merchant = {
  [WhitelistedEndpoint.GET_CONTRACT]: (linkId: LinkId) =>
    API.get<Contract>(`/api/merchant/${linkId}`),

  pingVisit: (linkId: LinkId) => API.post(`/api/merchant/${linkId}/visits`),

  [WhitelistedEndpoint.GET_PREVIEW_CONTRACT]: (contractId: ContractId) =>
    API.get<Contract>(`/api/merchant/preview/${contractId}`),

  confirmContract: (linkId: LinkId, version: ContractVersion) =>
    API.post(`/api/merchant/${linkId}/confirm`, { version: version }).then(
      (resp) => {
        cache.delete(`/api/merchant/${linkId}`);
        return resp;
      }
    ),

  uploadSignature: (linkId: LinkId, signature: string) =>
    API.post(`/api/merchant/${linkId}/signature`, {
      signature: signature,
    }).then((resp) => {
      cache.delete(`/api/merchant/${linkId}`);
      return resp;
    }),

  sendDeviceSwitchLink: (linkId: LinkId, phoneNumber: string) =>
    API.post(`/api/merchant/sign/${linkId}/send-link`, {
      phoneNumber,
    }).then((resp) => {
      cache.delete(`/api/merchant/${linkId}`);
      return resp;
    }),

  saveInvoiceAddress: (linkId: LinkId, invoiceAddress: Address) =>
    API.post(`/api/merchant/${linkId}/invoice`, invoiceAddress),

  getSupplements: (linkId: LinkId) =>
    API.get<Supplements>(`/api/merchant/${linkId}/supplements`),

  saveAddress: (linkId: LinkId, address: SaveAddress) =>
    API.post(`/api/merchant/${linkId}/company`, address),

  saveBax: (linkId: LinkId, bax: string) =>
    API.post(`/api/merchant/${linkId}/bax`, { bax }),

  saveIntegrations: (linkId: LinkId, integrations: Integrations) =>
    API.post(`/api/merchant/${linkId}/integrations`, integrations),
};

let merchant: typeof Merchant;

export function dataMerchant(access: Access) {
  if (!merchant) {
    merchant = getProxy(Merchant, access, Object.values(WhitelistedEndpoint));
  }

  return merchant;
}
