import { ValidationResponse, VALIDATION_STATE } from "../hooks/useValidation";
import { BaseValidator } from "./BaseValidator";

export class PhoneCountryCodeValidator extends BaseValidator {
  name = "PhoneCountryCodeValidator";

  validate(value: string): Promise<ValidationResponse> {
    return new Promise<ValidationResponse>((resolve) => {
      if (value.length === 0 || value[0] === "+") {
        resolve({ status: VALIDATION_STATE.SUCCESS });
      } else {
        resolve({
          status: VALIDATION_STATE.FAILED,
          message: this.error,
        });
      }
    });
  }
}

// function getPrefixLength(value: string) {
//   let i = 0;
//   let len = phoneCountryCodes.length;
//   for (i = 0; i < len; i++) {
//     const phonePrefix = phoneCountryCodes[i].dial_code;
//     if (value.startsWith(phonePrefix)) {
//       return phonePrefix.length;
//     }
//   }

//   return -1;
// }

// export function validatorPhone(
//   message?: string | ReactNode
// ): (value: string) => StatusMessage | null {
//   return (value) => {
//     if (!value) {
//       return null;
//     }

//     const cleanedValue = value.replace(/\s+/g, "");
//     const prefixLength = getPrefixLength(cleanedValue);
//     const phoneLength = cleanedValue.length - prefixLength;

//     if (!cleanedValue.startsWith("+")) {
//       return {
//         status: Status.ERROR,
//         message:
//           message || "Phone number must start with a country code e.g '+46...'",
//       };
//     }

//     if (prefixLength < 0) {
//       return {
//         status: Status.ERROR,
//         message: message || `We could not find this country code`,
//       };
//     }

//     if (phoneLength < 6) {
//       return {
//         status: Status.ERROR,
//         message:
//           message ||
//           `Phone number, country prefix excluded, cannot be less than 6 characters`,
//       };
//     }

//     if (phoneLength > 12) {
//       return {
//         status: Status.ERROR,
//         message:
//           message ||
//           `Phone number, country prefix excluded, cannot be more than 12 characters`,
//       };
//     }

//     return null;
//   };
// }
