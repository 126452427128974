import { FunctionComponent, useMemo } from "react";
import styles from "../Offering.module.scss";
import { TerminalCard } from "./TerminalCard";
import { TerminalType } from "../../../../../../data/models/ContractTypes";
import { T } from "../../../../../../components/translation/T";

interface Props {
  numberOfWiredTerminals: number;
  numberOfWirelessIntegrated: number;
  numberOfWirelessStandalone: number;
}

export const Terminals: FunctionComponent<Props> = ({
  numberOfWiredTerminals,
  numberOfWirelessIntegrated,
  numberOfWirelessStandalone,
}) => {
  const numberOfWirelessTerminals = useMemo(
    () => numberOfWirelessIntegrated + numberOfWirelessStandalone,
    [numberOfWirelessIntegrated, numberOfWirelessStandalone]
  );

  return (
    <>
      {numberOfWirelessTerminals > 0 && (
        <div className={styles.terminalsTitle}>
          <div className={styles.sectionTitle}>
            <T>Wireless terminals</T>
          </div>
          <div className={styles.terminalGrid}>
            <TerminalCard
              terminalType={TerminalType.WIRELESS_INTEGRATED}
              quantity={numberOfWirelessTerminals}
              addOn={
                numberOfWirelessStandalone > 1 ? (
                  <T
                    id="(of which {{numberOfWirelessStandalone}} are of type Standalone)"
                    options={{
                      numberOfWirelessStandalone,
                    }}
                  />
                ) : (
                  <T
                    id="(of which {{numberOfWirelessStandalone}} is of type Standalone)"
                    options={{
                      numberOfWirelessStandalone,
                    }}
                  />
                )
              }
            />
          </div>
        </div>
      )}
      {numberOfWiredTerminals > 0 && (
        <div className={styles.terminalsTitle}>
          <div className={styles.sectionTitle}>
            <T>Wired terminals</T>
          </div>
          <div className={styles.terminalGrid}>
            <TerminalCard
              terminalType={TerminalType.WIRED_INTEGRATED}
              quantity={numberOfWiredTerminals}
            />
          </div>
        </div>
      )}
    </>
  );
};
