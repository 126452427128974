export type ContractId = string & { isContractId: true };
export type AssociateId = string & { isAssociateId: true };
export type TerminalId = number & { isTerminalId: true };
export type LinkId = string & { isLinkId: true };
export type UTCDate = string & { isUTCDate: true };
export type DocumentId = number & { isDocumentId: true };
export type ContractVersion = number & { isContractVersion: true };
export type KlarnaToken = string & { isKlarnaToken: true };
export type StoreId = number & { isStoreId: true };

export const NO_COUNTRY = undefined;

export type SwedishCompanyRegistrationId = string & {
  isSwedishCompanyRegistrationId: true;
};
export type NorweiganCompanyRegistrationId = string & {
  isNorweiganCompanyRegistrationId: true;
};
export type DanishCompanyRegistrationId = string & {
  isDanishCompanyRegistrationId: true;
};
export type FinnishCompanyRegistrationId = string & {
  isFinnishCompanyRegistrationId: true;
};

export type CompanyRegistrationId =
  | SwedishCompanyRegistrationId
  | NorweiganCompanyRegistrationId
  | DanishCompanyRegistrationId
  | FinnishCompanyRegistrationId;

export enum IdType {
  NATIONAL_ID_CARD = "NATIONAL_ID_CARD",
  PASSPORT = "PASSPORT",
}

// https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2
export enum Country {
  SWEDEN = "SE",
  DENMARK = "DK",
  NORWAY = "NO",
  FINLAND = "FI",
  GB = "GB",
}

export enum Language {
  ENGLISH = "en",
  SWEDISH = "sv",
  DANISH = "da",
  NORWEGIAN = "nb",
  FINNISH = "fi",
}

export enum Currency {
  SWEDEN = "SEK",
  DENMARK = "DKK",
  NORWAY = "NOK",
  FINLAND = "EUR",
  GB = "GBP",
}

export interface Address {
  street: string;
  postalCode: string;
  city: string;
  countryCode: string | Country | typeof NO_COUNTRY;
}

export enum ProductType {
  ACCEPTANCE_ONLINE = "ACCEPTANCE_ONLINE",
  ACCEPTANCE_INSTORE = "ACCEPTANCE_INSTORE",
  BAMBORA_DEVICE = "BAMBORA_DEVICE",
  BAMBORA_DEVICE_SHORT_TERM = "BAMBORA_DEVICE_SHORT_TERM",
  BAMBORA_ONE = "BAMBORA_ONE",
  BAMBORA_ONE_SHORT_TERM = "BAMBORA_ONE_SHORT_TERM",
  CHECKOUT = "CHECKOUT",
}

export enum EcomType {
  WEB = "WEB",
  APP = "APP",
  MOTO = "MOTO",
}

export enum LegalEntityType {
  LIMITED = "LIMITED",
  SOLE_PROPRIETARY = "SOLE_PROPRIETARY",
  PARTNERSHIP = "PARTNERSHIP",
  LIMITED_PARTNERSHIP = "LIMITED_PARTNERSHIP",
  ASSOCIATION = "ASSOCIATION",
  TRUST = "TRUST",
  MUNICIPALITY = "MUNICIPALTY",
}

export enum VerifiedByAlternativeMethod {
  KLARNA = "KLARNA",
}

export enum TerminalType {
  WIRED_INTEGRATED = "wiredIntegrated",
  WIRELESS_INTEGRATED = "wirelessIntegrated",
  WIRELESS_STANDALONE = "wirelessStandalone",
  WIRED_INTEGRATED_ANDROID = "wiredIntegratedAndroid",
  WIRELESS_INTEGRATED_ANDROID = "wirelessIntegratedAndroid",
  WIRELESS_STANDALONE_ANDROID = "wirelessStandaloneAndroid",
}

export enum DocumentType {
  ID_DOCUMENT = "ID_DOCUMENT",
  BANK_STATEMENT = "BANK_STATEMENT",
  BUSINESS_CERTIFICATE = "BUSINESS_CERTIFICATE",
  TAXI_LICENSE = "TAXI_LICENSE",
  POWER_OF_ATTORNEY = "POWER_OF_ATTORNEY",
  PROOF_OF_ACCREDITATION = "PROOF_OF_ACCREDITATION",
  FINANCIAL_STATEMENT = "FINANCIAL_STATEMENT",
  SALES_CUSTOM_REQUEST = "SALES_CUSTOM_REQUEST",
  APP_SCREENSHOT = "APP_SCREENSHOT",
  CONTROL_STRUCTURE = "CONTROL_STRUCTURE",
  RECORD_OF_NOMINEE_SHAREHOLDERS = "RECORD_OF_NOMINEE_SHAREHOLDERS",
  LETTER_OF_SHAREHOLDER_CUSTODIAN = "LETTER_OF_SHAREHOLDER_CUSTODIAN",
  ECOM_APPLICATION_SCREENSHOT = "ECOM_APPLICATION_SCREENSHOT",
  SIGNED_CONTRACT = "SIGNED_CONTRACT",
}

export interface Contact {
  position: string;
  phoneNumber: string;
  email: string;
  firstName: string;
  lastName: string;
}

export interface ContractDocument {
  documentId: DocumentId;
  confirmed?: UTCDate;
  fileAvailable: boolean;
  uploaded?: UTCDate;
  filename?: string;
  fileName?: string; // Urk, naming inconsistencies
  mimeType?: string;
  documentType: DocumentType;
  documentDescription?: string; //Den här är satt om sales har skrivit in ett custom dokument, annrs bör språkfil används
}

export const languageByCountry: { [key in Country]: Language } = {
  [Country.SWEDEN]: Language.SWEDISH,
  [Country.DENMARK]: Language.DANISH,
  [Country.NORWAY]: Language.NORWEGIAN,
  [Country.FINLAND]: Language.FINNISH,
  [Country.GB]: Language.ENGLISH,
};
