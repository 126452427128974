import { ReactNode } from "react";
import { T } from "../translation/T";

export const DataListItem = ({
  label,
  value,
}: {
  label: string | ReactNode;
  value?: ReactNode | string | number;
}) => {
  if (typeof value === "undefined") {
    return null;
  }

  return (
    <>
      <dt>
        <span>{typeof label === "string" ? <T>{label}</T> : label}</span>
      </dt>
      <dd>
        <span>{value}</span>
      </dd>
    </>
  );
};
