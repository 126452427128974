import React, { useCallback } from "react";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import { Status } from "../../data/types";
import { Pending } from "../icons/Pending";
import { Button } from "../interactions/Buttons/Button";
import styles from "./Retry.module.scss";

interface Props {
  retry?: () => void;
  children: React.ReactNode | string;
  status: Status;
}

export const Retry: React.FC<Props> = (props) => {
  const { retry, children, status } = props;
  const { t } = useTranslation();

  const onRetry = useCallback(() => {
    if (retry) {
      retry();
    }
  }, [retry]);

  let elem = null;
  if (status === Status.PENDING) {
    elem = (
      <div className={cx(styles.error, "retry-pending", "center")}>
        <Pending />
      </div>
    );
  }

  if (status === Status.ERROR) {
    elem = (
      <div className={styles.error}>
        <p>{t("Something went wrong. Try again?")}</p>
        {retry && <Button onClick={onRetry}>{t("Retry")}</Button>}
      </div>
    );
  }

  if (status === Status.SUCCESS) {
    elem = children;
  }

  return <>{elem}</>;
};
