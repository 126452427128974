import React, {
  ReactNode,
  Children,
  useEffect,
  useRef,
  useMemo,
  useCallback,
  useState,
} from "react";
import cx from "classnames";
import styles from "./Steps.module.scss";
import { Checkmark } from "../icons/Checkmark";
import { OpacitySwitch } from "../opacitySwitch/OpacitySwitch";
import { motion } from "framer-motion";

interface Props {
  children: ReactNode;
  index: number;
  length?: number;
  hideProgress?: boolean;
  header?: string | string[];
  insideOverlay?: boolean;
}

export const Steps: React.FunctionComponent<Props> = ({
  children,
  index,
  length,
  hideProgress = false,
  header = null,
  insideOverlay = false,
}) => {
  const len = length || Children.count(children);
  const wrapper = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [scrolled, setScrolled] = useState<boolean>(false);

  useEffect(() => {
    setTimeout(() => {
      containerRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }, 300);
  }, [index]);

  let headerElem = null;
  if (header) {
    if (Array.isArray(header)) {
      headerElem = header[index];
    } else {
      headerElem = header;
    }
  }

  const onScroll = useCallback((ev: React.UIEvent<HTMLDivElement>) => {
    if (ev.currentTarget.scrollTop > 12) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  }, []);

  useEffect(() => {
    if (!insideOverlay) {
      return;
    }

    if (!wrapper.current) {
      return;
    }

    const overlay = wrapper.current.closest(".overlay-body");

    if (!overlay) {
      return;
    }

    (overlay as any).addEventListener("scroll", onScroll);

    return () => (overlay as any).removeEventListener("scroll", onScroll);
  }, [onScroll, insideOverlay]);

  const items = useMemo(
    () => React.Children.toArray(children).filter(Boolean),
    [children]
  );

  return (
    <div className={styles.container} ref={containerRef}>
      <div className={styles.headerWrapper}>
        {headerElem && (
          <b className={styles.header}>
            <OpacitySwitch text={headerElem} />
          </b>
        )}

        <div
          className={cx(styles.progress, {
            [styles["hide-progress"]]: hideProgress,
            [styles.scrolled]: scrolled,
          })}
        >
          <div className={styles["bg-bar"]} />
          <div
            className={styles["progress-bar"]}
            style={{ width: `${(100 * index) / (len - 1)}%` }}
          />
          <ul className={styles.indicators}>
            {[...Array(len)].map((_, i) => (
              <motion.li
                layout
                key={`indicator-${i}`}
                className={cx(styles.indicator, {
                  [styles.done]: index > i - 1,
                  [styles.active]: index === i,
                })}
                initial={{
                  opacity: 0,
                }}
                animate={{
                  opacity: 1,
                  scale: index === i ? 1.3 : 1,
                }}
                transition={{
                  type: "spring",
                  stiffness: 175,
                  damping: 20,
                }}
              >
                {i + 1 === len && index + 1 === i + 1 ? <Checkmark /> : i + 1}
              </motion.li>
            ))}
          </ul>
        </div>
      </div>
      <div
        className={cx(styles.steps, {
          [styles.wrapper]: insideOverlay,
        })}
        ref={wrapper}
      >
        <div
          className={cx(styles["steps-content"])}
          style={{
            transform: `translateX(${index * -10}%)`,
          }}
        >
          {items.map((child, idx) => (
            <div
              key={idx}
              style={{ visibility: idx === index ? "visible" : "hidden" }}
            >
              {child}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
