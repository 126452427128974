import React, { ReactNode } from "react";
import { QuestionMark } from "../icons/QuestionMark";
import cx from "classnames";
import styles from "./TooltipWrapper.module.scss";

interface Props {
  children: ReactNode;
  selector: string;
}

export const TooltipWrapper: React.FunctionComponent<Props> = ({
  children,
  selector,
}) => {
  const tag = selector.replace(".", "");

  return (
    <button
      className={cx(tag, styles.button)}
      onClick={(ev) => ev.preventDefault()}
    >
      {children} <QuestionMark className={styles.icon} />
    </button>
  );
};
