import React, { ReactNode, useMemo } from "react";
import cx from "classnames";
import { Validity } from "../../data/types";
import { ErrorOutline } from "../icons/ErrorOutline";
import { Checkmark } from "../icons/Checkmark";
import styles from "./Badge.module.scss";
import { InfoOutline } from "../icons/InfoOutline";

enum BadgeInfoType {
  INFO = "info-information",
}

type BadgeType = Validity | BadgeInfoType;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BadgeType = { ...Validity, ...BadgeInfoType };

interface Props {
  type?: BadgeType;
  children?: ReactNode;
  truncate?: boolean;
  icon?: boolean;
  className?: string;
  content?: string | React.ReactNode;
}

export const Badge: React.FunctionComponent<Props> = ({
  type = BadgeType.INFO,
  children,
  truncate = false,
  icon = true,
  className,
  content,
}) => {
  const badgeIcon = useMemo(() => {
    if (type === BadgeType.MISSING) {
      return <ErrorOutline className={styles.icon} />;
    }

    if (type === BadgeType.VALID) {
      return <Checkmark className={styles.icon} />;
    }

    return <InfoOutline className={styles.icon} />;
  }, [type]);

  return (
    <div
      className={cx(styles.badge, "text-small", styles[type], className, {
        [styles.truncate]: truncate,
        [styles["no-icon"]]: !icon,
      })}
    >
      {icon && <span className={styles["icon-wrapper"]}>{badgeIcon}</span>}
      <div className={cx(styles.text, { truncate })}>{content || children}</div>
    </div>
  );
};
