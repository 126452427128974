import React, { useRef } from "react";
import { useRecoilValue } from "recoil";
import { contractState } from "../../../state/contractState";
import { SummaryInner } from "../../DataCollection/Summary/Summary";
import { StoryPage } from "../../../components/story/StoryPage";
import { useLinkId } from "../../../hooks/useLinkId";
import { useSuspendedQuery } from "../../../hooks/useSuspendedQuery";
import { dataFinancial } from "../../../data/dataFinancial";
import { Access } from "../../../data/proxy";

export const Sign: React.FunctionComponent = () => {
  const contract = useRecoilValue(contractState);
  const ref = useRef<HTMLDivElement>(null);
  const linkId = useLinkId();
  const { data: financial } = useSuspendedQuery(
    dataFinancial(Access.VIEW_AND_EDIT).fetchFinancial(linkId)
  );

  return (
    <StoryPage ref={ref}>
      <SummaryInner data={contract.contractData} financial={financial}>
        <div />
      </SummaryInner>
    </StoryPage>
  );
};
