import { Logo } from "../components/images/Logo";
import { T } from "../components/translation/T";
import styles from "./InvalidLink.module.scss";

export function InvalidLink() {
  // const tooltipId = useRef<string>(id());
  // const { t } = useTranslation();
  // const selector = `.${tooltipId.current}`;

  return (
    <div className={styles.wrapper}>
      {/* <Tooltip
        positionStrategy="absolute"
        anchorSelect={selector}
        content={t("Specify your specific gateway solution")}
      /> */}

      <section>
        <article>
          <div className="logo-wrapper">
            <Logo />
          </div>
          <p>
            <T>This page does not exist.</T>
            <br />
            <T>Please check the link in your text or email.</T>
            {/* <Trans t={t}>
              <TooltipWrapper selector={selector}>
                Please check the link
              </TooltipWrapper>
              in your text or email.
            </Trans> */}
          </p>
        </article>
      </section>
    </div>
  );
}
