import { useMemo } from "react";
import { Navigate, Route } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { Story } from "../../components/story/Story";
import { T } from "../../components/translation/T";
import { contractState } from "../../state/contractState";
import { routeState } from "../../state/routeState";
import { useDataCollectionRoutes } from "./useDataCollectionRoutes";

export const DATA_COLLECTION_STORY_URL = "/:linkId/";

export const DataCollectionStory = () => {
  const state = useRecoilValue(routeState);
  const { contractData } = useRecoilValue(contractState);
  const { routes, routeElements } = useDataCollectionRoutes(
    contractData.legalEntityType,
    state.productType,
    state
  );

  const fallbackRoute = useMemo(
    () => Object.values(routes)?.[0] || "/",
    [routes]
  );

  return (
    <Story
      headerText={<T>Onboarding</T>}
      routes={routes}
      baseRoute={DATA_COLLECTION_STORY_URL}
      showBack={state.showBack}
    >
      {routeElements}
      <Route path="*" element={<Navigate replace to={fallbackRoute} />} />
    </Story>
  );
};
