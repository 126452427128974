import React from "react";
import styles from "./GenericSuccess.module.scss";
import { Logo } from "../../components/images/Logo";
import { LanguageSwitch } from "../../components/languageSwitch/LanguageSwitch";
import { T } from "../../components/translation/T";

export const GENERIC_SUCCESS_URL = "/success";

export const GenericSuccess: React.FunctionComponent = () => {
  return (
    <section>
      <article>
        <div className={styles.wrapper}>
          <div className="logo-wrapper">
            <Logo />
          </div>
          <div className="m-top-30">
            <LanguageSwitch />
          </div>
          <div className={styles.header}>
            <h2>
              <T>Thank you!</T>
            </h2>
          </div>
          <p>
            <T>Everything is looking good! It is safe to close this tab.</T>
          </p>
        </div>
      </article>
    </section>
  );
};
