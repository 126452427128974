import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from "react";
import {
  Associate,
  AssociateContractViewer,
  Contract,
  EcomStore,
  ViewerStatus,
  dataMerchant,
} from "../../data/dataMerchant";
import {
  Country,
  EcomType,
  LinkId,
  ProductType,
  languageByCountry,
} from "../../data/models/ContractTypes";
import { useSetRecoilState } from "recoil";
import { Retry } from "../../components/retry/Retry";
import { Status } from "../../data/types";
import { useLinkId } from "../../hooks/useLinkId";
import { cache } from "../../network/Cache";
import { contractState } from "../../state/contractState";
import { Outlet, generatePath, matchPath, useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { dataDocuments } from "../../data/dataDocuments";
import { routeState } from "../../state/routeState";
import { dataAssociates, isSignee } from "../../data/dataAssociates";
import { dataBank } from "../../data/dataBank";
import { dataStores } from "../../data/dataStores";
import { DOCUMENT_URL } from "../DocumentCollection/DocumentCollection";
import { PAYOUT_ACCOUNT_URL } from "../PayoutAccountCollection/PayoutAccountCollectionStory";
import { SIGNATORY_URL } from "../SignatoryCollection/SignatoryCollection";
import { DONE_URL } from "../Done/Done";
import { useTranslation } from "react-i18next";
import { Access } from "../../data/proxy";

interface Props {
  associates: Associate[];
  contractViewer: AssociateContractViewer;
  confirmedContract: boolean;
  confirmedBank: boolean;
}

export function isDev() {
  return !process.env.NODE_ENV || process.env.NODE_ENV === "development";
}

export function isEcom(contract: Contract) {
  return (
    contract.productType === ProductType.ACCEPTANCE_ONLINE ||
    contract.productType === ProductType.CHECKOUT
  );
}

export function getViewer(
  contractViewer: AssociateContractViewer,
  associates: Associate[]
) {
  return associates.find(
    (associate) => associate.associateId === contractViewer.associateId
  );
}

export function getViewerByRequiredProps(required: Props) {
  return getViewer(required.contractViewer, required.associates);
}

export const ContractLoader: FunctionComponent = () => {
  const { i18n } = useTranslation();
  const [status, setStatus] = useState<Status>(Status.PENDING);
  const [required, setRequired] = useState<Props>();
  const setContract = useSetRecoilState(contractState);
  const setRouteState = useSetRecoilState(routeState);
  const linkId = useLinkId();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const load = useCallback(
    (idParam: LinkId) => {
      setStatus(Status.PENDING);

      Promise.all([
        dataMerchant(Access.VIEW_AND_EDIT).getContract(idParam),
        dataDocuments(Access.VIEW_AND_EDIT).getDocuments(idParam),
        dataAssociates(Access.VIEW_AND_EDIT).getAssociates(idParam),
        dataBank(Access.VIEW_AND_EDIT).getConfirmedStatus(idParam),
      ])
        .then((responses) => {
          const [contract, documents, associates, confirmedBank] = responses;
          queryClient.setQueryData(
            dataDocuments(Access.VIEW_AND_EDIT).getDocumentsKey(idParam),
            documents
          );
          queryClient.setQueryData(
            dataBank(Access.VIEW_AND_EDIT).getConfirmedStatusKey(idParam),
            confirmedBank
          );
          queryClient.setQueryData(
            dataAssociates(Access.VIEW_AND_EDIT).getAssociatesKey(idParam),
            associates
          );

          const everyAssociateHaveId = associates
            .filter((associate) => isSignee(associate))
            .every((associate) => !!associate.id?.nationalPersonId);

          setContract(contract);
          const viewerLanguage =
            contract.contractViewer.language ||
            languageByCountry[contract.contractData.country as Country];

          let lang;
          try {
            const urlSearchParams = new URLSearchParams(window.location.search);
            ({ lang } = Object.fromEntries(urlSearchParams.entries()));
          } catch (err) {}

          i18n.changeLanguage(lang || viewerLanguage);

          let promise;
          if (isEcom(contract)) {
            promise = dataStores(Access.VIEW_AND_EDIT).getEcomStore(idParam);
          } else {
            promise = dataStores(Access.VIEW_AND_EDIT).getStores(idParam);
          }

          return promise.then((resp) => {
            if (isEcom(contract)) {
              queryClient.setQueryData(
                dataStores(Access.VIEW_AND_EDIT).getEcomStoresKey(idParam),
                resp
              );
            } else {
              queryClient.setQueryData(
                dataStores(Access.VIEW_AND_EDIT).getStoresKey(idParam),
                resp
              );
            }

            setRouteState((prev) => ({
              ...prev,
              hasGenericDocuments: !!documents.length,
              // hasSelfCheckout: !!contract.selfCheckout,
              legalEntityType: contract.contractData.legalEntityType,
              productType: contract.productType,
              ecomType: (resp as EcomStore).type || EcomType.WEB,
              country: contract.contractData.country,
              everyAssociateHaveId,
            }));

            setRequired({
              associates: associates,
              contractViewer: contract.contractViewer,
              confirmedContract: contract.confirmedContract,
              confirmedBank: confirmedBank.done,
            });
          });
        })
        .catch((err) => {
          console.log("err", err);
          setStatus(Status.ERROR);
        });
    },
    [setContract, queryClient, setRouteState, i18n]
  );

  useEffect(() => {
    // set "visit" flag for this associate
    // don't care about the actual outcome
    dataMerchant(Access.VIEW_AND_EDIT)
      .pingVisit(linkId)
      .catch(() => {});
  }, [linkId]);

  useEffect(() => {
    load(linkId);
    return () => {
      // Everything should be fetched from the server on hard navigation
      cache.clear();
    };
  }, [load, linkId]);

  const retry = useCallback(() => {
    setStatus(Status.PENDING);
    setTimeout(() => {
      load(linkId);
    }, 500);
  }, [load, linkId]);

  const render = useCallback(() => setStatus(Status.SUCCESS), []);
  // Primary redirect
  useEffect(() => {
    if (!required) {
      return;
    }

    const { contractViewer } = required;

    if (contractViewer.status === ViewerStatus.INFORMATION) {
      render();
      return;
    }

    const { pathname } = window.location;

    if (contractViewer.status === ViewerStatus.DOCUMENTS) {
      const isDocPage = matchPath(`${DOCUMENT_URL}`, pathname);

      if (isDocPage) {
        render();
        return;
      }

      navigate(
        generatePath(DOCUMENT_URL, {
          linkId,
        })
      );
      render();
      return;
    }

    if (contractViewer.status === ViewerStatus.PAYOUT_ACCOUNT) {
      const isPayoutAccountPage = matchPath(`${PAYOUT_ACCOUNT_URL}*`, pathname);

      if (isPayoutAccountPage) {
        render();
        return;
      }

      navigate(
        generatePath(PAYOUT_ACCOUNT_URL, {
          linkId,
        })
      );
      render();
      return;
    }

    if (contractViewer.status === ViewerStatus.SIGNATORY) {
      const isSignatoryPage = matchPath(`${SIGNATORY_URL}`, pathname);

      if (isSignatoryPage) {
        render();
        return;
      }

      navigate(
        generatePath(SIGNATORY_URL, {
          linkId,
        })
      );
      render();
      return;
    }

    navigate(
      generatePath(DONE_URL, {
        linkId,
      })
    );
    render();
  }, [required, render, linkId, navigate]);

  return (
    <Retry retry={retry} status={status}>
      <Outlet />
    </Retry>
  );
};
