import { useEffect, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Card } from "../../../components/cards/Card";
import {
  MultiForm,
  MultiFormContainer,
} from "../../../components/form/MultiForm";
import { Link } from "../../../components/link/Link";
import { StoryStepProps } from "../../../components/story/Story";
import { StoryContinueButton } from "../../../components/story/StoryContinueButton";
import { T } from "../../../components/translation/T";
import { dataAssociates, isOwner } from "../../../data/dataAssociates";
import { Associate } from "../../../data/dataMerchant";
import { useLinkId } from "../../../hooks/useLinkId";
import { useSuspendedQuery } from "../../../hooks/useSuspendedQuery";
import { PepOverlay } from "./components/PepOverlay";
import { useRecoilValue } from "recoil";
import { routeState } from "../../../state/routeState";
import styles from "./Pep.module.scss";
import { Access } from "../../../data/proxy";

export const Pep: React.FunctionComponent<StoryStepProps> = ({ next }) => {
  const linkId = useLinkId();
  const { access } = useRecoilValue(routeState);
  const { t } = useTranslation();
  const { data } = useSuspendedQuery(
    dataAssociates(access).fetchAssociates(linkId)
  );
  const [associates, setAssociates] = useState<Associate[]>([]);

  useEffect(
    () =>
      setAssociates(
        data
          .filter((associate) => isOwner(associate))
          .sort((a, b) =>
            a.contact.firstName.localeCompare(b.contact.firstName)
          )
      ),
    [data]
  );

  const ref = useRef<HTMLDivElement>(null);
  const formContainer = useRef<MultiFormContainer>();

  const onSave = async () => {
    if (!formContainer.current || formContainer.current.isInvalid) {
      formContainer.current?.forceValidation();
      return;
    }

    next();
  };

  return (
    <>
      <div>
        <h1>
          <T>Politically Exposed Person</T>
        </h1>

        <p>
          <T>
            The person(s) listed below are the ones you have registered as
            beneficial owners of your company. Worldline has a duty to find out
            if any of these people are a so-called PEP which means that you have
            at one time held a high public position in a country or
            international organization or are related to someone who held such a
            position (e.g. minister in parliament or judge in the supreme
            court).
          </T>
        </p>
        <p>
          <T>Please fill in this information about the people below.</T>
        </p>
        <p>
          <Trans t={t}>
            It is quite unusual to be a PEP, but if you are unsure, you can read
            more{" "}
            <Link
              external
              link="https://www.swedishbankers.se/media/3462/1710_pep_eng_ny.pdf"
            >
              here
            </Link>
          </Trans>
        </p>
      </div>
      <MultiForm multiFormContainer={formContainer}>
        <div className="flex-columns gap-20 m-top-40" ref={ref}>
          {associates.map((associate) => (
            <Card key={associate.associateId}>
              <PepOverlay associate={associate} />
            </Card>
          ))}
        </div>

        <div className={styles.continue}>
          <StoryContinueButton
            type="button"
            disabled={access === Access.VIEW}
            onClick={onSave}
          >
            <T>Continue</T>
          </StoryContinueButton>
        </div>
      </MultiForm>
    </>
  );
};
