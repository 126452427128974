import { FunctionComponent } from "react";
import { PricePlanTable } from "../../../../../../components/pricePlanTable/PricePlanTable";
import { T } from "../../../../../../components/translation/T";
import styles from "../Offering.module.scss";
import { PricePlan } from "../../../../../../data/dataPricing";
interface Props {
  pricePlan: PricePlan;
}

export const Pricing: FunctionComponent<Props> = ({ pricePlan }) => {
  return (
    <div>
      <br />
      <div className={styles.sectionTitle}>
        <T>Price Package</T>
      </div>
      <PricePlanTable pricePlan={pricePlan} />
    </div>
  );
};
