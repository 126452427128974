import React, { useEffect, useMemo, useRef, useState } from "react";
import cx from "classnames";
import { PersistentOverlay } from "../../../../components/overlay/PersistantOverlay";
import { Button } from "../../../../components/interactions/Buttons/Button";
import styles from "./OwnershipCompanyOverlay.module.scss";
import { Beacon } from "../../../../components/beacon/Beacon";
import { Validity } from "../../../../data/types";
import { Edit } from "../../../../components/icons/Edit";
import { T } from "../../../../components/translation/T";
import { Badge } from "../../../../components/badges/Badge";
import { RequiredValidator } from "../../../../components/form/validators/RequiredValidator";
import { Form, FormContainer } from "../../../../components/form/Form";
import { Trash } from "../../../../components/icons/Trash";
import { TextInput } from "../../../../components/form/TextInput";
import { NumberInput } from "../../../../components/form/NumberInput";
import { MinValidator } from "../../../../components/form/validators/MinValidator";
import { Select } from "../../../../components/form/Select";
import { MaxValidator } from "../../../../components/form/validators/MaxValidator";
import {
  getCountryOpts,
  getIntlNumberFormat,
} from "../../../../components/utils";
import { Language } from "../../../../data/models/ContractTypes";
import { useTranslation } from "react-i18next";
import { id as generateId } from "../../../../components/utils";
import { OwnershipCompany } from "../../../../data/dataOwnership";
import { useRecoilValue } from "recoil";
import { contractState } from "../../../../state/contractState";

interface Props {
  company: OwnershipCompany;
  onChange: (company: OwnershipCompany) => void;
  onRemove: () => void;
}

export const OwnershipCompanyOverlay: React.FunctionComponent<Props> = ({
  company,
  onChange,
  onRemove,
}) => {
  const { t, i18n } = useTranslation();
  const contract = useRecoilValue(contractState);
  const formContainer = useRef<FormContainer>();
  const [formId] = useMemo(() => [`OwnershipCompany-${generateId()}`], []);
  const [isOpen, setIsOpen] = useState(!!company.new);

  const [internalCompany, setInternalCompany] = useState(company);

  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    const onUpdate = () => {
      if (
        formContainer.current &&
        isFormValid !== formContainer.current.isValid
      ) {
        setIsFormValid(formContainer.current.isValid);
      }
    };

    const container = formContainer.current;
    container?.addListener(onUpdate);
    return () => {
      container?.removeListener(onUpdate);
    };
  }, [isFormValid]);

  const countryOptions = useMemo(() => {
    return getCountryOpts(t, i18n.language as Language, false);
  }, [t, i18n.language]);

  return (
    <>
      <div className={styles.row}>
        <Beacon
          className={cx("small", styles.beacon)}
          validity={isFormValid ? Validity.VALID : Validity.MISSING}
        />

        <div className={styles.name}>
          <div className="truncate">
            {company.name ? <>{company.name}</> : <T>Missing name</T>}
          </div>
          <div>
            {!isFormValid ? (
              <Badge truncate>
                <T>Please add information</T>
              </Badge>
            ) : (
              <Badge truncate>
                <T>Ownership share</T>:{" "}
                {getIntlNumberFormat(
                  i18n.language,
                  company.ownershipPercentage
                    ? company.ownershipPercentage / 100
                    : 0,
                  0,
                  "percent"
                )}
              </Badge>
            )}
          </div>
        </div>
        <div>
          <Button
            action
            className={cx("small", styles.action)}
            onClick={() => setIsOpen(true)}
          >
            <Edit />
          </Button>
          <Button
            action
            className={cx("small", "danger-button", styles.action)}
            onClick={onRemove}
          >
            <Trash />
          </Button>
        </div>
      </div>

      <PersistentOverlay
        open={isOpen}
        onClose={() => setIsOpen(false)}
        className={cx("full-height")}
        bodyClassName={styles.formBody}
      >
        <h4>{internalCompany.name || "-"}</h4>

        <p>
          <T>Information about the legal part</T>
        </p>

        <Form
          formContainer={formContainer}
          name={formId}
          className="m-top-10 flex-1"
          onSubmit={(_, form) => {
            if (form.isInvalid) {
              return;
            }

            setIsOpen(false);
            onChange({ ...internalCompany, new: false });
          }}
        >
          <div>
            <div>
              <TextInput
                label={<T>Company name</T>}
                value={internalCompany.name}
                onChange={(name) =>
                  setInternalCompany({ ...internalCompany, name })
                }
                validators={[new RequiredValidator("Company name is required")]}
              />
            </div>
            <div>
              <TextInput
                label={<T>Registration number</T>}
                value={internalCompany.registrationNumber}
                onChange={(registrationNumber) =>
                  setInternalCompany({
                    ...internalCompany,
                    registrationNumber,
                  })
                }
                validators={[
                  new RequiredValidator("Registration number is required"),
                ]}
              />
            </div>
            <div>
              <p>
                {t("How much of {{company}} does {{parent}} own?", {
                  company: contract.contractData.companyName,
                  parent: internalCompany.name || "-",
                })}
              </p>
              <NumberInput
                name="ownershipPercentage"
                label="Ownership percentage"
                value={internalCompany.ownershipPercentage}
                onChange={(ownershipPercentage) =>
                  setInternalCompany({
                    ...internalCompany,
                    ownershipPercentage,
                  })
                }
                validators={[
                  new RequiredValidator("Ownership percentage is required"),
                  new MinValidator(
                    1,
                    "Ownership percentage must be at least 1%"
                  ),
                  new MaxValidator(
                    100,
                    "Ownership percentage must be at most 100%"
                  ),
                ]}
                min={0}
                max={100}
                suffix="%"
                attributes={{
                  decimalScale: 0,
                }}
              />
            </div>
            <div>
              <Select
                label="Country of incorporation"
                value={internalCompany.countryOfIncorporation}
                onChange={(value) => {
                  setInternalCompany({
                    ...internalCompany,
                    countryOfIncorporation: value,
                  });
                }}
                alternatives={countryOptions}
                validators={[
                  new RequiredValidator("Country of incorporation is required"),
                ]}
              />
            </div>
          </div>

          <div className="m-top-20" />

          <Button type="submit" className={styles.button}>
            <T>Save & close</T>
          </Button>
        </Form>
      </PersistentOverlay>
    </>
  );
};
