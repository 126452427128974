import { AnimationControls, Transition, useAnimation } from "framer-motion";
import { useRef, useEffect } from "react";

const DEFAULT_TRANSITION: Transition = {
  // cool if we wanna delay the animation when the height is decreasing
  // if we wanna wait a bit for the item to animate out before animating the height
  // duration: 0.3,
  // delay: newHeight > height.current ? 0 : 0.15,
  type: "spring",
  stiffness: 160,
  damping: 18,
};

interface Props {
  deps?: any[];
  opacity?: boolean;
  transition?: Transition;
}

export const useAutoHeightAnimation = ({
  deps = [],
  opacity = true,
  transition = DEFAULT_TRANSITION,
}: Props): [AnimationControls, React.RefObject<HTMLDivElement>] => {
  const controls = useAnimation();
  const ref = useRef<HTMLDivElement>(null);
  const height = useRef<number | null>(null);

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    ref.current.style.height = "auto";
    const newHeight = ref.current.offsetHeight;

    if (height.current !== null) {
      controls.set({ height: height.current });
      if (opacity) {
        controls.start({
          opacity: [0, 1],
          transition: {
            duration: 0.35,
          },
        });
      }
      controls
        .start({
          height: newHeight,
          transition,
        })
        .then(() => {
          controls.set({ height: "auto" });
        });
    }

    height.current = newHeight;
  }, [
    ref,
    controls,
    opacity,
    transition,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ...deps,
  ]);

  return [controls, ref];
};
