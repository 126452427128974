import React from "react";
import "./StoreTerminals.scss";

export const StoreTerminals: React.FunctionComponent = () => {
  return null;
  // return (
  //   <div className={cx("store-terminals")}>
  //     {TERMINAL_TYPES.map((type) => {
  //       const count = terminals.filter(
  //         (terminal) => terminal.terminalType === type
  //       ).length;
  //       if (count === 0) {
  //         return null;
  //       }
  //       return <TerminalPreview type={type} count={count} key={type} />;
  //     })}
  //   </div>
  // );
};
