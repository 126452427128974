import React from "react";
import { OwnershipAndControlData } from "../../../../data/dataOwnership";
import { HiddenInput } from "../../../../components/form/HiddenInput";
import { RequiredValidator } from "../../../../components/form/validators/RequiredValidator";

interface Props {
  data: OwnershipAndControlData;
  formRef: React.MutableRefObject<HTMLFormElement | null>;
}

export const ValidationErrors: React.FunctionComponent<Props> = ({
  data,
  formRef,
}) => {
  if (
    data.controlledByOtherLegalPart &&
    !data.controlStructureDocument.fileAvailable
  ) {
    return (
      <HiddenInput
        label="controlStructureDoc"
        value={undefined}
        validators={[
          new RequiredValidator(
            "Attachment of control structure must be uploaded"
          ),
        ]}
        scrollToRef={formRef}
      />
    );
  }

  if (
    data.nomineeShareholders &&
    !data.nomineeShareholdersDocument.fileAvailable
  ) {
    return (
      <HiddenInput
        label="nomineeShareholdersDoc"
        value={undefined}
        validators={[
          new RequiredValidator(
            "Attachment of nominee shareholders must be uploaded"
          ),
        ]}
        scrollToRef={formRef}
      />
    );
  }

  if (
    data.nomineeShareholders &&
    !data.shareholderCustodianDocument.fileAvailable
  ) {
    return (
      <HiddenInput
        label="shareholderCustodianDoc"
        value={undefined}
        validators={[
          new RequiredValidator(
            "A letter of the shareholder custodian must be uploaded"
          ),
        ]}
        scrollToRef={formRef}
      />
    );
  }

  return null;
};
