import React from "react";
import { Beacon } from "../../../../components/beacon/Beacon";
import { Validity } from "../../../../data/types";
import { T } from "../../../../components/translation/T";
import { Associate } from "../../../../data/dataMerchant";
import styles from "../Signature.module.scss";

interface Props {
  signees: Associate[];
}

export const ViewerIsSigneeSigned: React.FunctionComponent<Props> = ({
  signees,
}) => {
  const allSigned = signees.every((signee) => !!signee.signatory?.signed);

  if (allSigned) {
    return (
      <>
        <div className={styles.box}>
          <Beacon validity={Validity.VALID} className={styles.done} />
          <b className="text-large">
            <T>You're done!</T>
          </b>
        </div>
        <p>
          <T>
            Thank your for signing the application. As soon as your application
            has been reviewed we will proceed with the setup.
          </T>
        </p>
        <p>
          <T>It is safe to close this tab.</T>
        </p>
      </>
    );
  }

  return (
    <>
      <div className={styles.box}>
        <Beacon validity={Validity.VALID} className={styles.done} />
        <b className="text-large">
          <T>You're done!</T>
        </b>
      </div>
      <p>
        <T>
          We have sent the application to the other assigned signatories as
          well. Once they have signed the application we will proceed with the
          setup.
        </T>
      </p>
      <p>
        <T>It is safe to close this tab.</T>
      </p>
    </>
  );
};
