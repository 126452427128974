import { FunctionComponent, ReactElement } from "react";
import styles from "../Offering.module.scss";
import { ProductCard } from "./ProductCard";
import { T } from "../../../../../../components/translation/T";
import { ProductType } from "../../../../../../data/models/ContractTypes";
import { Package } from "../../../../../../components/icons/Package";

interface Props {
  productType: ProductType;
}

export interface ProductOption {
  name: string;
  productType: ProductType;
  icon: ReactElement;
  description: string;
}

export const Product: FunctionComponent<Props> = ({ productType }) => {
  const { icon, description, name } = PRODUCT_OFFERINGS[productType];

  return (
    <div>
      <div className={styles.sectionTitle}>
        <T>Product</T>
      </div>
      <ProductCard icon={icon} name={name} description={description} />
    </div>
  );
};

const PRODUCT_OFFERINGS: Record<string, ProductOption> = {
  ACCEPTANCE_ONLINE: {
    name: "acceptance online",
    productType: ProductType.ACCEPTANCE_ONLINE,
    icon: <Package className={styles.largeIcon} />,
    description: "Standalone acquiring E-Com",
  },
  ACCEPTANCE_INSTORE: {
    name: "acceptance instore",
    productType: ProductType.ACCEPTANCE_INSTORE,
    icon: <Package className={styles.largeIcon} />,
    description: "Standalone acquiring instore",
  },
  BAMBORA_DEVICE: {
    name: "Worldline Device",
    productType: ProductType.BAMBORA_DEVICE,
    icon: <Package className={styles.largeIcon} />,
    description: "Terminal rental",
  },
  BAMBORA_DEVICE_SHORT_TERM: {
    name: "Worldline Device short term",
    productType: ProductType.BAMBORA_DEVICE_SHORT_TERM,
    icon: <Package className={styles.largeIcon} />,
    description: "Terminal rental - Short Term",
  },
  BAMBORA_ONE: {
    name: "Worldline One",
    productType: ProductType.BAMBORA_ONE,
    icon: <Package className={styles.largeIcon} />,
    description: "Instore bundle (acquiring + terminal)",
  },
  BAMBORA_ONE_SHORT_TERM: {
    name: "Worldline One short term",
    productType: ProductType.BAMBORA_ONE_SHORT_TERM,
    icon: <Package className={styles.largeIcon} />,
    description: "Instore bundle - Short Term",
  },
  CHECKOUT: {
    name: "checkout",
    productType: ProductType.CHECKOUT,
    icon: <Package className={styles.largeIcon} />,
    description: "E-Com bundle",
  },
};
