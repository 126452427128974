import { useMemo, useRef } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Form } from "../../../../../components/form/Form";
import { RadioGroup } from "../../../../../components/form/RadioGroup";
import { RequiredValidator } from "../../../../../components/form/validators/RequiredValidator";
import { Button } from "../../../../../components/interactions/Buttons/Button";
import { Alternative } from "../../../../../components/interactions/InputTypes";
import { Link } from "../../../../../components/link/Link";
import { T } from "../../../../../components/translation/T";
import { Pep, PEP_TYPE } from "../../../../../data/dataMerchant";

interface Props {
  pep: Pep;
  onChange: (pep: Pep) => void;
  onSubmit: () => void;
  isViewer: boolean;
}

export function getPepQuestions(viewerIsOwner: boolean) {
  if (viewerIsOwner) {
    return [
      {
        value: PEP_TYPE.NOT_PEP,
        text: <T>No, I'm not a PEP</T>,
      },
      {
        value: PEP_TYPE.PEP,
        text: <T>Yes, I'm a PEP</T>,
      },
      {
        value: PEP_TYPE.RELATIVE_TO_PEP,
        text: <T>Yes, I'm a relative or co-worker to a PEP</T>,
      },
      {
        value: PEP_TYPE.PEP_AND_PEP_RELATIVE,
        text: <T>Yes, I'm a PEP and also a relative or co-worker to a PEP</T>,
      },
    ];
  } else {
    return [
      {
        value: PEP_TYPE.NOT_PEP,
        text: <T>No, this person is not a PEP</T>,
      },
      {
        value: PEP_TYPE.PEP,
        text: <T>Yes, this person is a PEP</T>,
      },
      {
        value: PEP_TYPE.RELATIVE_TO_PEP,
        text: <T>Yes, this person is a relative or co-worker to a PEP</T>,
      },
      {
        value: PEP_TYPE.PEP_AND_PEP_RELATIVE,
        text: (
          <T>
            Yes, this person is PEP and also a relative or co-worker to a PEP
          </T>
        ),
      },
    ];
  }
}

export const PepIntro: React.FunctionComponent<Props> = ({
  pep,
  onChange,
  onSubmit,
  isViewer,
}) => {
  const formRef = useRef<HTMLFormElement>(null);
  const { t } = useTranslation();

  const pepAlternatives: Alternative<PEP_TYPE>[] = useMemo(
    () => getPepQuestions(isViewer),
    [isViewer]
  );

  const intro = useMemo(() => {
    if (isViewer) {
      return (
        <Trans t={t}>
          We need to know if you <u>can be considered a PEP</u>, or if you have
          a close <u>relative or co-worker</u> that can be considered a PEP.
        </Trans>
      );
    } else {
      return (
        <Trans t={t}>
          We need to know if this person <u>can be considered a Pep</u>, or has
          a close <u>relative or co-worker</u> that can be considered a PEP.
        </Trans>
      );
    }
  }, [isViewer, t]);

  return (
    <div>
      <div className="m-top-20">
        <p>
          <Trans t={t}>
            A so called PEP (Politically Exposed Person) is a person who is or
            has been entrusted with prominent public functions in a country or
            an international organisation (
            <Link
              external
              link="https://www.swedishbankers.se/media/3462/1710_pep_eng_ny.pdf"
            >
              definition
            </Link>
            ).
          </Trans>
        </p>
        <p>{intro}</p>
      </div>
      <div className="m-top-40">
        <Form
          name="pepIntro"
          ref={formRef}
          onSubmit={(_, form) => {
            if (form.isInvalid) {
              return;
            }
            onSubmit();
          }}
        >
          <RadioGroup
            name="type"
            label="Are you a PEP?"
            className={"wrap-options"}
            alternatives={pepAlternatives}
            validators={[new RequiredValidator("Please select an option")]}
            value={pep.type}
            onChange={(value) => {
              onChange({
                type: value,
              });
            }}
          />

          <div className="columns">
            <div />
            <div className="text-right">
              <Button type="submit" className="m-top-20">
                <T>Next</T>
              </Button>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};
