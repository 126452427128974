import React, { useCallback } from "react";
import cx from "classnames";
import { Button } from "../interactions/Buttons/Button";
import { T } from "../translation/T";
import styles from "./StepButtons.module.scss";

interface Props {
  disabled?: boolean;
  setIndex: React.Dispatch<React.SetStateAction<number>>;
  showBack?: boolean | string;
  onSave?: () => void;
}

export const StepButtons: React.FunctionComponent<Props> = ({
  setIndex,
  showBack = true,
  onSave,
  disabled = false,
}) => {
  const isSave = !!onSave;

  const onClick = useCallback(() => {
    if (!!onSave) {
      onSave();
    } else {
      setIndex((prev) => prev + 1);
    }
  }, [onSave, setIndex]);

  let prevButton = null;
  if (showBack) {
    prevButton = (
      <Button
        disabled={disabled}
        ghost
        onClick={() => {
          setIndex((prev) => prev - 1);
        }}
      >
        <T>{typeof showBack === "string" ? showBack : "Back"}</T>
      </Button>
    );
  }

  return (
    <div className={cx("flex-rows", "space-between", styles["pep-buttons"])}>
      <div>{prevButton}</div>
      <div>
        {isSave ? (
          <Button onClick={onClick} disabled={disabled}>
            <T>Save & close</T>
          </Button>
        ) : (
          <Button type="submit" disabled={disabled}>
            <T>Next</T>
          </Button>
        )}
      </div>
    </div>
  );
};
