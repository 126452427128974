import React, { useMemo, useRef, useState } from "react";
import cx from "classnames";
import { MAX_LENGTH, MIN_LENGTH, OwnershipType } from "../OwnerOverlay";
import { TextInput } from "../../../../components/form/TextInput";
import { MaxLengthValidator } from "../../../../components/form/validators/MaxLengthValidator";
import { MinLengthValidator } from "../../../../components/form/validators/MinLengthValidator";
import { RequiredValidator } from "../../../../components/form/validators/RequiredValidator";
import { Associate, AssociateOwner } from "../../../../data/dataMerchant";
import { Form } from "../../../../components/form/Form";
import { StepButtons } from "../../../../components/steps/StepButtons";
import { T } from "../../../../components/translation/T";
import { RadioGroup } from "../../../../components/form/RadioGroup";
import { NumberInput } from "../../../../components/form/NumberInput";
import { Or } from "../../../../components/or/Or";
import { ValidationErrors } from "./ValidationErrors";
import styles from "../OwnerOverlay.module.scss";
import { MinValidator } from "../../../../components/form/validators/MinValidator";
import { MaxValidator } from "../../../../components/form/validators/MaxValidator";
import { Alternative } from "../../../../components/interactions/InputTypes";

interface Props {
  owner: Associate;
  setOwner: React.Dispatch<React.SetStateAction<Associate>>;
  // values: IdValues;
  // onChange: (value: string, name: string) => void;
  // onOwnershipChange: (value: boolean, name: string) => void;
  // onCapitalStakeChange: (value: number | undefined, name: string) => void;
  // activeOwnershipType?: OwnershipType;
  // setActiveOwnershipType: (type?: OwnershipType) => void;
  setIndex: React.Dispatch<React.SetStateAction<number>>;
  hasMajorityOwners: boolean;
}

function getAlternatives() {
  return [
    {
      value: false,
      text: <T>Ownership shares are directly owned</T>,
    },
    {
      value: true,
      text: (
        <T>
          Ownership shares are indirectly owned e.g. through another legal
          entity
        </T>
      ),
    },
  ];
}

export const NameAndOwnership: React.FunctionComponent<Props> = ({
  // values,
  // onChange,
  // onOwnershipChange,
  // onCapitalStakeChange,
  // activeOwnershipType,
  // setActiveOwnershipType,
  owner,
  setOwner,
  setIndex,
  hasMajorityOwners,
}) => {
  const ref = useRef<HTMLFormElement | null>(null);
  const alternatives: Alternative<boolean>[] = useMemo(
    () => getAlternatives(),
    []
  );
  const [activeOwnershipType, setActiveOwnershipType] = useState<
    OwnershipType | undefined
  >();

  return (
    <div className="m-top-20">
      <Form
        ref={ref}
        onSubmit={(_, form) => {
          if (form.isInvalid) {
            return;
          }

          setIndex((prev) => prev + 1);
        }}
      >
        <div>
          <TextInput
            label="First name"
            onChange={(value) => {
              setOwner((prev) => ({
                ...prev,
                contact: {
                  ...prev.contact,
                  firstName: value,
                },
              }));
            }}
            value={owner.contact.firstName}
            name="firstName"
            validators={[
              new RequiredValidator("First name is required"),
              new MinLengthValidator(
                MIN_LENGTH,
                "At least two characters are required"
              ),
              new MaxLengthValidator(
                MAX_LENGTH,
                "Not more than 30 characters are allowed"
              ),
            ]}
          />
        </div>
        <div className="m-top-30">
          <TextInput
            label="Last name"
            onChange={(value) => {
              setOwner((prev) => ({
                ...prev,
                contact: {
                  ...prev.contact,
                  lastName: value,
                },
              }));
            }}
            value={owner.contact.lastName}
            name="lastName"
            validators={[
              new RequiredValidator("Last name is required"),
              new MinLengthValidator(
                MIN_LENGTH,
                "At least two characters are required"
              ),
              new MaxLengthValidator(
                MAX_LENGTH,
                "Not more than 30 characters are allowed"
              ),
            ]}
          />
        </div>

        {hasMajorityOwners ? (
          <>
            <div className="m-top-30">
              <TextInput
                label="Position"
                onChange={(value) => {
                  setOwner((prev) => ({
                    ...prev,
                    contact: {
                      ...prev.contact,
                      position: value,
                    },
                  }));
                }}
                value={owner.contact.position}
                name="position"
                validators={[
                  new RequiredValidator("Position is required"),
                  new MinLengthValidator(
                    MIN_LENGTH,
                    "At least two characters are required"
                  ),
                  new MaxLengthValidator(
                    MAX_LENGTH,
                    "Not more than 30 characters are allowed"
                  ),
                ]}
              />
            </div>
          </>
        ) : (
          <>
            <div className="m-top-30">
              <RadioGroup
                name="hasIndirectOwnership"
                label="Ownership type?"
                alternatives={alternatives}
                validators={[new RequiredValidator("Please select an option")]}
                value={owner.owner?.hasIndirectOwnership}
                onChange={(value) => {
                  setOwner((prev) => ({
                    ...prev,
                    owner: {
                      ...prev.owner,
                      hasIndirectOwnership: (value as any) === "true",
                    },
                  }));
                }}
              />
            </div>
            <div className="m-top-30">
              <Or className={cx(styles.divider, styles.shares, "white-bg")}>
                <div
                  className={cx([styles.ownershipShares], {
                    [styles.passive]:
                      activeOwnershipType === OwnershipType.VOTING_RIGHTS,
                  })}
                >
                  <NumberInput
                    onFocus={() => {
                      setActiveOwnershipType(OwnershipType.OWNERSHIP);
                    }}
                    onBlur={() => {
                      if ((owner.owner?.capitalStake ?? 0) > 0) {
                        setActiveOwnershipType(OwnershipType.OWNERSHIP);
                        return;
                      }
                      if ((owner.owner?.votingRightsStake ?? 0) > 0) {
                        setActiveOwnershipType(OwnershipType.VOTING_RIGHTS);
                        return;
                      }
                      setActiveOwnershipType(undefined);
                    }}
                    name="capitalStake"
                    onChange={(value) => {
                      setOwner((prev) => ({
                        ...prev,
                        owner: {
                          ...prev.owner,
                          capitalStake: value || 0,
                        } as AssociateOwner,
                      }));
                    }}
                    label="Ownership shares"
                    value={owner.owner?.capitalStake}
                    placeholder="25%"
                    min={0}
                    max={100}
                    attributes={{
                      decimalScale: 0,
                    }}
                    validators={[
                      new MinValidator(0, "Value can not be negative"),
                      new MaxValidator(100, "Value can not exceed 100%"),
                    ]}
                    suffix="%"
                  />
                </div>
                <div
                  className={cx([styles.ownershipShares], {
                    [styles.passive]:
                      activeOwnershipType === OwnershipType.OWNERSHIP,
                  })}
                >
                  <NumberInput
                    onFocus={() => {
                      setActiveOwnershipType(OwnershipType.VOTING_RIGHTS);
                    }}
                    onBlur={() => {
                      if ((owner.owner?.capitalStake ?? 0) > 0) {
                        setActiveOwnershipType(OwnershipType.OWNERSHIP);
                        return;
                      }
                      if ((owner.owner?.votingRightsStake ?? 0) > 0) {
                        setActiveOwnershipType(OwnershipType.VOTING_RIGHTS);
                        return;
                      }
                      setActiveOwnershipType(undefined);
                    }}
                    name="votingRightsStake"
                    onChange={(value) => {
                      setOwner((prev) => ({
                        ...prev,
                        owner: {
                          ...prev.owner,
                          votingRightsStake: value || 0,
                        } as AssociateOwner,
                      }));
                    }}
                    label="Voting rights"
                    value={owner.owner?.votingRightsStake}
                    placeholder="25%"
                    min={0}
                    max={100}
                    validators={[
                      new MinValidator(0, "Value can not be negative"),
                      new MaxValidator(100, "Value can not exceed 100%"),
                    ]}
                    attributes={{
                      decimalScale: 0,
                    }}
                    suffix="%"
                  />
                </div>
              </Or>
            </div>

            <ValidationErrors formRef={ref} owner={owner} />
          </>
        )}

        <div className="m-top-30">
          <StepButtons setIndex={setIndex} />
        </div>
      </Form>
    </div>
  );
};
