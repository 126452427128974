import React, { useState, useEffect, useCallback, useRef } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { PersistentOverlay } from "../../../components/overlay/PersistantOverlay";
import { Steps } from "../../../components/steps/Steps";
import { useLinkId } from "../../../hooks/useLinkId";
import { CitizenshipAndSSN, getMatches } from "./steps/CitizenshipAndSSN";
import { Done } from "./steps/Done";
import { NameAndOwnership } from "./steps/NameAndOwnership";
import {
  Associate,
  AssociateOwner,
  AssociateRole,
} from "../../../data/dataMerchant";
import { AssociateId, Country } from "../../../data/models/ContractTypes";
import { HEADERS } from "./OwnerOverlay";
import { useRecoilValue } from "recoil";
import { contractState } from "../../../state/contractState";
import { dataAssociates } from "../../../data/dataAssociates";
import { routeState } from "../../../state/routeState";

interface Props {
  id: string | null;
  setId: React.Dispatch<React.SetStateAction<string | null>>;
  associates: Associate[];
  hasMajorityOwners: boolean;
}

function getDefaultOwner(id: AssociateId) {
  return {
    associateId: id,
    roles: [AssociateRole.BENEFICIAL_OWNER],
    contact: {
      firstName: "",
      lastName: "",
      position: "",
      email: "",
      phoneNumber: "",
    },
    id: {
      nationalPersonId: "",
      countryOfBirth: "" as Country,
      citizenships: [],
      countryOfResidence: "" as Country,
    },
  };
}

export const NewOwner: React.FunctionComponent<Props> = ({
  id,
  setId,
  associates,
  hasMajorityOwners,
}) => {
  const contract = useRecoilValue(contractState);
  const linkId = useLinkId();
  const queryClient = useQueryClient();
  const { access } = useRecoilValue(routeState);
  const [isOpen, setIsOpen] = useState(!!id);
  const [index, setIndex] = useState<number>(0);
  const [owner, setOwner] = useState<Associate>(
    getDefaultOwner((id || "") as AssociateId)
  );
  const formRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    if (id) {
      setOwner(getDefaultOwner((id || "") as AssociateId));
      setIsOpen(true);
    }
  }, [id]);

  const {
    mutate: onSave,
    isLoading: isSaving,
    isError: isSaveError,
    reset,
  } = useMutation(
    () => {
      if (!owner) {
        return Promise.reject();
      }

      const roles = owner.roles.includes(AssociateRole.BENEFICIAL_OWNER)
        ? owner.roles
        : [...owner.roles, AssociateRole.BENEFICIAL_OWNER];

      const updatedOwner: Associate = {
        ...owner,
        roles,
        owner: {
          capitalStake: 0, // default value
          votingRightsStake: 0, // default value
          hasIndirectOwnership: false, // default value
          ...owner.owner,
          designatedOwner: hasMajorityOwners,
        } as AssociateOwner,
      };

      const matchingSignees = getMatches(
        updatedOwner.id.nationalPersonId || "",
        associates
      );

      if (matchingSignees.length !== 1) {
        return dataAssociates(access).saveAssociate(linkId, updatedOwner, true);
      } else {
        const signee = matchingSignees[0];
        return dataAssociates(access).saveAssociate(linkId, {
          ...updatedOwner,
          associateId: signee.associateId,
          roles: signee.roles.includes(AssociateRole.BENEFICIAL_OWNER)
            ? signee.roles
            : [...signee.roles, AssociateRole.BENEFICIAL_OWNER],
        });
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["associates", linkId]);
        setIsOpen(false);
        setTimeout(() => {
          setIndex(0);
          setId(null);
        }, 350);
      },
    }
  );

  const onClose = useCallback(() => {
    setIsOpen(false);
    setId(null);
    setTimeout(() => {
      setIndex(0);
      reset();
    }, 350);
  }, [reset, setId]);

  const onRetry = useCallback(() => {
    reset();
    setTimeout(onSave, 300);
  }, [reset, onSave]);

  if (!owner) {
    return null;
  }

  return (
    <div>
      <PersistentOverlay
        open={isOpen}
        onClose={onClose}
        className="full-height"
      >
        <Steps
          index={index}
          header={HEADERS}
          length={HEADERS.length}
          insideOverlay
        >
          <CitizenshipAndSSN
            owner={owner}
            setOwner={setOwner}
            formRef={formRef}
            isViewer={contract.contractViewer.associateId === owner.associateId}
            setIndex={setIndex}
            associates={associates}
          />

          <NameAndOwnership
            owner={owner}
            setOwner={setOwner}
            setIndex={setIndex}
            hasMajorityOwners={hasMajorityOwners}
          />

          <Done
            setIndex={setIndex}
            index={index}
            onSave={onSave}
            onRetry={onRetry}
            isLoading={isSaving}
            isError={isSaveError}
          />
        </Steps>
      </PersistentOverlay>
    </div>
  );
};
