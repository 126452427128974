import React, { useState, useEffect, useCallback, useRef } from "react";
import cx from "classnames";
import styles from "./OpacitySwitch.module.scss";
import { T } from "../translation/T";

interface Props {
  text: string;
}

const Opacity: React.FunctionComponent<Props> = ({ text }) => {
  const [elem, setElem] = useState<string>(text);
  const [fadeOut, setFadeOut] = useState<boolean>(false);
  const switchElem = useRef<HTMLDivElement>(null);

  const onTransitionEnd = useCallback(
    (ev: TransitionEvent) => {
      if (!(ev.target as HTMLElement).classList.contains("opacity-switch")) {
        return;
      }

      if (ev.propertyName !== "opacity") {
        return;
      }

      if (!fadeOut) {
        return;
      }

      setElem(text);
      setTimeout(
        () => window.requestAnimationFrame(() => setFadeOut(false)),
        50
      );
    },
    [text, fadeOut]
  );

  useEffect(() => {
    if (!switchElem.current) {
      return;
    }

    const ref = switchElem.current;
    ref.addEventListener("transitionend", onTransitionEnd);

    return () => {
      ref.removeEventListener("transitionend", onTransitionEnd);
    };
  }, [onTransitionEnd]);

  useEffect(() => {
    // if (prev.current === text) {
    //   return;
    // }

    setFadeOut(true);
  }, [text]);

  return (
    <div
      ref={switchElem}
      className={cx("opacity-switch", styles.switch, {
        [styles.fadeOut]: fadeOut,
      })}
    >
      <T>{elem}</T>
    </div>
  );
};

export const OpacitySwitch = React.memo(Opacity);
