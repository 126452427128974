import { atom, selector } from "recoil";
import { Language, UTCDate } from "../../data/models/ContractTypes";

export type TranslationId = number & { isTranslationId: true };

export enum SiteTranslation {
  merchant = "merchant",
  sales = "sales",
}

export interface TranslationText {
  id: TranslationId;
  created: UTCDate;
  updated?: UTCDate;
  updatedBy?: string;
  language: Language;
  namespace: string;
  key: string;
  value?: string;
  site: SiteTranslation;
}

interface TranslationManagementState {
  active: boolean;
  translation?: SelectedTranslation;
  registeredTranslations: RegisteredTranslation[];
  translations: TranslationText[];
}

interface SelectedTranslation {
  selected: string;
  alternatives?: string[];
}

export interface RegisteredTranslation {
  id: string;
  ref: React.RefObject<HTMLSpanElement>;
  highlightFunction: () => void;
}

export const translationManagementState = atom<TranslationManagementState>({
  key: "translation-state",
  default: {
    active: false,
    translation: undefined,
    registeredTranslations: [],
    translations: [],
  },
  // required to cleanup the refs
  dangerouslyAllowMutability: true,
});

export const translationStateSelector = selector({
  key: "translation-state-selector",
  get: ({ get }) => {
    const { translation } = get(translationManagementState);
    return translation;
  },
  set: ({ set, get }, newValue) =>
    set(translationManagementState, {
      ...get(translationManagementState),
      translation: isSelectedTranslation(newValue) ? newValue : undefined,
    }),
});

export const translationStateActiveSelector = selector({
  key: "translation-state-active-selector",
  get: ({ get }) => {
    const { active } = get(translationManagementState);
    return active;
  },
  set: ({ set, get }, newValue) => {
    const state = get(translationManagementState);
    set(translationManagementState, {
      ...state,
      active: typeof newValue === "boolean" ? newValue : state.active,
    });
  },
});

export const RegisteredTranslationsSelector = selector({
  key: "translation-state-available-translations-selector",
  get: ({ get }) => {
    const { registeredTranslations: availableTranslations } = get(
      translationManagementState
    );
    return availableTranslations;
  },
  set: ({ set, get }, newValue) => {
    const state = get(translationManagementState);

    if (!Array.isArray(newValue)) {
      return;
    }

    const newTranslations = newValue.filter(
      (translation) =>
        !state.registeredTranslations.find((registeredTranslation) => {
          return registeredTranslation.id === translation.id;
        })
    );

    set(translationManagementState, {
      ...state,
      registeredTranslations: [
        ...state.registeredTranslations,
        ...newTranslations,
      ],
    });
  },
});

export const ResetRegisteredTranslationsSelector = selector({
  key: "translation-state-reset-registered-translations-selector",
  get: () => {
    return;
  },
  set: ({ set, get }) => {
    const state = get(translationManagementState);

    set(translationManagementState, {
      ...state,
      registeredTranslations: [],
    });
  },
});

export const TranslationsSelector = selector({
  key: "translation-state-translations-selector",
  get: ({ get }) => {
    const { translations } = get(translationManagementState);
    return translations;
  },
  set: ({ set, get }, newValue) => {
    const state = get(translationManagementState);

    if (!Array.isArray(newValue)) {
      return;
    }

    set(translationManagementState, {
      ...state,
      translations: newValue,
    });
  },
});

const isSelectedTranslation = (
  translation: any
): translation is SelectedTranslation => {
  return translation && "selected" in translation;
};
