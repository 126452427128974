import React, { ReactNode } from "react";
import cx from "classnames";
import styles from "./Chip.module.scss";

interface Props {
  onRemove?: (data?: any) => void;
  data?: any;
  children: ReactNode;
  disabled?: boolean;
  className?: string;
}

export const Chip: React.FunctionComponent<Props> = ({
  children,
  onRemove,
  data,
  disabled,
  className,
}) => {
  return (
    <div
      className={cx(styles.chip, className, {
        [styles.disabled]: disabled,
      })}
    >
      <div className={cx(styles.text, "text-small")}>{children}</div>
      {onRemove && (
        <button
          className={styles.button}
          onClick={(ev) => {
            ev.preventDefault();
            onRemove(data);
          }}
          tabIndex={disabled ? -1 : undefined}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            className={styles.close}
          >
            <line x1="0" x2="20" y1="0" y2="20" />
            <line x1="0" x2="20" y1="20" y2="0" />
          </svg>
        </button>
      )}
    </div>
  );
};
