import React, { useState, useCallback, useRef } from "react";
import styles from "./FileUpload.module.scss";
import cx from "classnames";
import { id } from "../utils";
import { Status } from "../../data/types";
import { Button } from "../interactions/Buttons/Button";
import { Trans, useTranslation } from "react-i18next";
import { Close } from "../icons/Close";
import { ErrorBox } from "../boxes/ErrorBox";
import { Progress, ProgressInterface } from "./Progress";
import { API } from "../../network/API";
import { Attach } from "../icons/Attach";
import { EditRowRemove } from "../editRow/EditRowRemove";
import { T } from "../translation/T";
import { External } from "../icons/External";
import { dataDocuments } from "../../data/dataDocuments";
import { useAccess } from "../../hooks/useAccess";

export interface FileData {
  size?: number;
  name: string;
  type: string;
}

export function getUploadBaseUrl() {
  return (
    process.env.REACT_APP_TARGET_BACKEND ||
    process.env.REACT_APP_LOCAL_UPLOAD_URL
  );
}

export type AnonymousFile = string & { isAnonymousFile: true };

interface Props {
  onUpload?: (file: FileData) => void;
  onError?: (file: FileData) => void;
  onRemove?: (file: FileData) => void;
  accept?: string;
  disabled?: boolean;
  buttonText?: string;
  color?: string;
  endpoint: string;
  buttonClasses?: string;
  initialFile?: FileData | AnonymousFile;
  appends?: {
    [key: string]: string;
  };
}

export function isAnonymous(
  file?: AnonymousFile | FileData
): file is AnonymousFile {
  return typeof file === "string";
}

const PDF_POSTFIX = ".pdf";
const DEFAULT_ACCEPTED_TYPES = "image/*,application/pdf";

const FileNameAnonymous: React.FunctionComponent<{
  file: AnonymousFile;
}> = ({ file }) => {
  return <>{file}</>;
};

const FileName: React.FunctionComponent<{
  file?: FileData;
  endpoint: string;
}> = ({ file, endpoint }) => {
  return (
    <a
      href={`${endpoint}?c=${new Date().getTime()}${
        file?.name.endsWith(PDF_POSTFIX) ? "#view=Fit" : ""
      }`}
      target="_blank"
      rel="noreferrer"
    >
      {file?.name}
      <External className={styles.external} />
    </a>
  );
};

function getName(file?: FileData | AnonymousFile) {
  if (!file) {
    return "";
  }

  if (isAnonymous(file)) {
    return file;
  }

  return file.name;
}

export const FileUpload: React.FunctionComponent<Props> = ({
  onError,
  onUpload,
  onRemove,
  accept = DEFAULT_ACCEPTED_TYPES,
  disabled,
  buttonText = "Upload",
  color = "#fff",
  endpoint,
  buttonClasses,
  initialFile,
  appends = {},
}) => {
  const identifier = useRef<string>(id());
  const access = useAccess();
  const fileElemRef = useRef<HTMLInputElement>(null);
  const [status, setStatus] = useState<Status>(
    initialFile ? Status.SUCCESS : Status.DEFAULT
  );
  const { t } = useTranslation();
  const [file, setFile] = useState<FileData | AnonymousFile | undefined>(
    initialFile
  );
  const [progress, setProgress] = useState<ProgressInterface>({
    name: "",
    progress: 0,
  });

  const reset = useCallback(() => {
    // If the upload failed we should reset to default state
    // If we failed to remove the file we should should still
    // show that file
    if (file) {
      setStatus(Status.SUCCESS);
    } else {
      setStatus(Status.DEFAULT);
    }
  }, [file]);

  const upload = useCallback(
    (targetFile: File) => {
      if (!targetFile) {
        return;
      }

      setStatus(Status.PENDING);
      const req = new XMLHttpRequest();

      req.upload.addEventListener("progress", (event) => {
        if (event.lengthComputable) {
          setProgress({
            progress: (event.loaded / event.total) * 100,
            name: targetFile.name,
          });
        }
      });

      req.onload = function () {
        if (req.status === 200) {
          setProgress({
            progress: 100,
            name: targetFile.name,
          });

          setStatus(Status.SUCCESS);
          const fileData = {
            name: targetFile.name,
            size: targetFile.size,
            type: targetFile.type,
          };

          setFile(fileData);
          onUpload && onUpload(fileData);
        } else {
          setStatus(Status.ERROR);
          onError &&
            onError({
              name: targetFile.name,
              size: targetFile.size,
              type: targetFile.type,
            });
        }
      };

      const formData = new FormData();
      formData.append("document", targetFile);
      formData.append("filename", targetFile.name);
      Object.entries(appends).forEach((entry) => {
        formData.append(entry[0], entry[1]);
      });
      req.open("POST", API.getUrl(endpoint));
      req.withCredentials = true;
      req.send(formData);
    },
    [endpoint, onError, onUpload, appends]
  );

  const remove = useCallback(() => {
    const copy = isAnonymous(file)
      ? {
          name: file,
        }
      : { ...file };
    onRemove && onRemove(copy as File);
    setFile(undefined);
    setProgress({
      name: "",
      progress: 0,
    });
    setStatus(Status.DEFAULT);
  }, [onRemove, file]);

  const internalRemove = useCallback(
    () => dataDocuments(access).deleteDocument(endpoint).then(remove),
    [remove, access, endpoint]
  );

  const onAttachFile = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>) => {
      setStatus(Status.PENDING);
      const targetFile = ev.target.files?.[0];

      if (!targetFile) {
        return;
      }

      ev.target.value = "";
      setProgress({
        progress: 0,
        name: targetFile.name,
      });
      setFile(undefined);
      upload(targetFile);
    },
    [upload]
  );

  return (
    <div className={cx(styles.fileUpload, styles[status])}>
      <div className={styles.successOverlay} style={{ backgroundColor: color }}>
        <div className={styles.iconWrapper}>
          <Attach />
        </div>
        <div className={cx(styles.fileName, "truncate", "text-left")}>
          {isAnonymous(file) ? (
            <FileNameAnonymous file={file} />
          ) : (
            <FileName file={file} endpoint={endpoint} />
          )}
        </div>
        <EditRowRemove onRemove={internalRemove} disabled={disabled}>
          <T>Remove</T>: <b>{getName(file)}</b>
        </EditRowRemove>
      </div>
      <div className={styles.pendingOverlay} style={{ backgroundColor: color }}>
        <Progress {...progress} />
      </div>

      <div className={styles.errorOverlay} style={{ backgroundColor: color }}>
        <ErrorBox relative>
          <Trans t={t}>
            <b>Ooh no!</b> Something went wrong. Try again?
          </Trans>
        </ErrorBox>
        <Button type="button" ghost action className="small" onClick={reset}>
          <Close />
        </Button>
      </div>

      <input
        type="file"
        id={identifier.current}
        name={identifier.current}
        onChange={onAttachFile}
        ref={fileElemRef}
        multiple={false}
        accept={accept}
        disabled={disabled}
      />
      <label htmlFor={identifier.current}>
        <span
          className={cx("button", buttonClasses, {
            "is-disabled": disabled || status !== Status.DEFAULT,
          })}
        >
          {t(buttonText)}
        </span>
      </label>
    </div>
  );
};
