import { Language, UTCDate } from "../../data/models/ContractTypes";
import { API } from "../../network/API";
// import { UNAUTHORIZED_PAGE_URL } from "../../pages/error/UnauthorizedPage";

export type TranslationId = number & { isTranslationId: true };

export enum SiteTranslation {
  merchant = "merchant",
  sales = "sales",
}

export interface TranslationText {
  id: TranslationId;
  created: UTCDate;
  updated?: UTCDate;
  updatedBy?: string;
  language: Language;
  namespace: string;
  key: string;
  value?: string;
  site: SiteTranslation;
}

export interface Count {
  count: number;
}

const BASE_URL = "/api/admin/internal/translations";

export const dataTranslation = {
  loadTranslations: (language?: Language, ignoreCache = false) => {
    const lang = language || "";
    return API.get<TranslationText[]>(`${BASE_URL}?language=${lang}`);
  },

  suggestText: (id: TranslationId, text: string) => {
    return API.post<TranslationText[]>(`${BASE_URL}/${id}`, {
      text: text,
    });
  },

  updateTranslation: (id: TranslationId, text: string) => {
    return API.post(`${BASE_URL}/${id}`, {
      text,
    });
  },

  deleteText: (id: TranslationId) => {
    return API.delete<void>(`${BASE_URL}/${id}`);
  },

  countMissingTranslations: (language?: Language) => {
    const lang = language || "";
    return API.get<Count>(`${BASE_URL}/missing/count?language=${lang}`);
  },

  addTranslationLabel: ({
    namespace,
    key,
    page,
  }: {
    namespace: string;
    key: string;
    page?: string;
  }) =>
    API.post(`/translation/${namespace}.json`, {
      key,
      page,
    }),
};

// function actAndDelagate<T>(err: ServerError<T>) {
//   if (err.status === 401) {
//     window.location.href = UNAUTHORIZED_PAGE_URL;
//   }
//   return Promise.reject(err);
// }

// const data = {
//   get: <T>(url: string, refreshCache?: boolean, options?: RequestInit) => {
//     return API.get<T>(url, refreshCache, options).catch(actAndDelagate);
//   },

//   post: <T>(url: string, body?: any) => {
//     return API.post<T>(url, body).catch(actAndDelagate);
//   },

//   delete: <T>(url: string, body?: any) => {
//     return API.delete<T>(url, body).catch(actAndDelagate);
//   },
// };
