export const PHONE_TO_COUNTRY_CODE: Record<string, string> = {
  "+48": "PL",
  "+385": "HR",
  "+46": "SE",
  "+45": "DK",
  "+358": "FI",
  "+47": "NO",
  "+32": "BE",
  "+359": "BG",
  "+49": "DE",
  "+372": "EE",
  "+353": "IE",
  "+30": "EL",
  "+34": "ES",
  "+33": "FR",
  "+39": "IT",
  "+357": "CY",
  "+371": "LV",
  "+370": "LT",
  "+352": "LU",
  "+36": "HU",
  "+356": "MT",
  "+31": "NL",
  "+43": "AT",
  "+351": "PT",
  "+40": "RO",
  "+386": "SI",
  "+421": "SK",
};

export const getCountryCodeFromPhoneNumber = (phoneNumber?: string) => {
  if (!phoneNumber) {
    return;
  }

  if (phoneNumber.length > 4) {
    return getCountryFromPhoneNumber(phoneNumber);
  }
};

const getCountryFromPhoneNumber = (phoneNumber?: string) => {
  if (!phoneNumber) {
    return;
  }

  for (let i = 5; i >= 1; i--) {
    const code = phoneNumber.substring(0, i);
    const country: string | undefined = PHONE_TO_COUNTRY_CODE[code];
    if (country) {
      return country;
    }
  }
};
