import React, { ReactNode } from "react";
import { Address as AddressInterface } from "../../data/models/ContractTypes";
import { useTranslation } from "react-i18next";
import { defaultAddress } from "../../data/dataMerchant";
import { DataList } from "../datalist/DataList";
import { DataListItem } from "../datalist/DataListItem";
import { getCountryDisplayName } from "../utils";
import { T } from "../translation/T";

interface Props {
  address?: AddressInterface;
  header?: string | ReactNode;
  className?: string;
}

export const Address: React.FunctionComponent<Props> = ({
  address = defaultAddress,
  header,
  className,
}) => {
  const { i18n } = useTranslation();

  return (
    <div className={className}>
      <b>{header || <T>Address</T>}</b>
      <DataList align="left">
        <DataListItem label="Street" value={address.street} />
        <DataListItem label="Postal code" value={address.postalCode} />
        <DataListItem label="City" value={address.city} />
        <DataListItem
          label="Country"
          value={
            address.countryCode
              ? getCountryDisplayName(address.countryCode, i18n.language)
              : "-"
          }
        />
      </DataList>
    </div>
  );
};
