import { Dispatch, FunctionComponent, SetStateAction, useRef } from "react";
import styles from "./AssociateCard.module.scss";
import cx from "classnames";
import { motion } from "framer-motion";
// components
import { T } from "../../../../components/translation/T";
import { TextInput } from "../../../../components/form/TextInput";
import { RequiredValidator } from "../../../../components/form/validators/RequiredValidator";
import { Button } from "../../../../components/interactions/Buttons/Button";
import { Form } from "../../../../components/form/Form";
import { EmailValidator } from "../../../../components/form/validators/EmailValidator";
import { MinLengthValidator } from "../../../../components/form/validators/MinLengthValidator";
import { Expand } from "../../../../components/icons/Expand";
import { AiOutlineUserAdd } from "react-icons/ai";
// data & types
import { Associate } from "../../../../data/dataMerchant";
import { getFullname } from "../utils";
import { AssociateId } from "../../../../data/models/ContractTypes";
import { EmailBackendValidator } from "../../../../components/form/validators/EmailBackendValidator";
import { MaxLengthValidator } from "../../../../components/form/validators/MaxLengthValidator";
import { useLinkId } from "../../../../hooks/useLinkId";
import {
  RegexValidator,
  PHONE_REGEX,
} from "../../../../components/form/validators/RegexValidator";
import {
  MIN_MOBILE_PHONE_CHARS,
  MAX_MOBILE_PHONE_CHARS,
} from "../../Intro/components/CompanyInfo";

interface Props {
  isSaved: boolean;
  setIsSaved: Dispatch<SetStateAction<boolean>>;
  customAccountHolder: Associate;
  setCustomAccountHolder: Dispatch<SetStateAction<Associate>>;
  isSelected: boolean;
  isEditing: boolean;
  setIsEditing: Dispatch<SetStateAction<boolean>>;
  setSelectedAccountHolder: Dispatch<SetStateAction<AssociateId | undefined>>;
}

export const AddAssociateCard: FunctionComponent<Props> = ({
  isSelected,
  isSaved,
  setIsSaved,
  isEditing,
  setIsEditing,
  customAccountHolder,
  setCustomAccountHolder,
  setSelectedAccountHolder,
}) => {
  const formRef = useRef<HTMLFormElement>(null);
  const linkId = useLinkId();

  const iconContainerClass = cx(styles.iconContainer, {
    [styles.iconContainerSelected]: isSelected,
  });
  const btnClass = cx(styles.radioBtn, {
    [styles.btnSelected]: isSelected,
  });
  const associateClass = cx(styles.associate, {
    [styles.associateSelected]: isSelected,
  });
  const nameClass = cx(styles.nameText, {
    [styles.nameTextSelected]: isSelected,
  });
  const roleClass = cx(styles.roleText, {
    [styles.roleTextSelected]: isSelected,
  });

  return (
    <div className={styles.addAssociate}>
      <div
        className={associateClass}
        onClick={() => {
          setSelectedAccountHolder(customAccountHolder.associateId);

          if (isSaved && isEditing) {
            setIsEditing(false);
            return;
          }

          setIsEditing(true);
        }}
      >
        <div className={styles.radioBtnCol}>
          {isSaved ? (
            <div className={btnClass} />
          ) : (
            <div className={iconContainerClass}>
              <AiOutlineUserAdd />
            </div>
          )}
        </div>
        <div className={styles.infoCol}>
          <div className={nameClass}>
            {customAccountHolder.contact.firstName ? (
              getFullname(customAccountHolder)
            ) : (
              <T>Add manually</T>
            )}
          </div>
          <span className={roleClass}>
            {isSelected ? (
              <span className={styles.role}>
                <T>Account holder</T>
              </span>
            ) : (
              <T>
                Select this option if the account holder is not present above
              </T>
            )}
          </span>
        </div>

        <motion.div
          className={styles.expandContainer}
          animate={{
            rotate: isEditing ? 180 : 0,
          }}
        >
          <Expand />
        </motion.div>
      </div>
      <motion.div
        animate={{
          height: isEditing ? "auto" : 0,
          opacity: isEditing ? 1 : 0,
        }}
        transition={{ type: "tween", ease: "easeInOut", duration: 0.2 }}
        initial={false}
        onAnimationComplete={() => {
          if (formRef.current && isEditing) {
            formRef.current.scrollIntoView({
              behavior: "smooth",
              block: "center",
            });
          }
        }}
      >
        <Form
          ref={formRef}
          onSubmit={(_, form) => {
            if (!form.isValid) {
              return;
            }

            setIsSaved(true);
            setIsEditing(false);
            setCustomAccountHolder(customAccountHolder);
            setSelectedAccountHolder(customAccountHolder.associateId);
          }}
          className={styles.addAssociateForm}
        >
          <div className={styles.inputGrid}>
            <TextInput
              label={"First name"}
              value={customAccountHolder.contact.firstName}
              onChange={(value) => {
                setIsSaved(false);
                setCustomAccountHolder((prev) => ({
                  ...prev,
                  contact: { ...prev.contact, firstName: value },
                }));
              }}
              validators={[new RequiredValidator("First name is required")]}
            />
            <TextInput
              label={"Last name"}
              value={customAccountHolder.contact.lastName}
              onChange={(value) => {
                setIsSaved(false);
                setCustomAccountHolder((prev) => ({
                  ...prev,
                  contact: { ...prev.contact, lastName: value },
                }));
              }}
              validators={[new RequiredValidator("Last name is required")]}
            />
            <TextInput
              label={"Email"}
              value={customAccountHolder.contact.email}
              onChange={(value) => {
                setIsSaved(false);
                setCustomAccountHolder((prev) => ({
                  ...prev,
                  contact: { ...prev.contact, email: value },
                }));
              }}
              validators={[
                new RequiredValidator("Email is required"),
                new EmailValidator("Email is not valid"),
                new MaxLengthValidator(
                  50,
                  "Email must be less than 50 characters"
                ),
                new EmailBackendValidator(linkId, "Email is not valid"),
              ]}
            />
            <TextInput
              label={"Phone number"}
              value={customAccountHolder.contact.phoneNumber}
              onChange={(value) => {
                setIsSaved(false);
                setCustomAccountHolder((prev) => ({
                  ...prev,
                  contact: { ...prev.contact, phoneNumber: value },
                }));
              }}
              hint="Country code is required e.g +46..."
              validators={[
                new RequiredValidator("Phone number is required"),
                new RegexValidator(
                  PHONE_REGEX,
                  "Phone contains invalid characters"
                ),
                new MinLengthValidator(
                  MIN_MOBILE_PHONE_CHARS,
                  `Mobile phone must be at least ${MIN_MOBILE_PHONE_CHARS} characters`
                ),
                new MaxLengthValidator(
                  MAX_MOBILE_PHONE_CHARS,
                  `Mobile phone must be less than ${MAX_MOBILE_PHONE_CHARS} characters`
                ),
              ]}
            />
          </div>
          <div className={styles.saveBtnContainer}>
            <div className={styles.buttonWrapper}>
              <Button block type="submit">
                <T>Update</T>
              </Button>
            </div>
          </div>
        </Form>
      </motion.div>
    </div>
  );
};
