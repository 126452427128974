import React from "react";
import { Card } from "../../../../../components/cards/Card";
import { T } from "../../../../../components/translation/T";
import { Contract } from "../../../../../data/dataMerchant";
import { dataStores } from "../../../../../data/dataStores";
import { useLinkId } from "../../../../../hooks/useLinkId";
import { useSuspendedQuery } from "../../../../../hooks/useSuspendedQuery";
import { isEcom } from "../../../ContractLoader";
import { Store } from "./Store";
import { routeState } from "../../../../../state/routeState";
import { useRecoilValue } from "recoil";

interface Props {
  contract: Contract;
}

export const MultipleStores = ({ contract }: Props) => {
  if (isEcom(contract)) {
    return <EcomStore />;
  }

  return <LocationStore />;
};

const EcomStore = () => {
  return null;
};

const LocationStore = () => {
  const linkId = useLinkId();
  const { access } = useRecoilValue(routeState);
  const { data: stores } = useSuspendedQuery(
    dataStores(access).fetchStores(linkId)
  );

  return (
    <>
      <Card accent header={<T>Locations</T>} className="compact">
        {stores.map((store, idx) => (
          <React.Fragment key={store.storeId}>
            {idx === 0 ? null : <hr />}
            <div className="m-top-20">
              <div className="m-bottom-30">
                <Store store={store} />
              </div>
            </div>
          </React.Fragment>
        ))}
      </Card>
    </>
  );
};
