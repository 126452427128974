import React, {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useMemo,
} from "react";
import cx from "classnames";
import { T } from "../../../../components/translation/T";
import { InvoiceAddress, Props as InvoiceProps } from "./InvoiceAddress";
import { Card } from "../../../../components/cards/Card";
import { RadioGroup } from "../../../../components/form/RadioGroup";
import { Alternative } from "../../../../components/interactions/InputTypes";
import { InvoiceEmailStatus, InvoiceType } from "../Financials";
import { RequiredValidator } from "../../../../components/form/validators/RequiredValidator";
import { InvoiceEmail } from "./InvoiceEmail";
import { AnimateHeight } from "../../../../components/animate/AnimateHeight";
import { FormContainer } from "../../../../components/form/Form";
import styles from "./InvoiceAddress.module.scss";

interface Props extends InvoiceProps {
  isEmailInvoice?: InvoiceType;
  setIsEmailInvoice: Dispatch<SetStateAction<InvoiceType | undefined>>;
  formContainer: MutableRefObject<FormContainer | undefined>;
  setCodeWarning: Dispatch<SetStateAction<boolean>>;
}

function getAlternatives() {
  return [
    {
      value: InvoiceType.EMAIL,
      text: <T>by email</T>,
    },
    {
      value: InvoiceType.ADDRESS,
      text: <T>by post</T>,
    },
  ];
}

export const InvoiceToggle: React.FunctionComponent<Props> = ({
  isEmailInvoice,
  setIsEmailInvoice,
  ...props
}) => {
  const { primary, invoiceStatus, setInvoiceStatus } = props;

  const alternatives: Alternative<InvoiceType>[] = useMemo(
    () => getAlternatives(),
    []
  );

  return (
    <Card className={styles.card}>
      <div
        className={cx(styles.top, {
          [styles.hasValue]: isEmailInvoice !== undefined,
        })}
      >
        <RadioGroup
          name="invoiceType"
          label="How would you like to receive your invoices?"
          alternatives={alternatives}
          validators={[new RequiredValidator("Please select an option")]}
          value={isEmailInvoice}
          onChange={(value) => {
            if (value === InvoiceType.EMAIL && primary?.contact.email) {
              setInvoiceStatus(InvoiceEmailStatus.CONFIRMED_AS_PRIMARY);
            } else {
              if (invoiceStatus !== InvoiceEmailStatus.CONFIRMED_WITH_CODE) {
                setInvoiceStatus(InvoiceEmailStatus.REQUEST_CODE);
              }
            }

            setIsEmailInvoice(value);
          }}
        />
      </div>

      <AnimateHeight name={isEmailInvoice || "none"}>
        <div>
          {isEmailInvoice === InvoiceType.EMAIL ? (
            <InvoiceEmail {...props} />
          ) : null}
          {isEmailInvoice === InvoiceType.ADDRESS ? (
            <InvoiceAddress {...props} />
          ) : null}
        </div>
      </AnimateHeight>
    </Card>
  );
};
