import React from "react";
import { Dynamic } from "../../../components/animate/Dynamic";
import { TextInput } from "../../../components/form/TextInput";
import { T } from "../../../components/translation/T";
import { RequiredValidator } from "../../../components/form/validators/RequiredValidator";
import { Checkboxes } from "../../../components/interactions/Checkboxes/Checkboxes";
import { useTranslation } from "react-i18next";
import styles from "./Financials.module.scss";

const names = {
  amex: "Amex",
  resurs: "Resurs",
};

interface Props {
  setValue: (value: string) => void;
  value: string;
  enable: boolean;
  setEnable: (value: boolean) => void;
  type: "amex" | "resurs";
}

export const AmexAndResurs: React.FunctionComponent<Props> = ({
  enable,
  setEnable,
  type,
  value,
  setValue,
}) => {
  return null;
};

export const AmexAndResurss: React.FunctionComponent<Props> = ({
  enable,
  setEnable,
  type,
  value,
  setValue,
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <Checkboxes
        className="compact"
        label={
          <T
            id="Allow {{name}} bank credit card"
            options={{
              name: names[type],
            }}
          />
        }
        values={enable ? ["true"] : []}
        onChange={(values) => {
          if (values.length) {
            setEnable(true);
          } else {
            setEnable(false);
          }
        }}
        alternatives={[
          {
            value: "true",
            text: t("Yes"),
          },
        ]}
      />

      <Dynamic name={enable ? "one" : "two"}>
        {enable ? (
          <div className={styles.amex}>
            <TextInput
              label={
                <T
                  id={"Enter {{name}} integration number"}
                  options={{
                    name: names[type],
                  }}
                />
              }
              validators={[
                new RequiredValidator(
                  (
                    <T
                      id={"Integration number is required"}
                      options={{
                        name: names[type],
                      }}
                    />
                  )
                ),
              ]}
              value={value}
              onChange={(value) => setValue(value)}
            />
          </div>
        ) : null}
      </Dynamic>
    </div>
  );
};
