import { API } from "../network/API";
import { LinkId } from "./models/ContractTypes";
import { Access, getProxy } from "./proxy";

export interface Psp {
  psp?: string;
  bax?: string;
  tof?: string;
  amex?: string;
  resurs?: string;
}

const BASE_PATH = "/api/merchant";

enum WhitelistedEndpoint {
  FETCH_BUSINESS_RISK = "fetchBusinessRiskData",
}

export const BusinessRisk = {
  getBusinessRisk: (linkId: LinkId) =>
    API.get<Psp>(`${BASE_PATH}/${linkId}/psp`),

  [WhitelistedEndpoint.FETCH_BUSINESS_RISK]: (linkId: LinkId) => ({
    queryKey: BusinessRisk.getBusinessRiskKey(linkId),
    queryFn: () => BusinessRisk.getBusinessRisk(linkId),
  }),

  getBusinessRiskKey: (linkId: LinkId) => {
    return ["psp", linkId];
  },
};

let businessRisk: typeof BusinessRisk;

export function dataBusinessRisk(access: Access) {
  if (!businessRisk) {
    businessRisk = getProxy(
      BusinessRisk,
      access,
      Object.values(WhitelistedEndpoint)
    );
  }

  return businessRisk;
}
