import React, {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useMemo,
} from "react";
import cx from "classnames";
import {
  Address,
  Address as AddressInterface,
} from "../../../../data/models/ContractTypes";
import {
  getCountryDisplayName,
  getCountryOpts,
} from "../../../../components/utils";
import { RequiredValidator } from "../../../../components/form/validators/RequiredValidator";
import { useTranslation } from "react-i18next";
import { AddressSearch } from "../../../../components/address/AddressSearch";
import { Select } from "../../../../components/form/Select";
import { TextInput } from "../../../../components/form/TextInput";
import { RadioGroup } from "../../../../components/form/RadioGroup";
import { AnimateHeight } from "../../../../components/animate/AnimateHeight";
import { T } from "../../../../components/translation/T";
import { Associate } from "../../../../data/dataMerchant";
import { InvoiceEmailStatus } from "../Financials";
import styles from "./InvoiceAddress.module.scss";

export interface Props {
  address: AddressInterface;
  hasSameInvoiceAddress: boolean | undefined;
  setHasSameInvoiceAddress: (value: boolean) => void;
  invoiceAddress: Address;
  primary?: Associate;
  setInvoiceAddress: Dispatch<SetStateAction<Address>>;
  invoiceEmail?: string;
  setInvoiceEmail: (value: string) => void;
  code: string;
  setCode: (value: string) => void;
  setInvoiceStatus: Dispatch<SetStateAction<InvoiceEmailStatus>>;
  invoiceStatus: InvoiceEmailStatus;
  latestVerifiedInvoiceEmail: MutableRefObject<string>;
}

export const InvoiceAddress: React.FunctionComponent<Props> = ({
  hasSameInvoiceAddress,
  setHasSameInvoiceAddress,
  invoiceAddress,
  setInvoiceAddress,
  address,
}) => {
  const { i18n, t } = useTranslation();

  const countries = useMemo(
    () => getCountryOpts(t, i18n.language, false, null),
    [i18n.language, t]
  );

  const opts = useMemo(() => {
    return [
      { text: "Yes", value: true },
      { text: "No", value: false },
    ];
  }, []);

  return (
    <div className="m-top-30">
      <RadioGroup
        label={
          <>
            <T>Invoice address is same as company address</T>
            <br />
            <span className={cx(styles.address, "text-small")}>
              {address.street}, {address.postalCode} {address.city},{" "}
              {getCountryDisplayName(address.countryCode || "", i18n.language)}
            </span>
          </>
        }
        name="invoice-address"
        className="horizontal"
        value={hasSameInvoiceAddress}
        onChange={(value) => {
          setHasSameInvoiceAddress(value.toString() === "false" ? false : true);
        }}
        alternatives={opts}
        validators={[new RequiredValidator("Value is required")]}
      />

      <AnimateHeight name={hasSameInvoiceAddress === false ? "nope" : "yes"}>
        <div>
          {hasSameInvoiceAddress === false ? (
            <>
              <h5>
                <T>Invoice address</T>
              </h5>

              <AddressSearch
                label="Search address"
                onChange={(address) => {
                  setInvoiceAddress({
                    street: "",
                    postalCode: "",
                    city: "",
                    countryCode: undefined,
                    ...address,
                  });
                }}
              />

              <div className="tablet-columns">
                <div>
                  <TextInput
                    label="Street address"
                    value={invoiceAddress.street}
                    onChange={(value) =>
                      setInvoiceAddress((prev) => ({
                        ...prev,
                        street: value,
                      }))
                    }
                    name="street"
                    validators={[
                      new RequiredValidator("Street address is required"),
                    ]}
                  />
                </div>
                <div>
                  <TextInput
                    label="Postal code"
                    value={invoiceAddress.postalCode}
                    onChange={(value) =>
                      setInvoiceAddress((prev) => ({
                        ...prev,
                        postalCode: value,
                      }))
                    }
                    name="postalCode"
                    validators={[
                      new RequiredValidator("Postal code is required"),
                    ]}
                  />
                </div>
                <div>
                  <TextInput
                    label="City"
                    value={invoiceAddress.city}
                    onChange={(value) =>
                      setInvoiceAddress((prev) => ({
                        ...prev,
                        city: value,
                      }))
                    }
                    name="city"
                    validators={[new RequiredValidator("City is required")]}
                  />
                </div>
                <div>
                  <Select
                    name="Country"
                    label="Country"
                    alternatives={countries}
                    value={invoiceAddress.countryCode}
                    onChange={(value) => {
                      setInvoiceAddress((prev) => ({
                        ...prev,
                        countryCode: value,
                      }));
                    }}
                    validators={[
                      new RequiredValidator(
                        "You must select country of residence"
                      ),
                    ]}
                  />
                </div>
              </div>
            </>
          ) : null}
        </div>
      </AnimateHeight>
    </div>
  );
};
