import React, { ReactNode } from "react";
import cx from "classnames";
import "./Card.scss";

interface Props {
  className?: string;
  children: ReactNode;
  header?: ReactNode;
  accent?: boolean;
  bodyStyle?: React.CSSProperties;
}

export const Card: React.FunctionComponent<Props> = ({
  children,
  className,
  header,
  accent = false,
  bodyStyle,
}) => {
  return (
    <div
      className={cx("card", className, {
        accent,
      })}
    >
      {typeof header !== "undefined" && (
        <div className="card-header">
          <h4 className="truncate">{header}</h4>
        </div>
      )}

      <div className="card-body" style={bodyStyle}>
        {children}
      </div>
    </div>
  );
};
