import styles from "../PricePlan.module.scss";
import { T } from "../../translation/T";
import { ProductType } from "../../../data/models/ContractTypes";

interface Props {
  productType: ProductType;
}

export const PricePlanFooter: React.FunctionComponent<Props> = ({
  productType,
}) => (
  <div className={styles.terms}>
    <strong className={styles.termsTitle}>
      <T>Terms of Service</T>
    </strong>
    <ul className={styles.list}>
      {TERMS_MAPPING[productType].map((term) => (
        <li key={term} className={styles.li}>
          <T>{term}</T>
        </li>
      ))}
    </ul>
  </div>
);

const theInvoiceCost = "The monthly cost is invoiced monthly in advance.";
const theCostOfCardBrands =
  "The cost of Visa, MasterCard, Maestro, DinersClub, UnionPay and JCB is deducted from the payout you receive.";
const thePaymentOfRedeemedTransactions =
  "Payment of redeemed account transactions takes place in SEK on the banking day after the account card transaction has been completed.";

// TODO: should be different terms depending on product

const TERMS_MAPPING: Record<ProductType, string[]> = {
  [ProductType.ACCEPTANCE_ONLINE]: [
    theInvoiceCost,
    theCostOfCardBrands,
    thePaymentOfRedeemedTransactions,
  ],
  [ProductType.ACCEPTANCE_INSTORE]: [
    theInvoiceCost,
    theCostOfCardBrands,
    thePaymentOfRedeemedTransactions,
  ],
  [ProductType.BAMBORA_DEVICE]: [
    theInvoiceCost,
    theCostOfCardBrands,
    thePaymentOfRedeemedTransactions,
  ],
  [ProductType.BAMBORA_DEVICE_SHORT_TERM]: [
    theInvoiceCost,
    theCostOfCardBrands,
    thePaymentOfRedeemedTransactions,
  ],
  [ProductType.BAMBORA_ONE]: [
    theInvoiceCost,
    theCostOfCardBrands,
    thePaymentOfRedeemedTransactions,
  ],
  [ProductType.BAMBORA_ONE_SHORT_TERM]: [
    theInvoiceCost,
    theCostOfCardBrands,
    thePaymentOfRedeemedTransactions,
  ],
  [ProductType.CHECKOUT]: [
    theInvoiceCost,
    theCostOfCardBrands,
    thePaymentOfRedeemedTransactions,
  ],
};
