import React from "react";
import cx from "classnames";
import styles from "./Supplement.module.scss";
import { DocumentId, LinkId } from "../../data/models/ContractTypes";
import { Supplement as SupplementType } from "../../data/dataMerchant";
import {
  AnonymousFile,
  FileUpload,
  getUploadBaseUrl,
} from "../../components/uploads/FileUpload";
import { useTranslation } from "react-i18next";
import {
  documentTypeDescription,
  documentTypeDisplay,
} from "../../data/models/DocumentTypes";

interface Props {
  supplement: SupplementType;
  onUploadChange: (docId: DocumentId, uploaded: boolean) => void;
  linkId: LinkId;
  disabled?: boolean;
}

function getText(supplement: SupplementType, t: any) {
  if (supplement.documentDescription) {
    return supplement.documentDescription;
  }

  if (!supplement.documentType) {
    return "";
  }

  return t(documentTypeDescription(supplement.documentType));
}

export const Supplement: React.FunctionComponent<Props> = ({
  supplement,
  onUploadChange,
  linkId,
  disabled,
}) => {
  const { t } = useTranslation();

  return (
    <div className={cx(styles.wrapper)}>
      <div className={styles.header}>
        {t(
          supplement.documentType
            ? documentTypeDisplay(supplement.documentType)
            : "Requested document"
        )}
      </div>
      <div className={styles.card}>
        <div>{getText(supplement, t)}</div>
        <div className={styles.upload}>
          <FileUpload
            endpoint={`${getUploadBaseUrl()}/api/merchant/${linkId}/supplements/${
              supplement.documentId
            }`}
            disabled={disabled}
            initialFile={
              supplement.uploaded
                ? (t("Unknown file name") as AnonymousFile)
                : undefined
            }
            onUpload={() => {
              onUploadChange(supplement.documentId as DocumentId, true);
            }}
            onRemove={() => {
              onUploadChange(supplement.documentId as DocumentId, false);
            }}
            buttonClasses={cx(styles.button, "small", "ghost")}
          />
        </div>
      </div>
    </div>
  );
};
