import { ValidationResponse, VALIDATION_STATE } from "../hooks/useValidation";
import { BaseValidator } from "./BaseValidator";
import { electronicFormatIBAN, isValidIBAN } from "ibantools";

// https://www.iban.com/structure

export class IBANValidator extends BaseValidator {
  name = "IBAN validator";

  validate(value: string): Promise<ValidationResponse> {
    return new Promise<ValidationResponse>((resolve) => {
      if (
        value.length === 0 ||
        isValidIBAN(electronicFormatIBAN(value) || "")
      ) {
        resolve({ status: VALIDATION_STATE.SUCCESS });
      } else {
        resolve({
          status: VALIDATION_STATE.FAILED,
          message: this.error,
        });
      }
    });
  }
}
