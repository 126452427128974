import { useMemo, useRef, useState } from "react";
import cx from "classnames";
import styles from "./AssociateFields.module.scss";
import { useTranslation } from "react-i18next";
import { CountryChip } from "../../../../../components/chip/CountryChip";
import { Form } from "../../../../../components/form/Form";
import { HiddenInput } from "../../../../../components/form/HiddenInput";
import { Select } from "../../../../../components/form/Select";
import { TextInput } from "../../../../../components/form/TextInput";
import { RequiredValidator } from "../../../../../components/form/validators/RequiredValidator";
import { Dropdown } from "../../../../../components/interactions/Dropdown/Dropdown";
import { StepButtons } from "../../../../../components/steps/StepButtons";
import { getCountryOpts } from "../../../../../components/utils";
import { Associate, Id } from "../../../../../data/dataMerchant";
import { Country } from "../../../../../data/models/ContractTypes";
import { Status } from "../../../../../data/types";
import { MaxLengthValidator } from "../../../../../components/form/validators/MaxLengthValidator";
import { MinLengthValidator } from "../../../../../components/form/validators/MinLengthValidator";
import { useCountry } from "../../../../../hooks/useCountry";
import { T } from "../../../../../components/translation/T";

interface Props {
  associate: Associate;
  onChange: (associate: Associate) => void;
  setIndex: React.Dispatch<React.SetStateAction<number>>;
}

function getMaskedSSN(value?: string) {
  if (!value) {
    return value;
  }

  if (value.length < 4) {
    return value;
  }

  const length = value.length;
  const str = value.substring(0, 2).padEnd(length, "*");
  return `${str.substring(0, length - 1)}${value.slice(-1)}`;
}

export const DATE_PATTERN = "yyyy-MM-dd";

type Valdation = {
  hint: string;
  placeholder: string;
};

export const Validations: Record<Country, Valdation> = {
  [Country.SWEDEN]: {
    hint: "ÅÅÅÅMMDDNNNN",
    placeholder: "199901319999", // 12 chars
  },
  [Country.FINLAND]: {
    hint: "DDMMÅÅSNNNC", // Personbeteckningen, henkilötunnus
    placeholder: "310199Y001T", // 11 chars
  },
  [Country.DENMARK]: {
    hint: "DDMMÅÅNNNN", // CPR-nummer
    placeholder: "3101990001", // 10 chars
  },
  [Country.NORWAY]: {
    hint: "DDMMÅÅNNNKK", // Fødselsnummer
    placeholder: "31019912345", // 11 chars
  },

  [Country.GB]: {
    // Not applicable
    hint: "xxxxxxxxxxxx",
    placeholder: "xxxxxxxxxxxx",
  },
};

export const AssociateIdFields: React.FunctionComponent<Props> = ({
  associate,
  onChange,
  setIndex,
}) => {
  const formRef = useRef<HTMLFormElement>(null);
  const { i18n, t } = useTranslation();
  const [values, setValues] = useState<Partial<Id>>(associate.id || {});
  const [presetSSN] = useState<boolean>(!!associate.id?.nationalPersonId);
  const country = useCountry();

  const countries = useMemo(
    () =>
      getCountryOpts(
        t,
        i18n.language,
        false,
        undefined,
        undefined,
        values.citizenships
      ),
    [i18n.language, t, values.citizenships]
  );

  const residences = useMemo(
    () => getCountryOpts(t, i18n.language, false, null),
    [i18n.language, t]
  );

  const numberOfCitizenships = values.citizenships?.length ?? 0;

  const ssnValidationsByCountry = useMemo(
    () => Validations[country],
    [country]
  );

  return (
    <div>
      <Form
        name={`AssociateId-${associate.associateId}`}
        ref={formRef}
        onSubmit={(_, form) => {
          if (form.isInvalid) {
            return;
          }
          onChange({
            ...associate,
            id: values as Id,
          });
          setIndex((prev) => prev + 1);
        }}
      >
        <div className="m-top-20">
          <TextInput
            label="Social security number"
            onChange={(value) => {
              setValues({
                ...values,
                nationalPersonId: value,
              });
            }}
            value={
              presetSSN
                ? getMaskedSSN(values.nationalPersonId)
                : values.nationalPersonId
            }
            name="SSN"
            disabled={presetSSN}
            hint={<>{ssnValidationsByCountry.hint}</>} // yes, ugly, but we need to pass a ReactNode into the input in order to prevent default translations
            placeholder={`${t("E.g.")} "${
              ssnValidationsByCountry.placeholder
            }"`}
            validators={[
              new RequiredValidator("Social security number is required"),
              new MinLengthValidator(
                ssnValidationsByCountry.hint.length,
                (
                  <T
                    id="At least {{length}} characters are required ({{hint}})"
                    options={{
                      length: ssnValidationsByCountry.hint.length,
                      hint: ssnValidationsByCountry.hint,
                    }}
                  />
                )
              ),
              new MaxLengthValidator(
                ssnValidationsByCountry.hint.length,
                (
                  <T
                    id="Not more than {{length}} characters are allowed ({{hint}})"
                    options={{
                      length: ssnValidationsByCountry.hint.length,
                      hint: ssnValidationsByCountry.hint,
                    }}
                  />
                )
              ),
            ]}
          />
        </div>

        <div className="m-top-20">
          <Select
            name="countryOfBirth"
            label="Place of birth (Country)"
            alternatives={residences}
            value={values.countryOfBirth}
            onChange={(value) => {
              setValues({
                ...values,
                countryOfBirth: value as Country,
              });
            }}
            validators={[
              new RequiredValidator("You must select a place of birth"),
            ]}
          />
        </div>

        <div className="m-top-20">
          <Select
            name="countryOfResidence"
            label="Add residence"
            alternatives={residences}
            value={values.countryOfResidence}
            onChange={(value) => {
              setValues({
                ...values,
                countryOfResidence: value as Country,
              });
            }}
            validators={[
              new RequiredValidator("You must select country of residence"),
            ]}
          />
        </div>

        <div className="m-top-20">
          <Dropdown
            required={values.citizenships?.length ? false : true}
            name="citizenships"
            label="Add all citizenships"
            className={cx("compact", {
              "was-successful":
                numberOfCitizenships > 0 && numberOfCitizenships !== 3,
            })}
            alternatives={countries}
            value=""
            onChange={(citizenship) => {
              setValues({
                ...values,
                citizenships: [...(values.citizenships || []), citizenship],
              });
            }}
            status={
              numberOfCitizenships === 3 ? Status.DISABLED : Status.DEFAULT
            }
          />

          <div className={styles.countryChips}>
            {values.citizenships?.map((citizenship) => {
              return (
                <CountryChip
                  key={citizenship}
                  country={citizenship as Country}
                  onRemove={(country) => {
                    setValues({
                      ...values,
                      citizenships: values.citizenships?.filter(
                        (c) => c !== country
                      ),
                    });
                  }}
                  data={citizenship}
                />
              );
            })}
          </div>

          <HiddenInput
            label="Citizenship"
            value={values.citizenships?.length ? true : undefined}
            validators={[
              new RequiredValidator("You must select at least one citizenship"),
            ]}
            scrollToRef={formRef}
          />

          <div className="m-top-40">
            <StepButtons setIndex={setIndex} />
          </div>
        </div>
      </Form>
    </div>
  );
};
