import { SelfCheckout } from "./SelfCheckout/SelfCheckout";
import { RouteState } from "./../../state/routeState";
import { AppInformation } from "./AppInformation/AppInformation";
import { Documents } from "./Documents/Documents";
import { Financials } from "./Financials/Financials";
import { Intro } from "./Intro/Intro";
import { Owners } from "./Owners/Owners";
import { OwnershipAndControl } from "./OwnershipAndControl/OwnershipAndControl";
import { Pep } from "./Pep/Pep";
import { Signatories } from "./Signatories/Signatories";
import { Summary } from "./Summary/Summary";
import { WebsiteInformation } from "./WebsiteInformation/WebsiteInformation";
import { Bank } from "./Bank/Bank";
import { AccountHolder } from "./AccountHolder/AccountHolder";
import {
  LegalEntityType,
  ProductType,
  EcomType,
  Country,
} from "../../data/models/ContractTypes";
import { ThirdPartyPayment } from "./PayoutAccount/ThirdPartyPayment";
import { PayoutAccount } from "./PayoutAccount/PayoutAccount";
import { ConfirmWarning } from "./ConfirmWarning/ConfirmWarning";
import { Access } from "../../data/proxy";
import { Kar } from "./Bank/kar/Kar";
import { Bankgiro } from "./Bank/bankgiro/Bankgiro";

export interface RouteConfig {
  path: string;
  element: React.FunctionComponent<any>;
  includeLegalEntityType: Record<LegalEntityType, boolean>;
  includeProductType: Record<ProductType, boolean>;
  includeCriteria: (criteria?: RouteState) => boolean;
}

export const DATA_COLLECTION_STORY_ROUTES: Record<string, RouteConfig> = {
  INTRO: {
    path: "intro",
    element: Intro,
    includeLegalEntityType: {
      [LegalEntityType.LIMITED]: true,
      [LegalEntityType.SOLE_PROPRIETARY]: true,
      [LegalEntityType.PARTNERSHIP]: true,
      [LegalEntityType.LIMITED_PARTNERSHIP]: true,
      [LegalEntityType.ASSOCIATION]: true,
      [LegalEntityType.TRUST]: true,
      [LegalEntityType.MUNICIPALITY]: true,
    },
    includeProductType: {
      [ProductType.ACCEPTANCE_INSTORE]: true,
      [ProductType.ACCEPTANCE_ONLINE]: true,
      [ProductType.BAMBORA_DEVICE]: true,
      [ProductType.BAMBORA_DEVICE_SHORT_TERM]: true,
      [ProductType.BAMBORA_ONE]: true,
      [ProductType.BAMBORA_ONE_SHORT_TERM]: true,
      [ProductType.CHECKOUT]: true,
    },
    includeCriteria: () => true,
  },
  FINANCIALS: {
    path: "financial",
    element: Financials,
    includeLegalEntityType: {
      [LegalEntityType.LIMITED]: true,
      [LegalEntityType.SOLE_PROPRIETARY]: true,
      [LegalEntityType.PARTNERSHIP]: true,
      [LegalEntityType.LIMITED_PARTNERSHIP]: true,
      [LegalEntityType.ASSOCIATION]: true,
      [LegalEntityType.TRUST]: true,
      [LegalEntityType.MUNICIPALITY]: true,
    },
    includeProductType: {
      [ProductType.ACCEPTANCE_INSTORE]: true,
      [ProductType.ACCEPTANCE_ONLINE]: true,
      [ProductType.BAMBORA_DEVICE]: true,
      [ProductType.BAMBORA_DEVICE_SHORT_TERM]: true,
      [ProductType.BAMBORA_ONE]: true,
      [ProductType.BAMBORA_ONE_SHORT_TERM]: true,
      [ProductType.CHECKOUT]: true,
    },
    includeCriteria: () => true,
  },
  OWNERS: {
    path: "owners",
    element: Owners,
    includeLegalEntityType: {
      [LegalEntityType.LIMITED]: true,
      [LegalEntityType.SOLE_PROPRIETARY]: false,
      [LegalEntityType.PARTNERSHIP]: true,
      [LegalEntityType.LIMITED_PARTNERSHIP]: true,
      [LegalEntityType.ASSOCIATION]: true,
      [LegalEntityType.TRUST]: true,
      [LegalEntityType.MUNICIPALITY]: true,
    },
    includeProductType: {
      [ProductType.ACCEPTANCE_INSTORE]: true,
      [ProductType.ACCEPTANCE_ONLINE]: true,
      [ProductType.BAMBORA_DEVICE]: false,
      [ProductType.BAMBORA_DEVICE_SHORT_TERM]: false,
      [ProductType.BAMBORA_ONE]: true,
      [ProductType.BAMBORA_ONE_SHORT_TERM]: true,
      [ProductType.CHECKOUT]: true,
    },
    includeCriteria: () => true,
  },
  OWNERSHIP_AND_CONTROL: {
    path: "ownership-and-control",
    element: OwnershipAndControl,
    includeLegalEntityType: {
      [LegalEntityType.LIMITED]: true,
      [LegalEntityType.SOLE_PROPRIETARY]: false,
      [LegalEntityType.PARTNERSHIP]: false,
      [LegalEntityType.LIMITED_PARTNERSHIP]: true,
      [LegalEntityType.ASSOCIATION]: true,
      [LegalEntityType.TRUST]: true,
      [LegalEntityType.MUNICIPALITY]: true,
    },
    includeProductType: {
      [ProductType.ACCEPTANCE_INSTORE]: true,
      [ProductType.ACCEPTANCE_ONLINE]: true,
      [ProductType.BAMBORA_DEVICE]: false,
      [ProductType.BAMBORA_DEVICE_SHORT_TERM]: false,
      [ProductType.BAMBORA_ONE]: true,
      [ProductType.BAMBORA_ONE_SHORT_TERM]: true,
      [ProductType.CHECKOUT]: true,
    },
    includeCriteria: (criteria) => !criteria?.hasSimpleOwnership,
  },
  SIGNATORIES: {
    path: "signatories",
    element: Signatories,
    includeLegalEntityType: {
      [LegalEntityType.LIMITED]: true,
      [LegalEntityType.SOLE_PROPRIETARY]: true,
      [LegalEntityType.PARTNERSHIP]: true,
      [LegalEntityType.LIMITED_PARTNERSHIP]: true,
      [LegalEntityType.ASSOCIATION]: true,
      [LegalEntityType.TRUST]: true,
      [LegalEntityType.MUNICIPALITY]: true,
    },
    includeProductType: {
      [ProductType.ACCEPTANCE_INSTORE]: true,
      [ProductType.ACCEPTANCE_ONLINE]: true,
      [ProductType.BAMBORA_DEVICE]: true,
      [ProductType.BAMBORA_DEVICE_SHORT_TERM]: true,
      [ProductType.BAMBORA_ONE]: true,
      [ProductType.BAMBORA_ONE_SHORT_TERM]: true,
      [ProductType.CHECKOUT]: true,
    },
    includeCriteria: (criteria) => {
      if (criteria?.legalEntityType !== LegalEntityType.SOLE_PROPRIETARY) {
        return true;
      }

      if (criteria.everyAssociateHaveId) {
        return false;
      }

      return true;
    },
  },
  PEP: {
    path: "pep",
    element: Pep,
    includeLegalEntityType: {
      [LegalEntityType.LIMITED]: true,
      [LegalEntityType.SOLE_PROPRIETARY]: true,
      [LegalEntityType.PARTNERSHIP]: true,
      [LegalEntityType.LIMITED_PARTNERSHIP]: true,
      [LegalEntityType.ASSOCIATION]: true,
      [LegalEntityType.TRUST]: true,
      [LegalEntityType.MUNICIPALITY]: true,
    },
    includeProductType: {
      [ProductType.ACCEPTANCE_INSTORE]: true,
      [ProductType.ACCEPTANCE_ONLINE]: true,
      [ProductType.BAMBORA_DEVICE]: false,
      [ProductType.BAMBORA_DEVICE_SHORT_TERM]: false,
      [ProductType.BAMBORA_ONE]: true,
      [ProductType.BAMBORA_ONE_SHORT_TERM]: true,
      [ProductType.CHECKOUT]: true,
    },
    includeCriteria: () => true,
  },
  WEBSITE_INFORMATION: {
    path: "website-information",
    element: WebsiteInformation,
    includeLegalEntityType: {
      [LegalEntityType.LIMITED]: true,
      [LegalEntityType.SOLE_PROPRIETARY]: true,
      [LegalEntityType.PARTNERSHIP]: true,
      [LegalEntityType.LIMITED_PARTNERSHIP]: true,
      [LegalEntityType.ASSOCIATION]: true,
      [LegalEntityType.TRUST]: true,
      [LegalEntityType.MUNICIPALITY]: true,
    },
    includeProductType: {
      [ProductType.ACCEPTANCE_INSTORE]: false,
      [ProductType.ACCEPTANCE_ONLINE]: true,
      [ProductType.BAMBORA_DEVICE]: false,
      [ProductType.BAMBORA_DEVICE_SHORT_TERM]: false,
      [ProductType.BAMBORA_ONE]: false,
      [ProductType.BAMBORA_ONE_SHORT_TERM]: false,
      [ProductType.CHECKOUT]: true,
    },
    includeCriteria: (criteria) => criteria?.ecomType === EcomType.WEB,
  },
  APP_INFORMATION: {
    path: "app-information",
    element: AppInformation,
    includeLegalEntityType: {
      [LegalEntityType.LIMITED]: true,
      [LegalEntityType.SOLE_PROPRIETARY]: true,
      [LegalEntityType.PARTNERSHIP]: true,
      [LegalEntityType.LIMITED_PARTNERSHIP]: true,
      [LegalEntityType.ASSOCIATION]: true,
      [LegalEntityType.TRUST]: true,
      [LegalEntityType.MUNICIPALITY]: true,
    },
    includeProductType: {
      [ProductType.ACCEPTANCE_INSTORE]: false,
      [ProductType.ACCEPTANCE_ONLINE]: true,
      [ProductType.BAMBORA_DEVICE]: false,
      [ProductType.BAMBORA_DEVICE_SHORT_TERM]: false,
      [ProductType.BAMBORA_ONE]: false,
      [ProductType.BAMBORA_ONE_SHORT_TERM]: false,
      [ProductType.CHECKOUT]: true,
    },
    includeCriteria: (criteria) => criteria?.ecomType === EcomType.APP,
  },
  SELF_CHECKOUT: {
    path: "self-checkout",
    element: SelfCheckout,
    includeLegalEntityType: {
      [LegalEntityType.LIMITED]: true,
      [LegalEntityType.SOLE_PROPRIETARY]: true,
      [LegalEntityType.PARTNERSHIP]: true,
      [LegalEntityType.LIMITED_PARTNERSHIP]: true,
      [LegalEntityType.ASSOCIATION]: true,
      [LegalEntityType.TRUST]: true,
      [LegalEntityType.MUNICIPALITY]: true,
    },
    includeProductType: {
      [ProductType.ACCEPTANCE_INSTORE]: false,
      [ProductType.ACCEPTANCE_ONLINE]: false,
      [ProductType.BAMBORA_DEVICE]: true,
      [ProductType.BAMBORA_DEVICE_SHORT_TERM]: true,
      [ProductType.BAMBORA_ONE]: true,
      [ProductType.BAMBORA_ONE_SHORT_TERM]: true,
      [ProductType.CHECKOUT]: false,
    },
    includeCriteria: (criteria) => !!criteria?.hasSelfCheckout,
  },
  PAYOUT_ACCOUNT: {
    path: "payout-account",
    element: PayoutAccount,
    includeLegalEntityType: {
      [LegalEntityType.LIMITED]: true,
      [LegalEntityType.SOLE_PROPRIETARY]: true,
      [LegalEntityType.PARTNERSHIP]: true,
      [LegalEntityType.LIMITED_PARTNERSHIP]: true,
      [LegalEntityType.ASSOCIATION]: true,
      [LegalEntityType.TRUST]: true,
      [LegalEntityType.MUNICIPALITY]: true,
    },
    includeProductType: {
      [ProductType.ACCEPTANCE_INSTORE]: true,
      [ProductType.ACCEPTANCE_ONLINE]: true,
      [ProductType.BAMBORA_DEVICE]: false,
      [ProductType.BAMBORA_DEVICE_SHORT_TERM]: false,
      [ProductType.BAMBORA_ONE]: true,
      [ProductType.BAMBORA_ONE_SHORT_TERM]: true,
      [ProductType.CHECKOUT]: true,
    },
    includeCriteria: () => true,
  },
  BANKGIRO: {
    path: "bankgiro",
    element: Bankgiro,
    includeLegalEntityType: {
      [LegalEntityType.LIMITED]: true,
      [LegalEntityType.SOLE_PROPRIETARY]: true,
      [LegalEntityType.PARTNERSHIP]: true,
      [LegalEntityType.LIMITED_PARTNERSHIP]: true,
      [LegalEntityType.ASSOCIATION]: true,
      [LegalEntityType.TRUST]: true,
      [LegalEntityType.MUNICIPALITY]: true,
    },
    includeProductType: {
      [ProductType.ACCEPTANCE_INSTORE]: true,
      [ProductType.ACCEPTANCE_ONLINE]: true,
      [ProductType.BAMBORA_DEVICE]: false,
      [ProductType.BAMBORA_DEVICE_SHORT_TERM]: false,
      [ProductType.BAMBORA_ONE]: true,
      [ProductType.BAMBORA_ONE_SHORT_TERM]: true,
      [ProductType.CHECKOUT]: true,
    },
    includeCriteria: (criteria) => {
      if (criteria?.country !== Country.SWEDEN) {
        return false;
      }

      return !criteria?.thirdPartyPayments;
    },
  },
  ACCOUNT_HOLDER: {
    path: "account-holder",
    element: AccountHolder,
    includeLegalEntityType: {
      [LegalEntityType.LIMITED]: true,
      [LegalEntityType.SOLE_PROPRIETARY]: false,
      [LegalEntityType.PARTNERSHIP]: true,
      [LegalEntityType.LIMITED_PARTNERSHIP]: true,
      [LegalEntityType.ASSOCIATION]: true,
      [LegalEntityType.TRUST]: true,
      [LegalEntityType.MUNICIPALITY]: true,
    },
    includeProductType: {
      [ProductType.ACCEPTANCE_INSTORE]: true,
      [ProductType.ACCEPTANCE_ONLINE]: true,
      [ProductType.BAMBORA_DEVICE]: false,
      [ProductType.BAMBORA_DEVICE_SHORT_TERM]: false,
      [ProductType.BAMBORA_ONE]: true,
      [ProductType.BAMBORA_ONE_SHORT_TERM]: true,
      [ProductType.CHECKOUT]: true,
    },
    includeCriteria: (criteria) => {
      if (criteria?.country === Country.NORWAY) {
        return false;
      }

      if (criteria?.country === Country.SWEDEN) {
        return false;
      }

      if (criteria?.thirdPartyPayments) {
        return false;
      }

      if (criteria?.payoutAccountHasBeenAdded) {
        return false;
      }

      return true;
    },
  },
  BANK: {
    path: "bank",
    element: Bank,
    includeLegalEntityType: {
      [LegalEntityType.LIMITED]: true,
      [LegalEntityType.SOLE_PROPRIETARY]: true,
      [LegalEntityType.PARTNERSHIP]: true,
      [LegalEntityType.LIMITED_PARTNERSHIP]: true,
      [LegalEntityType.ASSOCIATION]: true,
      [LegalEntityType.TRUST]: true,
      [LegalEntityType.MUNICIPALITY]: true,
    },
    includeProductType: {
      [ProductType.ACCEPTANCE_INSTORE]: true,
      [ProductType.ACCEPTANCE_ONLINE]: true,
      [ProductType.BAMBORA_DEVICE]: false,
      [ProductType.BAMBORA_DEVICE_SHORT_TERM]: false,
      [ProductType.BAMBORA_ONE]: true,
      [ProductType.BAMBORA_ONE_SHORT_TERM]: true,
      [ProductType.CHECKOUT]: true,
    },
    includeCriteria: (criteria) => {
      if (criteria?.country === Country.NORWAY) {
        return false;
      }

      if (criteria?.country === Country.SWEDEN) {
        return false;
      }

      return (
        !!criteria?.accountHolderIsPrimaryContact &&
        !criteria?.thirdPartyPayments
      );
    },
  },
  KAR: {
    path: "kar",
    element: Kar,
    includeLegalEntityType: {
      [LegalEntityType.LIMITED]: true,
      [LegalEntityType.SOLE_PROPRIETARY]: true,
      [LegalEntityType.PARTNERSHIP]: true,
      [LegalEntityType.LIMITED_PARTNERSHIP]: true,
      [LegalEntityType.ASSOCIATION]: true,
      [LegalEntityType.TRUST]: true,
      [LegalEntityType.MUNICIPALITY]: true,
    },
    includeProductType: {
      [ProductType.ACCEPTANCE_INSTORE]: true,
      [ProductType.ACCEPTANCE_ONLINE]: true,
      [ProductType.BAMBORA_DEVICE]: false,
      [ProductType.BAMBORA_DEVICE_SHORT_TERM]: false,
      [ProductType.BAMBORA_ONE]: true,
      [ProductType.BAMBORA_ONE_SHORT_TERM]: true,
      [ProductType.CHECKOUT]: true,
    },
    includeCriteria: (criteria) => {
      if (criteria?.country !== Country.NORWAY) {
        return false;
      }

      return !criteria?.thirdPartyPayments;
    },
  },
  THIRD_PARTY_PAYMENTS: {
    path: "3rd-party-payments",
    element: ThirdPartyPayment,
    includeLegalEntityType: {
      [LegalEntityType.LIMITED]: true,
      [LegalEntityType.SOLE_PROPRIETARY]: true,
      [LegalEntityType.PARTNERSHIP]: true,
      [LegalEntityType.LIMITED_PARTNERSHIP]: true,
      [LegalEntityType.ASSOCIATION]: true,
      [LegalEntityType.TRUST]: true,
      [LegalEntityType.MUNICIPALITY]: true,
    },
    includeProductType: {
      [ProductType.ACCEPTANCE_INSTORE]: true,
      [ProductType.ACCEPTANCE_ONLINE]: true,
      [ProductType.BAMBORA_DEVICE]: false,
      [ProductType.BAMBORA_DEVICE_SHORT_TERM]: false,
      [ProductType.BAMBORA_ONE]: true,
      [ProductType.BAMBORA_ONE_SHORT_TERM]: true,
      [ProductType.CHECKOUT]: true,
    },
    includeCriteria: (criteria) =>
      !!criteria?.thirdPartyPayments || criteria?.access === Access.VIEW,
  },
  DOCUMENTS: {
    path: "documents",
    element: Documents,
    includeLegalEntityType: {
      [LegalEntityType.LIMITED]: true,
      [LegalEntityType.SOLE_PROPRIETARY]: true,
      [LegalEntityType.PARTNERSHIP]: true,
      [LegalEntityType.LIMITED_PARTNERSHIP]: true,
      [LegalEntityType.ASSOCIATION]: true,
      [LegalEntityType.TRUST]: true,
      [LegalEntityType.MUNICIPALITY]: true,
    },
    includeProductType: {
      [ProductType.ACCEPTANCE_INSTORE]: true,
      [ProductType.ACCEPTANCE_ONLINE]: true,
      [ProductType.BAMBORA_DEVICE]: true,
      [ProductType.BAMBORA_DEVICE_SHORT_TERM]: true,
      [ProductType.BAMBORA_ONE]: true,
      [ProductType.BAMBORA_ONE_SHORT_TERM]: true,
      [ProductType.CHECKOUT]: true,
    },
    includeCriteria: (criteria) => !!criteria?.hasGenericDocuments,
  },
  CONFIRM: {
    path: "confirm",
    element: ConfirmWarning,
    includeLegalEntityType: {
      [LegalEntityType.LIMITED]: true,
      [LegalEntityType.SOLE_PROPRIETARY]: true,
      [LegalEntityType.PARTNERSHIP]: true,
      [LegalEntityType.LIMITED_PARTNERSHIP]: true,
      [LegalEntityType.ASSOCIATION]: true,
      [LegalEntityType.TRUST]: true,
      [LegalEntityType.MUNICIPALITY]: true,
    },
    includeProductType: {
      [ProductType.ACCEPTANCE_INSTORE]: true,
      [ProductType.ACCEPTANCE_ONLINE]: true,
      [ProductType.BAMBORA_DEVICE]: true,
      [ProductType.BAMBORA_DEVICE_SHORT_TERM]: true,
      [ProductType.BAMBORA_ONE]: true,
      [ProductType.BAMBORA_ONE_SHORT_TERM]: true,
      [ProductType.CHECKOUT]: true,
    },
    includeCriteria: (criteria) => true,
  },
  SUMMARY: {
    path: "summary",
    element: Summary,
    includeLegalEntityType: {
      [LegalEntityType.LIMITED]: true,
      [LegalEntityType.SOLE_PROPRIETARY]: true,
      [LegalEntityType.PARTNERSHIP]: true,
      [LegalEntityType.LIMITED_PARTNERSHIP]: true,
      [LegalEntityType.ASSOCIATION]: true,
      [LegalEntityType.TRUST]: true,
      [LegalEntityType.MUNICIPALITY]: true,
    },
    includeProductType: {
      [ProductType.ACCEPTANCE_INSTORE]: true,
      [ProductType.ACCEPTANCE_ONLINE]: true,
      [ProductType.BAMBORA_DEVICE]: true,
      [ProductType.BAMBORA_DEVICE_SHORT_TERM]: true,
      [ProductType.BAMBORA_ONE]: true,
      [ProductType.BAMBORA_ONE_SHORT_TERM]: true,
      [ProductType.CHECKOUT]: true,
    },
    includeCriteria: () => true,
  },
};
