import debounce from "lodash.debounce";
import { useCallback, useState } from "react";
import { ValidationProps } from "../form/hooks/useValidation";
import { Searchable } from "../interactions/Searchable/Searchable";
import { Alternative } from "../interactions/InputTypes";
import { useTranslation } from "react-i18next";
import { dataLocationLookup } from "../../data/dataLocationLookup";
import { useLinkId } from "../../hooks/useLinkId";
import { useAccess } from "../../hooks/useAccess";

export interface AddressLocationChange {
  street?: string;
  city?: string;
  postalCode?: string;
  countryCode?: string;
}

interface Props extends ValidationProps {
  onChange: ({ street, city, postalCode }: AddressLocationChange) => void;
  disabled?: boolean;
  label?: string;
  hideHeader?: boolean;
}

const getStreetRow = ({
  street,
  streetNumber,
}: {
  street?: string;
  streetNumber?: string;
}) => {
  if (street && streetNumber) {
    return `${street} ${streetNumber}`;
  } else if (street) {
    return street;
  } else {
    return undefined;
  }
};

export const AddressSearch: React.FunctionComponent<Props> = ({
  onChange,
  label = null,
  ...props
}) => {
  const { t } = useTranslation();
  const access = useAccess();
  const linkId = useLinkId();
  const [searchValue, setSearchValue] = useState<string>("");
  const [suggestions, setSuggestions] = useState<Alternative<string>[]>([]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const search = useCallback(
    debounce(async (value: string) => {
      if (value.length < 3) {
        return;
      }

      dataLocationLookup(access)
        .getSuggestions(linkId, value)
        .then((suggestions) => {
          setSuggestions(
            suggestions.map(({ text }) => ({ text, value: text }))
          );
        });
    }, 250),
    []
  );

  const select = useCallback(
    (value: string) => {
      dataLocationLookup(access)
        .getLocation(linkId, value)
        .then((location) => {
          onChange({
            street: getStreetRow({
              ...location,
            }),
            city: location.city,
            postalCode: location.postalCode,
            countryCode: location.countryCode,
          });
          setSearchValue("");
          setSuggestions([]);
        });
    },
    [onChange, access, linkId]
  );

  return (
    <div className="address-search">
      <Searchable
        {...props}
        onChange={(value) => {
          setSearchValue(value);
          search(value);
        }}
        onSelect={select}
        alternatives={suggestions}
        value={searchValue}
        autocomplete="off"
        placeholder={t("Example street 13 ...")}
        {...{ label }}
      />
    </div>
  );
};
