import { useRef, useState, useMemo, useEffect, FunctionComponent } from "react";
import styles from "./AccountHolder.module.scss";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useSuspendedQuery } from "../../../hooks/useSuspendedQuery";
// components
import { T } from "../../../components/translation/T";
import { Form } from "../../../components/form/Form";
import { HiddenInput } from "../../../components/form/HiddenInput";
import { RequiredValidator } from "../../../components/form/validators/RequiredValidator";
import { StoryContinueButton } from "../../../components/story/StoryContinueButton";
import { AssociateCard } from "./components/AssociateCard";
import { AddAssociateCard } from "./components/AddAssociateCard";
import { useLinkId } from "../../../hooks/useLinkId";
// state
import { useSetRecoilState } from "recoil";
import { routeState } from "../../../state/routeState";
// types
import {
  isAccountHolder,
  isPrimary,
  isSelectedSignee,
} from "../../../data/dataAssociates";
import { Associate } from "../../../data/dataMerchant";
import { dataAssociates } from "../../../data/dataAssociates";
import { AssociateRole } from "../../../data/dataMerchant";
import { StoryStepProps } from "../../../components/story/Story";
import {
  InitialCustomAccountHolderState,
  AdditionalId,
  getFullname,
} from "./utils";
import { AnimateHeight } from "../../../components/animate/AnimateHeight";
import { GenericError } from "../../../components/Errors/GenericError";
import { AssociateId } from "../../../data/models/ContractTypes";
import { useAccess } from "../../../hooks/useAccess";
import { Access } from "../../../data/proxy";
import { Trans, useTranslation } from "react-i18next";

function getCustomAccountHolderData(associate?: Associate): Associate {
  if (!associate) {
    return structuredClone(InitialCustomAccountHolderState);
  }

  return { ...associate };
}

export const AccountHolder: FunctionComponent<StoryStepProps> = ({ next }) => {
  const { t } = useTranslation();
  const ref = useRef(null);
  const linkId = useLinkId();
  const queryClient = useQueryClient();
  const access = useAccess();

  const setRouteState = useSetRecoilState(routeState);
  const { data: associates } = useSuspendedQuery(
    dataAssociates(access).fetchAssociates(linkId)
  );
  const [selectedAccountHolder, setSelectedAccountHolder] =
    useState<AssociateId>();
  const originalAccountHolder = useRef<AssociateId>();

  useEffect(() => {
    if (!selectedAccountHolder) return;

    const primary = associates.find((associate) => isPrimary(associate));

    setRouteState((prev) => ({
      ...prev,
      accountHolderIsPrimaryContact:
        !!primary && selectedAccountHolder === primary.associateId,
    }));
  }, [selectedAccountHolder, setRouteState, associates]);

  useEffect(() => {
    const potentialAccountHolder = associates.find((associate) =>
      associate.roles.includes(AssociateRole.ACCOUNT_HOLDER)
    );
    originalAccountHolder.current = potentialAccountHolder?.associateId;
    setSelectedAccountHolder(potentialAccountHolder?.associateId);
  }, [associates]);

  const { primary, selectedSignees, custom } = useMemo(() => {
    let primary: Associate | undefined;
    let selectedSignees: Associate[] = [];
    let custom: Associate | undefined;

    associates.forEach((associate) => {
      if (isPrimary(associate)) {
        primary = associate;
        return;
      }

      if (associate.roles.length === 1 && isAccountHolder(associate)) {
        custom = associate;
        return;
      }

      if (isSelectedSignee(associate)) {
        selectedSignees.push(associate);
        return;
      }
    });

    return {
      primary,
      selectedSignees,
      custom,
    };
  }, [associates]);

  const [customAccountHolder, setCustomAccountHolder] = useState<Associate>(
    getCustomAccountHolderData(custom)
  );

  const [isSaved, setIsSaved] = useState<boolean>(!!custom);
  const [isEditing, setIsEditing] = useState(false);

  const {
    mutate: onSave,
    isLoading: isSaving,
    isError,
    reset: resetSave,
  } = useMutation<Associate>(
    () => {
      // This is a new custom Account Holder
      if (selectedAccountHolder === AdditionalId.ADD_MANUALLY) {
        return dataAssociates(access).saveAssociate(
          linkId,
          { ...customAccountHolder, roles: [AssociateRole.ACCOUNT_HOLDER] },
          true
        );
      }

      // This is an existing associate
      const selectedAssociate = associates.find(
        (associate) => associate.associateId === selectedAccountHolder
      );

      if (!selectedAssociate) {
        return Promise.reject();
      }

      const isCustomAccountHolder =
        selectedAccountHolder === customAccountHolder.associateId;

      const body = isCustomAccountHolder
        ? customAccountHolder
        : selectedAssociate;

      return dataAssociates(access).saveAssociate(
        linkId,
        {
          ...body,
          roles: [
            ...body.roles.filter(
              (role) => role !== AssociateRole.ACCOUNT_HOLDER
            ),
            AssociateRole.ACCOUNT_HOLDER,
          ],
        },
        false
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          dataAssociates(access).fetchAssociates(linkId).queryKey
        );
        next();
      },
    }
  );

  const {
    mutate: onRemove,
    isLoading: isRemoving,
    isError: isRemoveError,
    reset: resetRemove,
  } = useMutation<Associate | void>(
    () => {
      const previousAccountHolder = associates.find((associate) =>
        associate.roles.includes(AssociateRole.ACCOUNT_HOLDER)
      );

      if (!previousAccountHolder) {
        return Promise.resolve();
      }

      if (previousAccountHolder.associateId === selectedAccountHolder) {
        return Promise.resolve();
      }

      return dataAssociates(access).saveAssociate(
        linkId,
        {
          ...previousAccountHolder,
          roles: previousAccountHolder.roles.filter(
            (role) => role !== AssociateRole.ACCOUNT_HOLDER
          ),
        },
        false
      );
    },
    {
      onSuccess: () => onSave(),
    }
  );

  useEffect(() => {
    resetRemove();
    resetSave();
  }, [selectedAccountHolder, resetRemove, resetSave]);

  return (
    <>
      <h1>
        <T>Payout account</T>
      </h1>
      <p>
        <T>Please select a person that can verify your payout account.</T>
      </p>
      <div>
        <Trans t={t}>
          The person you choose <b>must have access to the bank</b> for your
          company, so only choose people who have this.
        </Trans>
      </div>

      <div className={styles.body}>
        {primary && (
          <div className={styles.option}>
            <strong className={styles.title}>
              <T>You</T>
            </strong>
            <div className={styles.cards}>
              <AssociateCard
                name={getFullname(primary)}
                roles={primary.roles}
                isSelected={selectedAccountHolder === primary.associateId}
                onClick={() => {
                  setSelectedAccountHolder(primary.associateId);
                }}
              />
            </div>
          </div>
        )}
        {selectedSignees.length > 0 && (
          <div className={styles.option}>
            <strong className={styles.title}>
              <T>Signatories</T>
            </strong>
            <div className={styles.cards}>
              {selectedSignees.map((associate) => (
                <AssociateCard
                  key={associate.associateId}
                  name={getFullname(associate)}
                  roles={associate.roles}
                  isSelected={selectedAccountHolder === associate.associateId}
                  onClick={() => {
                    setSelectedAccountHolder(associate.associateId);
                  }}
                />
              ))}
            </div>
          </div>
        )}
        <div className={styles.option}>
          <strong className={styles.title}>
            <T>Custom</T>
          </strong>
          <div className={styles.cards}>
            <AddAssociateCard
              isSaved={isSaved}
              setIsSaved={setIsSaved}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              customAccountHolder={customAccountHolder}
              setCustomAccountHolder={setCustomAccountHolder}
              setSelectedAccountHolder={setSelectedAccountHolder}
              isSelected={
                selectedAccountHolder === customAccountHolder.associateId
              }
            />
          </div>
        </div>
      </div>
      <div>
        <Form
          onSubmit={(_, form) => {
            if (!form.isValid) {
              return;
            }

            onRemove();
          }}
          id="account-holder-form"
        >
          <div className={styles.validators}>
            <HiddenInput
              label="Missing account holder"
              value={!!selectedAccountHolder ? true : undefined}
              validators={[
                new RequiredValidator("Please select an account holder"),
              ]}
              scrollToRef={ref}
            />

            {selectedAccountHolder === customAccountHolder.associateId ? (
              <HiddenInput
                label="Editing custom account holder"
                value={isEditing && !isSaved ? undefined : true}
                validators={[
                  new RequiredValidator(
                    "Please finish updating the account holder information"
                  ),
                ]}
                scrollToRef={ref}
              />
            ) : null}
          </div>

          <div className="m-top-30">
            <AnimateHeight name={isError || isRemoveError ? "error" : ""}>
              <div>{isError || isRemoveError ? <GenericError /> : <div />}</div>
            </AnimateHeight>
          </div>

          <StoryContinueButton
            disabled={access === Access.VIEW}
            type="submit"
            isLoading={isSaving || isRemoving}
          >
            <T>Continue</T>
          </StoryContinueButton>
        </Form>
      </div>
    </>
  );
};
