import React, { useMemo, useRef } from "react";
import { OwnershipCompanyOverlay } from "./OwnershipCompanyOverlay";
import { Button } from "../../../../components/interactions/Buttons/Button";
import { T } from "../../../../components/translation/T";
import { Form } from "../../../../components/form/Form";
import { HiddenInput } from "../../../../components/form/HiddenInput";
import { RequiredValidator } from "../../../../components/form/validators/RequiredValidator";
import { Card } from "../../../../components/cards/Card";
import { Section } from "../../../../components/section/Section";
import { OwnershipCompany } from "../../../../data/dataOwnership";
import { AnimateHeightAuto } from "../../../../components/animate/AnimateHeightAuto";

interface Props {
  companies: OwnershipCompany[];
  onChange: (companies: OwnershipCompany[]) => void;
}

export const OwnershipCompanies: React.FunctionComponent<Props> = ({
  companies,
  onChange,
}) => {
  const formRef = useRef<HTMLFormElement>(null);

  const totalOwnershipPercentage = useMemo(() => {
    return companies.reduce(
      (acc, company) => acc + (company.ownershipPercentage || 0),
      0
    );
  }, [companies]);

  return (
    <>
      <Section highlighted classNames="flex-columns gap-10">
        <AnimateHeightAuto
          dependencies={[companies.length]}
          className="flex-columns gap-10"
        >
          {companies.map((company, index) => (
            <Card key={index}>
              <OwnershipCompanyOverlay
                company={company}
                onChange={(company) => {
                  const newCompanies = [...companies];
                  newCompanies[index] = company;
                  onChange(newCompanies);
                }}
                onRemove={() => {
                  const newCompanies = [...companies];
                  newCompanies.splice(index, 1);
                  onChange(newCompanies);
                }}
              />
            </Card>
          ))}
        </AnimateHeightAuto>
        <Form name="OwnershipCompanies" ref={formRef}>
          <HiddenInput
            label="Total Ownership percentage"
            value={totalOwnershipPercentage <= 100 ? true : undefined}
            validators={[
              new RequiredValidator(
                "Ownership percentage must be 100% or less"
              ),
            ]}
            scrollToRef={formRef}
          />
          <HiddenInput
            label="At least one company"
            value={companies.length > 0 ? true : undefined}
            validators={[
              new RequiredValidator("At least one company must be added"),
            ]}
            scrollToRef={formRef}
          />
        </Form>
        <Button
          ghost
          block
          onClick={() =>
            onChange([
              ...companies,
              {
                new: true,
              },
            ])
          }
        >
          <T>Add company</T>
        </Button>
      </Section>
    </>
  );
};
