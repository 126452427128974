import { useRef, useState } from "react";
import { Form } from "../../../../../components/form/Form";
import { TextInput } from "../../../../../components/form/TextInput";
import { EmailValidator } from "../../../../../components/form/validators/EmailValidator";
import { MaxLengthValidator } from "../../../../../components/form/validators/MaxLengthValidator";
import { MinLengthValidator } from "../../../../../components/form/validators/MinLengthValidator";
import { PhoneCountryCodeValidator } from "../../../../../components/form/validators/PhoneCountryCodeValidator";
import { RequiredValidator } from "../../../../../components/form/validators/RequiredValidator";
import { StepButtons } from "../../../../../components/steps/StepButtons";
import { Associate } from "../../../../../data/dataMerchant";
import { Contact } from "../../../../../data/models/ContractTypes";
import { MIN_LENGTH, MAX_LENGTH } from "../SignatoryOverlay";
import { useLinkId } from "../../../../../hooks/useLinkId";
import { EmailBackendValidator } from "../../../../../components/form/validators/EmailBackendValidator";
import {
  RegexValidator,
  PHONE_REGEX,
} from "../../../../../components/form/validators/RegexValidator";
import {
  MIN_MOBILE_PHONE_CHARS,
  MAX_MOBILE_PHONE_CHARS,
} from "../../../Intro/components/CompanyInfo";

interface Props {
  associate: Associate;
  onChange: (associate: Associate) => void;
  setIndex: React.Dispatch<React.SetStateAction<number>>;
}

export const AssociateContactFields: React.FunctionComponent<Props> = ({
  associate,
  onChange,
  setIndex,
}) => {
  const formRef = useRef<HTMLFormElement>(null);
  const linkId = useLinkId();
  const [values, setValues] = useState<Contact>(associate.contact || {});

  return (
    <div>
      <Form
        name={`AssociateContact-${associate.associateId}`}
        ref={formRef}
        onSubmit={(_, form) => {
          if (form.isInvalid) {
            return;
          }
          onChange({
            ...associate,
            contact: values,
          });
          setIndex((prev) => prev + 1);
        }}
      >
        <div className="m-top-10">
          <TextInput
            label="First name"
            value={values.firstName}
            onChange={(value) =>
              setValues({
                ...values,
                firstName: value,
              })
            }
            name="firstName"
            validators={[
              new RequiredValidator("First name is required"),
              new MinLengthValidator(
                MIN_LENGTH,
                "At least two characters are required"
              ),
              new MaxLengthValidator(
                MAX_LENGTH,
                "Not more than 30 characters are allowed"
              ),
            ]}
          />
        </div>
        <div className="m-top-10">
          <TextInput
            label="Last name"
            onChange={(value) =>
              setValues({
                ...values,
                lastName: value,
              })
            }
            value={values.lastName}
            name="lastName"
            validators={[
              new RequiredValidator("Last name is required"),
              new MinLengthValidator(
                MIN_LENGTH,
                "At least two characters are required"
              ),
              new MaxLengthValidator(
                MAX_LENGTH,
                "Not more than 30 characters are allowed"
              ),
            ]}
          />
        </div>

        <div className="m-top-10">
          <TextInput
            name="email"
            label="Email"
            value={values.email}
            onChange={(value) =>
              setValues({
                ...values,
                email: value,
              })
            }
            validators={[
              new RequiredValidator("Email is required"),
              new EmailValidator("Email is not valid"),
              new MaxLengthValidator(
                50,
                "Email must be less than 50 characters"
              ),
              new EmailBackendValidator(linkId, "Email is not valid"),
            ]}
          />
        </div>

        <div className="m-top-10">
          <TextInput
            label="Mobile phone number"
            onChange={(value) =>
              setValues({
                ...values,
                phoneNumber: value,
              })
            }
            value={values.phoneNumber}
            name="phoneNumber"
            validators={[
              new RequiredValidator("Mobile phone number is required"),
              new PhoneCountryCodeValidator(
                "Phone number must start with a country code e.g +61..."
              ),
              new MinLengthValidator(
                MIN_MOBILE_PHONE_CHARS,
                `Mobile phone must be at least ${MIN_MOBILE_PHONE_CHARS} characters`
              ),
              new MaxLengthValidator(
                MAX_MOBILE_PHONE_CHARS,
                `Mobile phone must be less than ${MAX_MOBILE_PHONE_CHARS} characters`
              ),
              new RegexValidator(
                PHONE_REGEX,
                "Phone contains invalid characters"
              ),
            ]}
            hint="Country code is required e.g +46..."
          />
        </div>

        <div className="m-top-10">
          <TextInput
            label="Position"
            onChange={(value) =>
              setValues({
                ...values,
                position: value,
              })
            }
            value={values.position}
            name="position"
            validators={[new RequiredValidator("Position is required")]}
          />
        </div>

        <div className="m-top-20">
          <StepButtons showBack={false} setIndex={setIndex} />
        </div>

        {/* <AssociateFormButtons
          onBack={onBack}
          extraButton={
            isFormValid ? (
              <Button type="submit" ghost>
                <T>Save and close</T>
              </Button>
            ) : (
              <div />
            )
          }
        /> */}
      </Form>
    </div>
  );
};
