import React, { useEffect, useRef, useState } from "react";
import { StoryStepProps } from "../../../components/story/Story";
import { Form, FormContainer } from "../../../components/form/Form";
import { StoryContinueButton } from "../../../components/story/StoryContinueButton";
import { T } from "../../../components/translation/T";
import { BankAccountSource, dataBank } from "../../../data/dataBank";
import { RequiredValidator } from "../../../components/form/validators/RequiredValidator";
import { useLinkId } from "../../../hooks/useLinkId";
import { useSuspendedQuery } from "../../../hooks/useSuspendedQuery";
import {
  FileData,
  FileUpload,
  getUploadBaseUrl,
} from "../../../components/uploads/FileUpload";
import { HiddenInput } from "../../../components/form/HiddenInput";
import { Trans, useTranslation } from "react-i18next";
import { Card } from "../../../components/cards/Card";
import { useQueryClient } from "@tanstack/react-query";
import { TextInput } from "../../../components/form/TextInput";
import { WarningBox } from "../../../components/boxes/WarningBox";
import { IBANValidator } from "../../../components/form/validators/IBANValidator";
import { useAccess } from "../../../hooks/useAccess";
import { Access } from "../../../data/proxy";
import styles from "./ThirdPartyPayment.module.scss";
import { AnimateHeight } from "../../../components/animate/AnimateHeight";
import { NumberedItem } from "../../../components/numberedItem/NumberedItem";
import { BICValidator } from "../../../components/form/validators/BICValidator";

export const ThirdPartyPayment: React.FunctionComponent<StoryStepProps> = ({
  next,
}) => {
  const linkId = useLinkId();
  const ref = useRef<HTMLFormElement>(null);
  const formContainer = useRef<FormContainer>();
  const access = useAccess();

  const { data: bank } = useSuspendedQuery(
    dataBank(access).fetchConfirmedStatus(linkId)
  );
  const { t } = useTranslation();
  const [iban, setIban] = useState<string>(bank.iban || "");
  const [bic, setBic] = useState<string>(bank.bic || "");
  const [disableInput, setDisableInput] = useState<boolean>(!!iban && !!bic);
  const [allowUpload, setAllowUpload] = useState<boolean>(!!iban && !!bic);
  const [file, setFile] = useState<FileData | undefined>(
    bank.done && bank.source === BankAccountSource.BANK_STATEMENT
      ? {
          name: t("Uploaded bank statement"),
          type: "",
        }
      : undefined
  );
  const queryClient = useQueryClient();

  useEffect(() => {
    const onUpdate = () => {
      setAllowUpload(!!formContainer.current?.isValid);
    };

    const container = formContainer.current;
    container?.addListener(onUpdate);
    return () => {
      container?.removeListener(onUpdate);
    };
  }, []);

  return (
    <div>
      <h1>
        <T>Bank statement</T>
      </h1>

      <Form
        name="third-party-payments"
        formContainer={formContainer}
        onSubmit={(_, form) => {
          if (!form.isValid) {
            return;
          }
          queryClient.invalidateQueries(
            dataBank(access).getConfirmedStatusKey(linkId)
          );
          next();
        }}
      >
        <Card className="m-top-40">
          <T>
            You need to add a payout account. But since you receive third party
            payments there is no way for us to verify such a payout account by a
            digital lookup 😔.
          </T>
          <div className="m-top-10">
            <Trans t={t}>
              Hence, you will have to manually add an <b>IBAN</b> and{" "}
              <b>BIC/SWIFT</b> for your payout account in addition to{" "}
              <u>uploading a bank statement</u>. It is unfortunate.
            </Trans>
          </div>

          <div className="m-top-40 ">
            <div className="m-top-20">
              <TextInput
                name="iban"
                value={iban}
                onChange={(value) => setIban(value)}
                label="IBAN"
                validators={[
                  new RequiredValidator("IBAN is required"),
                  new IBANValidator("IBAN is incorrectly formatted"),
                ]}
                hint='E.g. "SE45 5000 0000 0583 9825 7466"'
                disabled={disableInput}
              />
            </div>

            <div className="m-top-20">
              <TextInput
                name="bic"
                value={bic}
                onChange={(value) => setBic(value)}
                label="BIC/SWIFT"
                validators={[
                  new RequiredValidator("BIC/SWIFT is required"),
                  new BICValidator("BIC/SWIFT is incorrectly formatted"),
                ]}
                hint='E.g. "AAAABBCC123"'
                disabled={disableInput}
              />
            </div>
          </div>
          <div className="m-top-30" />
        </Card>
        <div className="m-top-40">
          <Card>
            <h5>
              <T>Bank statement</T>
            </h5>

            <div style={{ padding: "1px 0" }}>
              <AnimateHeight name={allowUpload ? "one" : "two"}>
                {allowUpload ? (
                  <div />
                ) : (
                  <div style={{ padding: "10px 0 40px 0" }}>
                    <WarningBox relative>
                      <Trans t={t}>
                        You must add <b>IBAN</b> and <b>BIC</b> before uploading
                        statement.
                      </Trans>
                    </WarningBox>
                  </div>
                )}
              </AnimateHeight>
            </div>
            <div className={styles.info}>
              <p>
                <T>Make sure that the attached bank statement includes </T>
              </p>
              <NumberedItem
                className={styles.numbered}
                index={1}
                color="#e5f2f4"
              >
                <Trans t={t}>
                  the name of the <b>bank account holder</b>,
                </Trans>
              </NumberedItem>
              <NumberedItem
                className={styles.numbered}
                index={2}
                color="#e5f2f4"
              >
                <Trans t={t}>
                  the <b>bank account number</b>,
                </Trans>
              </NumberedItem>
              <NumberedItem
                className={styles.numbered}
                index={3}
                color="#e5f2f4"
              >
                <Trans t={t}>
                  the <b>name of the bank</b> (can also be logo, URL, or BIC),
                </Trans>
              </NumberedItem>
              <NumberedItem
                className={styles.numbered}
                index={4}
                color="#e5f2f4"
              >
                <Trans t={t}>
                  and <b>date</b> showing when the document was created (it must
                  not be older than two months)
                </Trans>
              </NumberedItem>
            </div>
            <div className="m-top-40">
              <FileUpload
                endpoint={`${getUploadBaseUrl()}/api/merchant/bank/${linkId}/statement`}
                onUpload={(file) => {
                  queryClient.invalidateQueries(
                    dataBank(access).getConfirmedStatusKey(linkId)
                  );
                  setDisableInput(true);
                  setFile(file);
                }}
                onRemove={() => {
                  queryClient.invalidateQueries(
                    dataBank(access).getConfirmedStatusKey(linkId)
                  );
                  setDisableInput(false);
                  setFile(undefined);
                }}
                buttonText="Upload Bank statement"
                buttonClasses="small ghost"
                initialFile={file}
                disabled={!allowUpload || access === Access.VIEW}
                appends={{
                  iban,
                  bic,
                }}
              />
            </div>
          </Card>
        </div>
      </Form>
      <Form
        name="third-party-upload"
        onSubmit={(_, form) => {
          if (!form.isValid) {
            return;
          }

          next();
        }}
      >
        <div className="m-top-10">
          <HiddenInput
            label="Completed documents"
            value={!!file ? true : undefined}
            validators={[
              new RequiredValidator(
                "You must upload the bank statement in order to proceed"
              ),
            ]}
            scrollToRef={ref}
          />
        </div>
        <div className="m-top-40">
          <StoryContinueButton
            type="submit"
            className="m-top-30"
            disabled={access === Access.VIEW}
          >
            <T>Continue</T>
          </StoryContinueButton>
        </div>
      </Form>
    </div>
  );
};
