import { ProductType } from "../../../../data/models/ContractTypes";
import { ProductByLanguage } from "../TermsAndConditions";

export const fiLinks: ProductByLanguage = {
  [ProductType.ACCEPTANCE_INSTORE]: [
    {
      name: "Acquiring - General terms (pdf)",
      link: "https://support.worldline.com/content/dam/support-worldline/local/sv-se/documents/card-acquiring-terms-and-conditions.pdf",
    },
  ],
  [ProductType.ACCEPTANCE_ONLINE]: [
    {
      name: "Acquiring - General terms (pdf)",
      link: "https://support.worldline.com/content/dam/support-worldline/local/sv-se/documents/card-acquiring-terms-and-conditions.pdf",
    },
  ],
  [ProductType.BAMBORA_DEVICE]: [
    {
      name: "Worldline device - General terms (pdf)",
      link: "https://support.worldline.com/content/dam/support-worldline/local/sv-se/documents/worldline-device-terms-and-conditions.pdf",
    },
  ],
  [ProductType.BAMBORA_DEVICE_SHORT_TERM]: [
    {
      name: "Worldline device - General terms (pdf)",
      link: "https://support.worldline.com/content/dam/support-worldline/local/sv-se/documents/worldline-device-terms-and-conditions.pdf",
    },
  ],
  [ProductType.BAMBORA_ONE]: [
    {
      name: "Worldline One - General terms (pdf)",
      link: "https://support.worldline.com/content/dam/support-worldline/local/sv-se/documents/worldline-one-and-worldline-grow-general-terms-and-conditions.pdf",
    },
  ],
  [ProductType.BAMBORA_ONE_SHORT_TERM]: [
    {
      name: "Worldline Grow - General terms (pdf)",
      link: "https://support.worldline.com/content/dam/support-worldline/local/sv-se/documents/worldline-one-and-worldline-grow-general-terms-and-conditions.pdf",
    },
  ],
  [ProductType.CHECKOUT]: [
    {
      name: "Worldline Checkout - General terms (pdf)",
      link: "https://support.worldline.com/content/dam/support-worldline/local/sv-se/documents/worldline-checkout-general-terms-and-conditions.pdf",
    },
  ],
};
