import { ProductType } from "../../../../data/models/ContractTypes";
import { ProductByLanguage } from "../TermsAndConditions";

export const noLinks: ProductByLanguage = {
  [ProductType.ACCEPTANCE_INSTORE]: [
    {
      name: "Acquiring - General terms (pdf)",
      link: "https://worldline.com/content/dam/worldline/local/no-no/documents/Card-Acquiring-Terms-and-Conditions.pdf",
    },
  ],
  [ProductType.ACCEPTANCE_ONLINE]: [
    {
      name: "Acquiring - General terms (pdf)",
      link: "https://worldline.com/content/dam/worldline/local/no-no/documents/Card-Acquiring-Terms-and-Conditions.pdf",
    },
  ],
  [ProductType.BAMBORA_DEVICE]: [
    {
      name: "Worldline device - General terms (pdf)",
      link: "https://worldline.com/content/dam/worldline/local/no-no/documents/bambora-Device-Terms-and-Conditions.pdf",
    },
  ],
  [ProductType.BAMBORA_DEVICE_SHORT_TERM]: [
    {
      name: "Worldline device - General terms (pdf)",
      link: "https://worldline.com/content/dam/worldline/local/no-no/documents/bambora-Device-Terms-and-Conditions.pdf",
    },
  ],
  [ProductType.BAMBORA_ONE]: [
    {
      name: "Worldline One - General terms (pdf)",
      link: "https://worldline.com/content/dam/worldline/local/no-no/documents/bambora-One-and-bambora-Grow-General-Terms-and-Conditions.pdf",
    },
  ],
  [ProductType.BAMBORA_ONE_SHORT_TERM]: [
    {
      name: "Worldline Grow - General terms (pdf)",
      link: "https://worldline.com/content/dam/worldline/local/no-no/documents/bambora-One-and-bambora-Grow-General-Terms-and-Conditions.pdf",
    },
  ],
  [ProductType.CHECKOUT]: [
    {
      name: "Worldline Checkout - General terms (pdf)",
      link: "https://worldline.com/content/dam/worldline/local/no-no/documents/bambora-Checkout-General-Terms-and-Conditions.pdf",
    },
  ],
};
