import { ValidationResponse, VALIDATION_STATE } from "../hooks/useValidation";
import { BaseValidator } from "./BaseValidator";

export class MinDateValidator extends BaseValidator {
  name = "MinDateValidator";
  private min: Date;

  constructor(min: Date, error: string) {
    super(error);
    this.min = min;
  }

  validate(value: Date | string): Promise<ValidationResponse> {
    return new Promise<ValidationResponse>((resolve) => {
      let modifiedValue = value;
      if (typeof value === "string") {
        modifiedValue = new Date(value);
      }

      if (modifiedValue >= this.min) {
        resolve({ status: VALIDATION_STATE.SUCCESS });
      } else {
        resolve({
          status: VALIDATION_STATE.FAILED,
          message: this.error,
        });
      }
    });
  }
}
