import React from "react";
import { StoryStepProps } from "../../../components/story/Story";

// const SELF_CHECKOUT_ALTERNATIVES = [
//   {
//     value: "terminalIsAssignedASeller",
//     text: "As a reseller I confirm that there will always be an assigned seller that is responsible for the terminal device and will controll the terminal if there are problems",
//   },
//   {
//     value: "terminalIsInaccessableOutsideWorkhours",
//     text: "As a reseller I confirm that the terminal device will be inaccessible when my store is closed",
//   },
//   {
//     value: "terminalResidesInside",
//     text: "As a reseller I confirm that the terminal device will reside inside my store",
//   },
//   {
//     value: "terminalIsControlledPeridically",
//     text: "As a reseller I will verify the function of the terminal device at store opening and then every eight hours until closure. On each controll I will follow the Worldline instructions that are included when the terminals are delivered",
//   },
//   {
//     value: "terminalIsPhysicallyLockedDown",
//     text: 'As a reseller I confirm that the "self checkout" terminal devices are placed in their spacepole and locked with corresponding lock supplied by Worldline',
//   },
//   {
//     value: "terminalAllowsOnlyChipOrWirelessTransactions",
//     text: 'As a reseller I understand that only chip or wireless transactions are available in "self checkout" terminal devices',
//   },
//   {
//     value: "terminalProvidesReceipt",
//     text: "As a reseller I understand that I must supply the customers with a receipt according the same rules as for a manned terminal",
//   },
// ];

export const SelfCheckout: React.FunctionComponent<StoryStepProps> = ({
  next,
}) => {
  return null;
  // const linkId = useLinkId();
  // const contract = useRecoilValue(contractState);
  // const { data: response } = useSuspendedQuery(
  //   dataProduct.fetchProduct<ProductWithPhysicalTerminals>(linkId)
  // );

  // const [product, setProduct] =
  //   useState<ProductWithPhysicalTerminals>(response);

  // const {
  //   mutate: onSave,
  //   isLoading,
  //   isError,
  //   reset,
  // } = useMutation(
  //   () => {
  //     return dataProduct.postProduct(linkId, product);
  //   },
  //   {
  //     onSuccess: next,
  //   }
  // );

  // return (
  //   <Form
  //     onSubmit={(_, form) => {
  //       if (!form.isValid) {
  //         return;
  //       }
  //       onSave();
  //     }}
  //   >
  //     <h2>
  //       <T>Self checkout</T>
  //     </h2>

  //     <p>
  //       <Trans t={t}>
  //         Since <b>{contract.contractData.companyName}</b> offers a self
  //         checkout option, we need you to answer a few additional questions
  //       </Trans>
  //     </p>

  //     <div className="m-top-30">
  //       {SELF_CHECKOUT_ALTERNATIVES.map((item) => {
  //         return (
  //           <div className="m-top-20" key={item.value}>
  //             <RadioGroup
  //               label={<T>{item.text}</T>}
  //               name={item.value}
  //               className="horizontal"
  //               value={
  //                 product.checklist[item.value as keyof SelfCheckoutChecklist]
  //               }
  //               onChange={(value, name) => {
  //                 reset();
  //                 setProduct((prev) => ({
  //                   ...prev,
  //                   checklist: {
  //                     ...prev.checklist,
  //                     [item.value]: (value as any) === "true",
  //                   },
  //                 }));
  //               }}
  //               alternatives={[
  //                 {
  //                   text: "Yes",
  //                   value: true,
  //                 },
  //                 {
  //                   text: "No",
  //                   value: false,
  //                 },
  //               ]}
  //               validators={[new RequiredValidator("Value is required")]}
  //             />
  //           </div>
  //         );
  //       })}
  //     </div>
  //     <div className="m-bottom-40">
  //       <div className="m-top-30">
  //         <AnimateHeight name={isError ? "error" : ""}>
  //           <div>{isError ? <GenericError /> : <div />}</div>
  //         </AnimateHeight>
  //       </div>
  //     </div>
  //     <StoryContinueButton type="submit" isLoading={isLoading}>
  //       <T>Continue</T>
  //     </StoryContinueButton>
  //   </Form>
  // );
};
