import { motion } from "framer-motion";
import { FunctionComponent, PropsWithChildren, ReactNode, useRef } from "react";
import { FaChevronLeft, FaTimes } from "react-icons/fa";
import { Routes, useLocation, useNavigate } from "react-router-dom";
import { LogoVertical } from "../images/LogoVertical";
import styles from "./Story.module.scss";
import { useStoryValues } from "./useStoryValues";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import React from "react";

interface Props {
  headerText: string | ReactNode;
  routes: Record<string, string>;
  baseRoute: string;
  showBack?: boolean;
  showClose?: boolean;
  onBack?: () => void;
  onClose?: () => void;
}

export interface StoryStepProps {
  next: () => void;
  pageRef: React.RefObject<HTMLDivElement>;
}

type AnimationDirection = "forward" | "backward";

export const Story: FunctionComponent<PropsWithChildren<Props>> = ({
  headerText,
  baseRoute,
  routes,
  showBack = false,
  showClose = false,
  onBack,
  onClose,
  children,
}) => {
  const navigate = useNavigate();
  const [index, progress, prevRoute] = useStoryValues(baseRoute, routes);
  const location = useLocation();
  const lastIndex = useRef(index);
  const animationDirection = useRef<AnimationDirection>("forward");
  const showBackButton = showBack && index > 0;
  const showCloseButton = showClose && onClose;

  if (lastIndex.current < index) {
    animationDirection.current = "forward";
  } else if (lastIndex.current > index) {
    animationDirection.current = "backward";
  }

  if (lastIndex.current !== index) {
    lastIndex.current = index;
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.headerWrapper}>
        <div className={styles.header}>
          <div className={styles.buttonWrapper}>
            {showBackButton && (
              <button
                onClick={() => {
                  onBack ? onBack() : navigate(prevRoute);
                }}
              >
                <FaChevronLeft />
              </button>
            )}
          </div>
          <div className={styles.title}>
            <h2 className={styles.titleText}>{headerText}</h2>
          </div>
          <div className={styles.buttonWrapper}>
            {showCloseButton ? (
              <button onClick={onClose}>
                <FaTimes />
              </button>
            ) : (
              <LogoVertical />
            )}
          </div>
          <motion.span
            className={styles.progressBar}
            animate={{ width: `${progress}%` }}
            transition={{ duration: 0.75, easings: "easeIn" }}
          />
        </div>
      </div>
      <div className={styles.contentWrapper}>
        <TransitionGroup
          component={TransitionWrapper}
          childFactory={(child) =>
            React.cloneElement(child, {
              classNames: {
                enterActive:
                  animationDirection.current === "forward"
                    ? styles.forwardEnterActive
                    : styles.backwardEnterActive,
                exitActive:
                  animationDirection.current === "forward"
                    ? styles.forwardExitActive
                    : styles.backwardExitActive,
              },
            })
          }
        >
          <CSSTransition key={location.pathname} timeout={500}>
            <Routes location={location}>{children}</Routes>
          </CSSTransition>
        </TransitionGroup>
      </div>
    </div>
  );
};

const TransitionWrapper: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  return <div className={styles.content}>{children}</div>;
};
