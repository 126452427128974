import { FunctionComponent, useMemo } from "react";
import styles from "./AssociateCard.module.scss";
import cx from "classnames";
import { T } from "../../../../components/translation/T";
import { AssociateRole } from "../../../../data/dataMerchant";
import { Badge } from "../../../../components/badges/Badge";

interface Props {
  name: string;
  roles: (AssociateRole | NoAssociateRole)[];
  isSelected: boolean;
  onClick: () => void;
}

export const AssociateCard: FunctionComponent<Props> = ({
  name,
  roles,
  isSelected,
  onClick,
}) => {
  const btnClass = cx(styles.radioBtn, {
    [styles.btnSelected]: isSelected,
  });

  const associateClass = cx(styles.associate, {
    [styles.associateSelected]: isSelected,
  });

  const nameClass = cx(styles.nameText, {
    [styles.nameTextSelected]: isSelected,
  });

  const roleClass = cx(styles.roleText, {
    [styles.roleTextSelected]: isSelected,
  });

  const isCurrentAccountHolder = roles.includes(AssociateRole.ACCOUNT_HOLDER);

  const filteredAndSortedRoles = useMemo(
    () =>
      sortRoles(
        ...roles.filter((role) => role !== AssociateRole.ACCOUNT_HOLDER)
      ),
    [roles]
  );

  return (
    <div className={associateClass} onClick={onClick}>
      <div className={styles.radioBtnCol}>
        <div className={btnClass} />
      </div>
      <div className={styles.infoCol}>
        <div className={nameClass}>{name}</div>
        {isCurrentAccountHolder && (
          <Badge
            truncate
            icon={false}
            className={cx(styles.badge, {
              [styles.badgeInactive]: !isSelected,
            })}
          >
            <T>Current account holder</T>
          </Badge>
        )}
        <span className={roleClass}>
          {filteredAndSortedRoles.map((role, index) => (
            <div key={index} className={styles.role}>
              <T>{`${ROLES_MAPPING[role]}`}</T>
            </div>
          ))}
        </span>
      </div>
    </div>
  );
};

function sortRoles(...roles: (AssociateRole | NoAssociateRole)[]) {
  return roles.sort((a, b) => {
    if (a === AssociateRole.ACCOUNT_HOLDER) return -1;
    else if (b === AssociateRole.ACCOUNT_HOLDER) return 1;
    else if (a === AssociateRole.PRIMARY_CONTACT) return -1;
    else if (b === AssociateRole.PRIMARY_CONTACT) return 1;
    else if (a === AssociateRole.BENEFICIAL_OWNER) return -1;
    else if (b === AssociateRole.BENEFICIAL_OWNER) return 1;
    return 0;
  });
}

export enum NoAssociateRole {
  NO_ROLE = "NO_ROLE",
}

const ROLES_MAPPING: Record<AssociateRole | NoAssociateRole, string> = {
  [AssociateRole.PRIMARY_CONTACT]: "Primary contact",
  [AssociateRole.ACCOUNT_HOLDER]: "Account holder",
  [AssociateRole.SIGNATORY]: "Signatory",
  [AssociateRole.POWER_OF_ATTORNEY]: "Power of attorney",
  [AssociateRole.BENEFICIAL_OWNER]: "Beneficial owner",
  [AssociateRole.PORTAL_LOGIN]: "Portal login",
  [AssociateRole.STORE_CONTACT]: "Store contact",
  [AssociateRole.SELECTED_SIGNATORY]: "Selected signatory",
  [NoAssociateRole.NO_ROLE]:
    "Select this option if the account holder is not present above",
};
