import cx from "classnames";
import "./DataList.scss";

interface Props extends React.PropsWithChildren {
  type?: "vertical" | "horizontal";
  align?: "left" | "right" | "center";
  small?: boolean;
  ratio?: number;
}

export const DataList: React.FunctionComponent<Props> = ({
  children,
  type = "horizontal",
  align = "right",
  small = true,
  ratio = 1,
}) => {
  let dimensions = "1fr 1fr";
  if (ratio < 1) {
    dimensions = `1fr ${1 / ratio}fr`;
  }

  if (ratio > 1) {
    dimensions = `${ratio}fr 1fr`;
  }

  return (
    <dl
      style={{
        gridTemplateColumns: dimensions,
      }}
      className={cx("data-list", {
        "--vertical": type === "vertical",
        "--horizontal": type === "horizontal",
        "--left": align === "left",
        "--right": align === "right",
        "--center": align === "center",
        "--small": small,
      })}
    >
      {children}
    </dl>
  );
};
