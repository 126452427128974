import React, { ReactNode, RefObject, useCallback, useRef } from "react";
import cx from "classnames";
import { Status } from "../../../data/types";
import { useForm } from "../../form/hooks/useForm";
import { useValidation, ValidationProps } from "../../form/hooks/useValidation";
import { id } from "../../utils";
import { RequiredValidatorName } from "../../form/validators/RequiredValidator";
import { createTranslation, T } from "../../translation/T";
import "./ButtonPaneInput.scss";

export interface ButtonInPaneProps<T> {
  data?: T;
  text: string | ReactNode;
  active?: boolean;
}

interface Props<T> extends ValidationProps {
  disabled?: boolean;
  onChange: (
    data: T | undefined,
    event: React.MouseEvent<HTMLButtonElement>
  ) => void;
  status?: Status;
  buttons: ButtonInPaneProps<T>[];
  className?: string;
  scrollToRef?: RefObject<HTMLElement>;
  value?: T;
  label?: string;
  small?: boolean;
}

function translateOrEmpty(value?: string | React.ReactNode) {
  if (!value) {
    return <div className="invisible">empty</div>;
  }
  return createTranslation(value);
}

export function ButtonPaneWithLabel<T>({ ...props }: Props<T>) {
  const {
    buttons,
    onChange,
    className,
    value,
    scrollToRef,
    label,
    validators = [],
    forceValidation = false,
    disabled = false,
    small = false,
  } = props;

  const identifier = useRef<string>(id());
  const ref = useRef<HTMLLabelElement>(null);
  const innerValidators = disabled ? [] : validators;
  const inputId = useRef(
    "select_" + Math.random().toString(36).substring(2, 9)
  );
  const [validity, errorMessages, resetValidation, status] = useValidation<T>(
    value,
    innerValidators,
    forceValidation
  );
  useForm(
    inputId.current,
    validity,
    value,
    resetValidation,
    scrollToRef ?? ref
  );

  const click = useCallback(
    (
      button: ButtonInPaneProps<T>,
      event: React.MouseEvent<HTMLButtonElement>
    ) => onChange(button.data, event),
    [onChange]
  );

  if (!buttons.length) {
    return null;
  }

  const isRequired = innerValidators.some(
    (validator) => validator.name === RequiredValidatorName
  );

  return (
    <label
      className={cx("input", "button-pane-input", className, status, {
        small,
      })}
      htmlFor={identifier.current}
      ref={ref}
    >
      <div className="row-wrapper">
        <div className="input-label-wrapper">
          <div className="input-label">
            <div className="input-label-tag">{translateOrEmpty(label)}</div>
          </div>
          {typeof value === "undefined" && isRequired && (
            <div className="required-marker text-small">
              <T>required</T>
            </div>
          )}
        </div>
        <div
          className={cx("input-frame", {
            small,
          })}
        >
          {buttons.map((button) => {
            return (
              <button
                type="button"
                onClick={(event) => click(button, event)}
                disabled={disabled || status === Status.DISABLED}
                key={button.text?.toString()}
                className={cx({
                  active: button.active,
                })}
              >
                <span>{createTranslation(button.text)}</span>
              </button>
            );
          })}
        </div>
      </div>
      <div className="input-messages">
        {/* <div className="input-hint">{hint}</div> */}
        <div className="input-message">
          {errorMessages.length > 0 ? errorMessages[0] : null}
        </div>
      </div>
    </label>
  );
}
