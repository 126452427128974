import { API } from "../network/API";
import { Country, LinkId } from "./models/ContractTypes";
import { Access, getProxy } from "./proxy";

const BASE_PATH = "/api/merchant";

export interface LocationSuggestionResult {
  text: string;
}

export interface LocationLookupResult {
  streetNumber?: string;
  country?: string;
  postalCode?: string;
  street?: string;
  city?: string;
  countryCode?: Country;
}

enum WhitelistedEndpoint {
  GET_LOCATION = "getLocation",
  GET_SUGGESTIONS = "getSuggestions",
}

export const LocationLookup = {
  [WhitelistedEndpoint.GET_SUGGESTIONS]: (linkId: LinkId, text: string) =>
    API.post<LocationSuggestionResult[]>(
      `${BASE_PATH}/${linkId}/location/suggestions`,
      {
        text,
      }
    ),
  [WhitelistedEndpoint.GET_LOCATION]: (linkId: LinkId, text: string) =>
    API.post<LocationLookupResult>(`${BASE_PATH}/${linkId}/location/lookup`, {
      text,
    }),
};

let locationLookup: typeof LocationLookup;

export function dataLocationLookup(access: Access) {
  if (!locationLookup) {
    locationLookup = getProxy(
      LocationLookup,
      access,
      Object.values(WhitelistedEndpoint)
    );
  }

  return locationLookup;
}
