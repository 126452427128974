import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import { API } from "./network/API";
import { Country, Currency, Language } from "./data/models/ContractTypes";

const SENT_CACHE = new Set<string>();

export type TI = any;

export enum TRANSLATION_NAMESPACE {
  SALES = "sales",
  MERCHANT = "merchant",
  MCC = "mcc",
}

export const DEFAULT_TRANSLATION_NAMESPACE = TRANSLATION_NAMESPACE.MERCHANT;

export const CurrencyByCountry: Record<Country, Currency> = {
  [Country.SWEDEN]: Currency.SWEDEN,
  [Country.DENMARK]: Currency.DENMARK,
  [Country.NORWAY]: Currency.NORWAY,
  [Country.FINLAND]: Currency.FINLAND,
  [Country.GB]: Currency.GB,
};

const HttpApi = new Backend(null, {
  loadPath: API.getUrl("/translation/{{lng}}/{{ns}}.json"),
  allowMultiLoading: false,
  crossDomain: true,
  withCredentials: true,
  requestOptions: {
    mode: "cors",
    credentials: "include",
  },
});

i18n
  .use(HttpApi)
  .use(initReactI18next)
  /**
   * Let's try this custom config to utilize caching of selected language
   * Should probably find a better solution for this
   */
  .use(
    new LanguageDetector(undefined, {
      order: ["localStorage"],
    })
  )
  .init({
    debug: false,
    // Since our translation keys are the translation in english, we should not need a fallback
    fallbackLng: Language.ENGLISH,
    ns: [DEFAULT_TRANSLATION_NAMESPACE, TRANSLATION_NAMESPACE.MCC],
    defaultNS: DEFAULT_TRANSLATION_NAMESPACE,
    interpolation: {
      escapeValue: false,
    },
    saveMissing: true,
    saveMissingTo: "current",
    load: "languageOnly",
    missingKeyHandler: (lng, ns, key) => {
      const language = lng[0];

      if (language === Language.ENGLISH) {
        return;
      }

      const cacheKey = language + ns + key;
      if (SENT_CACHE.has(cacheKey)) {
        return;
      }
      SENT_CACHE.add(cacheKey);
      fetch(API.getUrl(`/translation/${ns}.json`), {
        method: "POST",
        mode: "cors",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          key: key,
        }),
      }).catch(() => {
        console.warn("Couldnt send missing translation [" + key + "]");
      });
    },
  });

export default i18n;
