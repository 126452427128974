import React from "react";
import { Contract } from "../../../data/dataMerchant";
import { allowForTof } from "./Financials";
import { TextInput } from "../../../components/form/TextInput";
import { RequiredValidator } from "../../../components/form/validators/RequiredValidator";
import styles from "./Financials.module.scss";
import { Psp } from "../../../data/dataBusinessRisk";

interface Props {
  contract: Contract;
  setTof: (value: string) => void;
  tof: string;
  businessRisk: Psp;
}

export const Tof: React.FunctionComponent<Props> = ({
  contract,
  businessRisk,
  tof,
  setTof,
}) => {
  if (!allowForTof(contract, businessRisk)) {
    return null;
  }

  return (
    <div className={styles.amex}>
      <TextInput
        label="Enter DanCard integration number"
        validators={[new RequiredValidator("Integration number is required")]}
        value={tof}
        onChange={(value) => setTof(value)}
      />
    </div>
  );
};
