import cx from "classnames";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { AnimateHeight } from "../../../components/animate/AnimateHeight";
import { GenericError } from "../../../components/Errors/GenericError";
import { Form } from "../../../components/form/Form";
import { HiddenInput } from "../../../components/form/HiddenInput";
import { TextInput } from "../../../components/form/TextInput";
import { RequiredValidator } from "../../../components/form/validators/RequiredValidator";
import { StoryStepProps } from "../../../components/story/Story";
import { StoryContinueButton } from "../../../components/story/StoryContinueButton";
import { T } from "../../../components/translation/T";
import {
  FileUpload,
  getUploadBaseUrl,
} from "../../../components/uploads/FileUpload";
import { useLinkId } from "../../../hooks/useLinkId";
import { useSuspendedQuery } from "../../../hooks/useSuspendedQuery";
import { useRecoilValue } from "recoil";
import { dataStores } from "../../../data/dataStores";
import { contractState } from "../../../state/contractState";
import { Products, Product } from "../../../components/products/Products";
import { Country, ProductType } from "../../../data/models/ContractTypes";
import { useTranslation } from "react-i18next";
import { MaxLengthValidator } from "../../../components/form/validators/MaxLengthValidator";
import { SelfDeclaration } from "../../../components/selfDeclaration/SelfDeclaration";
import { AppStore } from "../../../data/dataMerchant";
import { CountryChip } from "../../../components/chip/CountryChip";
import { Dropdown } from "../../../components/interactions/Dropdown/Dropdown";
import { getCountryOpts } from "../../../components/utils";
import { Status } from "../../../data/types";
import styles from "./AppInformation.module.scss";
import { useAccess } from "../../../hooks/useAccess";
import { Access } from "../../../data/proxy";

const APP_NAME_MAX_LENGTH = 30;
const MAX_NUMBER_OF_POSSIBLE_COUNTRIES = 5;

export const AppInformation: React.FunctionComponent<StoryStepProps> = ({
  next,
}) => {
  const linkId = useLinkId();
  const contract = useRecoilValue(contractState);
  const { t, i18n } = useTranslation();
  const access = useAccess();
  const { data: store } = useSuspendedQuery(
    dataStores(access).fetchEcomStore(linkId)
  );
  const [ecomStore, setEcomStore] = useState<AppStore>({
    app: {
      name: "",
      testName: "",
      screenshotUploaded: false,
    },
    ...store,
  });
  const queryClient = useQueryClient();
  const ref = useRef<HTMLFormElement>(null);

  useEffect(() => {
    // App name may not yet be saved. Replace file info only.
    setEcomStore((prev) => {
      return {
        ...prev,
        app: {
          ...prev.app,
          screenshotUploaded: !!store.app?.screenshotUploaded,
        },
      };
    });
  }, [store]);

  const invalidateCache = useCallback(() => {
    queryClient.invalidateQueries(dataStores(access).getEcomStoresKey(linkId));
  }, [linkId, queryClient, access]);

  const {
    mutate: onSave,
    isLoading,
    isError,
  } = useMutation(
    () => {
      return dataStores(access).saveEcomStore(linkId, {
        ...ecomStore,
        app: { ...ecomStore.app, testName: ecomStore.app.testName || "" },
      });
    },
    {
      onSuccess: () => {
        invalidateCache();
        next();
      },
    }
  );

  const setApp = useCallback((value: string, name: string) => {
    setEcomStore((prev) => ({
      ...prev,
      app: {
        ...prev.app,
        [name]: value,
      },
    }));
  }, []);

  const numberOfCountries =
    (ecomStore.mainCountriesOfOperation || []).length ?? 0;

  const countries = useMemo(
    () =>
      getCountryOpts(
        t,
        i18n.language,
        false,
        undefined,
        undefined,
        ecomStore.mainCountriesOfOperation
      ),
    [ecomStore.mainCountriesOfOperation, t, i18n.language]
  );

  const onChange = useCallback((value: string) => {
    setEcomStore((prev) => ({
      ...prev,
      mainCountriesOfOperation: [
        ...(prev.mainCountriesOfOperation || []),
        value,
      ],
    }));
  }, []);

  const onRemove = useCallback((value: string) => {
    setEcomStore((prev) => ({
      ...prev,
      mainCountriesOfOperation: (prev.mainCountriesOfOperation || []).filter(
        (country) => country !== value
      ),
    }));
  }, []);

  return (
    <>
      <Form
        className="flex-1"
        ref={ref}
        onSubmit={(_, form) => {
          if (!form.isValid) {
            return;
          }
          onSave();
        }}
      >
        <h2>
          <T>App information</T>
        </h2>
        <div className="tablet-columns">
          <div className="m-top-20">
            <TextInput
              disabled={isLoading}
              name="name"
              label="App name"
              value={ecomStore.app.name}
              onChange={setApp}
              validators={[
                new RequiredValidator("App name is required"),
                new MaxLengthValidator(
                  APP_NAME_MAX_LENGTH,
                  `Maximum length is ${APP_NAME_MAX_LENGTH} characters`
                ),
              ]}
            />
          </div>
          <div className="m-top-20">
            <TextInput
              disabled={isLoading}
              name="testName"
              label="Test app name"
              value={ecomStore.app.testName}
              onChange={setApp}
              validators={[
                new MaxLengthValidator(
                  APP_NAME_MAX_LENGTH,
                  `Maximum length is ${APP_NAME_MAX_LENGTH} characters`
                ),
              ]}
            />
          </div>
        </div>

        <Products productType={contract.productType}>
          <Product
            products={[ProductType.ACCEPTANCE_ONLINE, ProductType.CHECKOUT]}
          >
            <div className="m-top-30">
              <Dropdown
                required={
                  ecomStore.mainCountriesOfOperation?.length ? false : true
                }
                onChange={onChange}
                name="mainCountriesOfOperation"
                label="Main countries in which the company operates (20% or more of company turnover)"
                className={cx("compact", {
                  "was-successful":
                    numberOfCountries > 0 &&
                    numberOfCountries !== MAX_NUMBER_OF_POSSIBLE_COUNTRIES,
                })}
                alternatives={countries}
                value=""
                status={
                  numberOfCountries === MAX_NUMBER_OF_POSSIBLE_COUNTRIES
                    ? Status.DISABLED
                    : Status.DEFAULT
                }
              />
            </div>

            <HiddenInput
              label="mainCountriesOfOperation_validation"
              value={numberOfCountries > 0 ? true : undefined}
              validators={[
                new RequiredValidator("You must select at least one country"),
              ]}
              scrollToRef={ref}
            />

            <div className={styles.chips}>
              {(ecomStore.mainCountriesOfOperation || []).map((citizenship) => {
                return (
                  <CountryChip
                    key={citizenship}
                    country={citizenship as Country}
                    onRemove={onRemove}
                    data={citizenship}
                  />
                );
              })}
            </div>
          </Product>
        </Products>

        <div className={cx("m-top-40", styles.upload)}>
          <T>We also need you to upload a screenshot of your app.</T>

          <div className="m-top-10">
            <FileUpload
              endpoint={`${getUploadBaseUrl()}/api/merchant/${linkId}/ecomstore/appscreen`}
              accept="image/*"
              color="#f5f6fa"
              onUpload={invalidateCache}
              onRemove={invalidateCache}
              disabled={Access.VIEW === access}
              buttonClasses="ghost"
              initialFile={
                ecomStore.app.screenshotUploaded
                  ? {
                      name: t("Uploaded file"),
                      type: "",
                    }
                  : undefined
              }
            />
          </div>
        </div>

        <div className="m-top-10">
          <HiddenInput
            label="Completed app"
            value={ecomStore.app.screenshotUploaded ? true : undefined}
            validators={[
              new RequiredValidator(
                "A screenshot of your application must be uploaded"
              ),
            ]}
            scrollToRef={ref}
          />
        </div>

        <Products productType={contract.productType}>
          <Product products={[ProductType.ACCEPTANCE_ONLINE]}>
            <hr />
            <SelfDeclaration
              setAppStore={setEcomStore}
              ecomStore={ecomStore}
              formRef={ref}
            />
          </Product>
        </Products>

        <div className="m-top-30">
          <AnimateHeight name={isError ? "error" : ""}>
            <div>{isError ? <GenericError /> : <div />}</div>
          </AnimateHeight>
        </div>

        <div className="m-top-30">
          <StoryContinueButton
            disabled={access === Access.VIEW}
            type="submit"
            isLoading={isLoading}
          >
            <T>Continue</T>
          </StoryContinueButton>
        </div>
      </Form>
    </>
  );
};
