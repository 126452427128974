import { useState } from "react";
import { Form } from "../../../../../components/form/Form";
import { TextInput } from "../../../../../components/form/TextInput";
import { MaxLengthValidator } from "../../../../../components/form/validators/MaxLengthValidator";
import { MinLengthValidator } from "../../../../../components/form/validators/MinLengthValidator";
import { RequiredValidator } from "../../../../../components/form/validators/RequiredValidator";
import { Button } from "../../../../../components/interactions/Buttons/Button";
import { T } from "../../../../../components/translation/T";
import { Pep } from "../../../../../data/dataMerchant";

interface Props {
  pep: Pep;
  onChange: (pep: Pep) => void;
  onSubmit: () => void;
  onBack: () => void;
  isViewer: boolean;
}

const MIN_LENGTH = 2;
const MAX_LENGTH = 30;

export const PepRelativeName: React.FunctionComponent<Props> = ({
  pep,
  onChange,
  onSubmit,
  onBack,
}) => {
  const [values, setValues] = useState({ ...pep });

  return (
    <div className="m-top-30">
      <Form
        name="pepRelative"
        onSubmit={(_, form) => {
          if (form.isInvalid) {
            return;
          }

          onChange({
            ...pep,
            ...values,
            type: pep.type, // if user has added a type but backs up,
            // the incorrect type is stored in the state. It is okey to
            // cache/reuse the other values, but, needless to say, not the type
          });
          onSubmit();
        }}
      >
        <div className="m-top-20">
          <TextInput
            name="firstName"
            label="First name of relative"
            value={values.firstName}
            validators={[
              new RequiredValidator("Required"),
              new MinLengthValidator(
                MIN_LENGTH,
                "At least two characters are required"
              ),
              new MaxLengthValidator(
                MAX_LENGTH,
                "Not more than 30 characters are allowed"
              ),
            ]}
            onChange={(value) => {
              setValues({ ...values, firstName: value });
            }}
          />
        </div>
        <div className="m-top-20">
          <TextInput
            name="lastName"
            label="Last name of relative"
            value={values.lastName}
            validators={[
              new RequiredValidator("Required"),
              new MinLengthValidator(
                MIN_LENGTH,
                "At least two characters are required"
              ),
              new MaxLengthValidator(
                MAX_LENGTH,
                "Not more than 30 characters are allowed"
              ),
            ]}
            onChange={(value) => {
              setValues({ ...values, lastName: value });
            }}
          />
        </div>
        <div className="m-top-30 columns">
          <div>
            <Button onClick={onBack} ghost>
              <T>Back</T>
            </Button>
          </div>
          <div className="text-right">
            <Button type="submit">
              <T>Next</T>
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
};
