import React from "react";
import cx from "classnames";
import { Image } from "../../components/images/Image";

interface Props {
  className?: string;
}

export const Logo: React.FunctionComponent<Props> = ({ className }) => {
  return (
    <div className={cx("logo", className)}>
      <Image
        src="/images/Worldline-Mint-Horizontal.svg"
        ratio={811 / 143}
        alt="Worldline logo"
      />
    </div>
  );
};
