import styles from "./Intro.module.scss";
import "./Intro.scss";
import { T } from "../../../components/translation/T";
import { TI } from "../../../i18n";
import { Trans, useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue } from "recoil";
import { StoryStepProps } from "../../../components/story/Story";
import { dataAssociates } from "../../../data/dataAssociates";
import { useLinkId } from "../../../hooks/useLinkId";
import { useSuspendedQuery } from "../../../hooks/useSuspendedQuery";
import { contractState } from "../../../state/contractState";
import { CompanyInfo } from "./components/CompanyInfo";
import { Offering } from "./components/Offering/Offering";
import { SalesContact } from "./components/SalesContact/SalesContact";
import { MultipleStores } from "./components/Stores/MultipleStores";
import { useViewerAsAssociate } from "../../../hooks/useViewerAsAssociate";
import { useState } from "react";
import { SaveAddress, dataMerchant } from "../../../data/dataMerchant";
import { LanguageSwitch } from "../../../components/languageSwitch/LanguageSwitch";
import { Form } from "../../../components/form/Form";
import { useMutation } from "@tanstack/react-query";
import { AnimateHeight } from "../../../components/animate/AnimateHeight";
import { GenericError } from "../../../components/Errors/GenericError";
import { StoryContinueButton } from "../../../components/story/StoryContinueButton";
import { routeState } from "../../../state/routeState";
import { Access } from "../../../data/proxy";

export const Intro: React.FunctionComponent<StoryStepProps> = ({ next }) => {
  const { t } = useTranslation();
  const linkId = useLinkId();
  const [contract, setContract] = useRecoilState(contractState);
  const { access } = useRecoilValue(routeState);

  const { data: associates } = useSuspendedQuery(
    dataAssociates(access).fetchAssociates(linkId)
  );

  const viewer = useViewerAsAssociate(associates, contract.contractViewer);
  const viewerName = `${viewer?.contact.firstName} ${viewer?.contact.lastName}`;
  const { companyName } = contract.contractData;
  const [address, setAddress] = useState<SaveAddress>({
    companyName: contract.contractData.companyName,
    address: contract.contractData.address,
    website: contract.contractData.website,
    contactPhoneNumber:
      contract.contractData.contactPhoneNumber || viewer?.contact.phoneNumber,
  });

  const {
    mutate: onSave,
    isLoading: disabled,
    isError,
  } = useMutation(() => dataMerchant(access).saveAddress(linkId, address), {
    onSuccess: () => {
      setContract((prev) => ({
        ...prev,
        contractData: {
          ...prev.contractData,
          companyName: address.companyName,
          address: address.address,
          website: address.website,
          contactPhoneNumber: address.contactPhoneNumber,
        },
      }));
      next();
    },
  });

  return (
    <>
      <LanguageSwitch />
      <h2 className="story-header">
        <T id="Hi {{ viewerName }}!" options={{ viewerName }} />
      </h2>

      <p>
        <Trans t={t}>
          We're pleased that you've chosen Worldline as your payment service.
          This is the information we have put together about{" "}
          <strong>{{ companyName } as TI}</strong>. We would like you to confirm
          that it is correct.
        </Trans>
      </p>

      <SalesContact />

      <Form
        name="intro"
        className="intro"
        onSubmit={(_, form) => {
          if (!form.isValid) {
            return;
          }

          onSave();
        }}
      >
        <div className={styles.cards}>
          <CompanyInfo address={address} onChange={setAddress} />

          <Offering />

          <MultipleStores contract={contract} />
        </div>

        <SalesContact />

        <div className="m-bottom-30">
          <AnimateHeight name={isError ? "error" : ""}>
            <div>{isError ? <GenericError /> : <div />}</div>
          </AnimateHeight>
        </div>

        <StoryContinueButton
          type="submit"
          disabled={access === Access.VIEW}
          isLoading={disabled}
        >
          <T>Continue</T>
        </StoryContinueButton>
      </Form>
    </>
  );
};
