import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { Checkboxes } from "../../../components/interactions/Checkboxes/Checkboxes";
import {
  Country,
  Language,
  ProductType,
} from "../../../data/models/ContractTypes";
import { Contract } from "../../../data/dataMerchant";
import { Link } from "../../../components/link/Link";
import { T } from "../../../components/translation/T";
import { svLinks } from "./productLinks/sv";
import { dkLinks } from "./productLinks/dk";
import { noLinks } from "./productLinks/no";
import { fiLinks } from "./productLinks/fi";

export type TermDoc = {
  name: string;
  link: string;
};

export type ProductByLanguage = Record<ProductType, TermDoc[]>;

interface Props {
  acceptedTerms: boolean;
  setAcceptedTerms: (acceptedTerms: boolean) => void;
  disabled?: boolean;
  contract: Contract;
  contractIncludesDcc: boolean;
}

function getToCLinkByLanguage(lang: Language, country: Country) {
  // fugly, missing for english, default to country
  if (lang === Language.ENGLISH) {
    if (country === Country.SWEDEN) {
      return termsAndConditionLinks[Language.SWEDISH];
    }

    if (country === Country.DENMARK) {
      return termsAndConditionLinks[Language.DANISH];
    }

    if (country === Country.NORWAY) {
      return termsAndConditionLinks[Language.NORWEGIAN];
    }

    if (country === Country.FINLAND) {
      return termsAndConditionLinks[Language.FINNISH];
    }

    return termsAndConditionLinks[Language.SWEDISH];
  }

  return termsAndConditionLinks[lang];
}

const dccLinks: Record<Language, TermDoc[]> = {
  [Language.SWEDISH]: [
    // {
    //   name: "Dynamisk valutaväxling (DCC)",
    //   link: "https://support.worldline.com/content/dam/worldline/local/sv-se/documents/dynamisk-valutavaxling-dcc-foreskrifter_2024.pdf",
    // },
  ],
  [Language.DANISH]: [],
  [Language.NORWEGIAN]: [],
  [Language.FINNISH]: [],
  [Language.ENGLISH]: [],
};

const termsAndConditionLinks: Record<Language, string> = {
  [Language.SWEDISH]:
    "https://support.worldline.com/sv-se/home/merchants/agreements/terms-and-conditions.html",
  [Language.DANISH]:
    "https://support.worldline.com/da-dk/home/merchants/agreements/terms-and-conditions.html",
  [Language.NORWEGIAN]:
    "https://support.worldline.com/no-no/home/merchants/agreements/terms-and-conditions.html",
  [Language.FINNISH]:
    "https://support.worldline.com/fi-fi/home/merchants/agreements/terms-and-conditions.html",
  [Language.ENGLISH]: "https://www.bambora.com/privacy-policy/",
};

const gdprLinks: Record<Language, string> = {
  [Language.SWEDISH]: "https://worldline.com/sv-se/compliancy/privacy.html",
  [Language.DANISH]: "https://worldline.com/da-dk/compliancy/privacy.html",
  [Language.NORWEGIAN]: "https://worldline.com/no-no/compliancy/privacy.html",
  [Language.FINNISH]: "https://worldline.com/fi-fi/compliancy/privacy.html",
  [Language.ENGLISH]: "https://worldline.com/en/compliancy/privacy.html",
};

const productLinks: Record<Language, ProductByLanguage> = {
  [Language.SWEDISH]: svLinks,
  [Language.DANISH]: dkLinks,
  [Language.NORWEGIAN]: noLinks,
  [Language.FINNISH]: fiLinks,
  [Language.ENGLISH]: svLinks, // Agreement is in English anyway but we
  // don't have specific terms for the UK so default to the equivalent
};

function getProductTermsByProductAndLanguage(
  product: ProductType,
  language: Language
): TermDoc[] {
  return productLinks[language][product];
}

export const TermsAndConditions: React.FunctionComponent<Props> = ({
  acceptedTerms,
  setAcceptedTerms,
  disabled,
  contract,
  contractIncludesDcc,
}) => {
  let terms = getProductTermsByProductAndLanguage(
    contract.productType,
    contract.contractViewer.language
  );
  const { t } = useTranslation();

  if (contractIncludesDcc) {
    terms = [...dccLinks[contract.contractViewer.language], ...terms];
  }

  return (
    <>
      <h5>
        <T>Privacy policy</T>
      </h5>
      <p className="text-small">
        <Trans t={t}>
          When you become a customer of Worldline, Worldline will start
          processing your personal data, in order to give you or your company
          access to our various payment solutions. You can read more about our
          processing of{" "}
          <Link external link={gdprLinks[contract.contractViewer.language]}>
            personal data here
          </Link>
        </Trans>
        .
      </p>
      <div className="m-top-40">
        <h5>
          <T>Terms & conditions</T>
        </h5>
        <p className="text-small">
          <Trans t={t}>
            This application form along with the conditions and regulations
            available at{" "}
            <Link
              external
              link={getToCLinkByLanguage(
                contract.contractViewer.language,
                contract.contractData.country
              )}
            >
              worldline.com
            </Link>{" "}
            constitutes the entire agreement. Signing this agreement will
            confirm that we have accepted these conditions.
          </Trans>
          <br />
          <Trans t={t}>
            We confirm that the information we have provided in this application
            is complete and correct. We accept credit information can be
            obtained and that the application may be rejected without further
            justification.
          </Trans>
        </p>
        <ul className="m-top-40">
          {terms.map((item) => (
            <li key={item.name} className="m-bottom-10">
              <Link external link={item.link}>
                <T>{item.name}</T>
              </Link>
            </li>
          ))}
        </ul>
      </div>

      <div className="m-top-10">
        <Checkboxes
          label=""
          values={acceptedTerms ? ["true"] : []}
          onChange={() => setAcceptedTerms(!acceptedTerms)}
          disabled={disabled}
          className="compact"
          alternatives={[
            {
              value: "true",
              text: <Trans t={t}>I accept terms and conditions</Trans>,
            },
          ]}
        />
      </div>
    </>
  );
};
