import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react";
import cx from "classnames";
import { Associate } from "../../../../data/dataMerchant";
import { EditRow } from "../../../../components/editRow/EditRow";
import styles from "./SignatoryOverlay.module.scss";
import { PersistentOverlay } from "../../../../components/overlay/PersistantOverlay";
import { Steps } from "../../../../components/steps/Steps";
import { AssociateIdFields } from "./forms/AssociateIdFields";
import { Badge } from "../../../../components/badges/Badge";
import { T } from "../../../../components/translation/T";
import { AssociateContactFields } from "./forms/AssociateContactFields";
import {
  MultiForm,
  MultiFormContainer,
} from "../../../../components/form/MultiForm";
import { Form } from "../../../../components/form/Form";
import { HiddenInput } from "../../../../components/form/HiddenInput";
import { RequiredValidator } from "../../../../components/form/validators/RequiredValidator";
import { AnimateHeight } from "../../../../components/animate/AnimateHeight";
import { useLinkId } from "../../../../hooks/useLinkId";
import { dataAssociates } from "../../../../data/dataAssociates";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Done } from "./forms/Done";
import { AssociateId } from "../../../../data/models/ContractTypes";
import { routeState } from "../../../../state/routeState";
import { useRecoilValue } from "recoil";
import { addRolesToSignee } from "../signatoriesMissing/NewSignatory";

interface Props {
  signatory: Associate;
  onChange: (associate: Associate) => void;
  onRemove?: (associate: Associate) => void;
  /**
   * If true, the overlay will be rendered, but not visible.
   * This makes sure the form validation is in the dom always.
   * @default false
   * */
  render?: boolean;
  attorneyIds?: AssociateId[];
}

export const MIN_LENGTH = 2;
export const MAX_LENGTH = 30;

export const HEADERS = ["Name & contact", "Residence & citizenship", "Done"];

export const SignatoryOverlay: React.FunctionComponent<Props> = ({
  signatory,
  onChange,
  onRemove,
  render = false,
  attorneyIds,
}) => {
  const linkId = useLinkId();
  const [associate, setAssociate] = useState<Associate>(signatory);
  const [isOpen, setIsOpen] = useState(false);
  const [index, setIndex] = useState<number>(0);
  const { access } = useRecoilValue(routeState);
  const queryClient = useQueryClient();
  const formContainer = useRef<MultiFormContainer>();
  const ref = useRef<HTMLDivElement>(null);
  const indexRef = useRef<number>(0);
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    setAssociate(signatory);
  }, [signatory]);

  const fullName = useMemo(() => {
    if (!associate.contact?.firstName || !associate.contact?.lastName) {
      return <T>Missing name</T>;
    }

    return `${associate.contact.firstName} ${associate.contact.lastName}`;
  }, [associate.contact?.firstName, associate.contact?.lastName]);

  const internalRemove = useCallback(() => {
    const isNew = attorneyIds?.includes(associate.associateId);
    if (isNew) {
      onRemove && onRemove(associate);
      return Promise.resolve();
    } else {
      return dataAssociates(access)
        .removeAssociate(linkId, associate)
        .then(() => onRemove && onRemove(associate));
    }
  }, [linkId, onRemove, access, attorneyIds, associate]);

  const {
    mutate: onSave,
    isLoading: isSaving,
    isError: isSaveError,
    reset,
  } = useMutation(
    () => {
      const isNew = attorneyIds?.includes(associate.associateId);
      const updatedSignee = addRolesToSignee(associate);
      return dataAssociates(access).saveAssociate(
        linkId,
        {
          ...updatedSignee,
        },
        isNew
      );
    },
    {
      onSuccess: (data: Associate) => {
        onChange(data);
        queryClient.invalidateQueries(
          dataAssociates(access).getAssociatesKey(linkId)
        );
        close();
      },
    }
  );

  const close = useCallback(() => {
    setIsOpen(false);
    setTimeout(() => {
      setIndex(0);
      reset();
    }, 350);
  }, [reset]);

  const onRetry = useCallback(() => {
    reset();
    setTimeout(onSave, 300);
  }, [reset, onSave]);

  useEffect(() => {
    if (index >= indexRef.current) {
      indexRef.current = index;
      return;
    }

    // We're going back in the overlay
    // reset errors
    reset();
    indexRef.current = index;
  }, [index, reset]);

  return (
    <div className={cx(styles.signatory)} ref={ref}>
      <MultiForm
        multiFormContainer={formContainer}
        updateFormValidity={setIsFormValid}
      >
        <EditRow
          isValid={isFormValid}
          setIsOpen={setIsOpen}
          data={signatory}
          onRemove={onRemove ? internalRemove : undefined}
          confirmRemoveText={
            typeof fullName === "string" ? (
              <T
                id="Do you want to remove signatory {{name}}?"
                options={{
                  name: fullName,
                }}
              />
            ) : (
              <T>Do you want to remove this signatory?</T>
            )
          }
        >
          <div className={cx(styles.info)}>
            <div className={cx(styles.description)}>
              <b>{fullName}</b>
            </div>
            <AnimateHeight name={isFormValid.toString()}>
              {!isFormValid ? (
                <Badge truncate className="text-small">
                  <T>Please add information</T>
                </Badge>
              ) : (
                <div className="text-small">
                  <span>{associate.contact.position}</span>
                </div>
              )}
            </AnimateHeight>
          </div>
        </EditRow>
        <PersistentOverlay
          open={isOpen}
          local={true}
          onClose={close}
          className="full-height"
        >
          {(render || isOpen) && (
            <Steps
              index={index}
              header={HEADERS}
              length={HEADERS.length}
              insideOverlay
            >
              <AssociateContactFields
                associate={associate}
                onChange={(value) => {
                  setAssociate(value);
                }}
                setIndex={setIndex}
              />

              <AssociateIdFields
                associate={associate}
                onChange={(value) => {
                  setAssociate(value);
                }}
                setIndex={setIndex}
              />

              <Done
                index={index}
                setIndex={setIndex}
                onSave={onSave}
                onRetry={onRetry}
                isLoading={isSaving}
                isError={isSaveError}
              />
            </Steps>
          )}
        </PersistentOverlay>
      </MultiForm>

      <Form name={`signatory-${associate.associateId}`}>
        <HiddenInput
          label="Signatory information"
          value={isFormValid ? true : undefined}
          validators={[
            new RequiredValidator("Missing information about signatory"),
          ]}
          scrollToRef={ref}
        />
      </Form>
    </div>
  );
};
