import React, { ReactNode } from "react";
import cx from "classnames";
import styles from "./NumberedItem.module.scss";

interface Props {
  index: number;
  children: ReactNode;
  color?: string;
  className?: string;
}

export const NumberedItem: React.FunctionComponent<Props> = ({
  index,
  children,
  color = "#f5f6fa",
  className,
}) => {
  return (
    <div className={cx(styles.row, className)}>
      <b
        className={cx("text-large", styles.indicator)}
        style={{
          backgroundColor: color,
        }}
      >
        {index}
      </b>
      <div className={styles.body}>{children}</div>
    </div>
  );
};
