import { ValidationResponse, VALIDATION_STATE } from "../hooks/useValidation";
import { BaseValidator } from "./BaseValidator";

export function getDecimalSeparator(locale?: string) {
  return (
    Intl.NumberFormat(locale)
      .formatToParts(1.1)
      .find((part) => part.type === "decimal")?.value ?? "."
  );
}

export class GreaterThanValidator extends BaseValidator {
  name = "GreaterThanValidator";
  private referenceValue: number;
  private separator: string;

  constructor(referenceValue: number, error: string, locale?: string) {
    super(error);
    this.referenceValue = referenceValue;
    this.separator = getDecimalSeparator(locale);
  }

  public getId() {
    return this.error + this.name + this.referenceValue;
  }

  validate(value: number | string): Promise<ValidationResponse> {
    return new Promise<ValidationResponse>((resolve) => {
      const val =
        typeof value === "string" ? value.replace(this.separator, ".") : value;
      const num = Number(val);

      if (isNaN(num) || num > this.referenceValue) {
        resolve({ status: VALIDATION_STATE.SUCCESS });
      } else {
        resolve({
          status: VALIDATION_STATE.FAILED,
          message: this.error,
        });
      }
    });
  }
}
