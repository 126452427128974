import React from "react";
import { Address } from "../../../../../components/address/Address";
import { T } from "../../../../../components/translation/T";
import { Store as StoreInterface } from "../../../../../data/dataMerchant";
import { StoreTerminals } from "./StoreTerminals";
import "./Store.scss";

interface Props {
  store: StoreInterface;
  displayTerminals?: boolean;
}

export const Store: React.FunctionComponent<Props> = ({
  store,
  displayTerminals = false,
}) => {
  return (
    <div className="store text-small">
      <h5>{store.commercialName}</h5>

      <div className="tablet-columns split">
        <Address header={<T>Store address</T>} address={store.address} />
      </div>
      {displayTerminals && (
        <div className="terminals">
          <b>
            <T>Terminals</T>
          </b>
          <StoreTerminals />
        </div>
      )}
    </div>
  );
};
