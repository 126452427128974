import React, { ReactNode } from "react";
import cx from "classnames";
import styles from "./EditRow.module.scss";
import { Button } from "../interactions/Buttons/Button";
import { T } from "../translation/T";
import { EditRowRemove } from "./EditRowRemove";

interface Props {
  isValid: boolean;
  children?: ReactNode;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onRemove?: () => Promise<unknown>;
  data?: any;
  confirmRemoveText?: ReactNode | string;
}

export const EditRow: React.FunctionComponent<Props> = ({
  children = null,
  setIsOpen,
  onRemove,
  confirmRemoveText = null,
  data,
}) => {
  return (
    <div className={styles.row}>
      {children}

      <div className={styles.actions}>
        {onRemove && (
          <EditRowRemove onRemove={onRemove} data={data}>
            {confirmRemoveText || <T>Confirm remove</T>}
          </EditRowRemove>
        )}

        <Button
          size="small"
          className={cx(styles.editButton, "text-small")}
          onClick={() => setIsOpen(true)}
        >
          <T>Edit</T>
        </Button>
      </div>
    </div>
  );
};
