import { QueryOptions } from "@tanstack/react-query";
import { API } from "../network/API";
import { ContractDocument, LinkId } from "./models/ContractTypes";
import { Access, getProxy } from "./proxy";

const BASE_PATH = "/api/merchant";

export interface OwnershipCompany {
  name?: string;
  registrationNumber?: string;
  ownershipPercentage?: number;
  countryOfIncorporation?: string;
  new?: boolean;
}

export interface OwnershipAndControlData {
  // ownership
  ownedByOtherLegalPart?: boolean;
  companies: OwnershipCompany[];

  // control
  controlledByOtherLegalPart?: boolean;
  controlArrangement?: string;
  controlArrangementOtherReason?: string;
  // attachments here as well
  controlStructureDocument: ContractDocument;

  // shareholders
  nomineeShareholders?: boolean;
  nomineeShareholdersDocument: ContractDocument;
  shareholderCustodianDocument: ContractDocument;
  // attachment of share record

  // government - TBD
  // ownedByGovernment: boolean;
  // ownedByGovernmentCountry?: string;
}

enum WhitelistedEndpoint {
  FETCH_OWNERSHIP = "fetchOwnership",
  GET_OWNERSHIP_KEY = "getQueryKey",
}

export const Ownership = {
  [WhitelistedEndpoint.FETCH_OWNERSHIP]: (
    linkId: LinkId
  ): QueryOptions<OwnershipAndControlData> => ({
    queryKey: Ownership.getQueryKey(linkId),
    queryFn: () =>
      API.get<OwnershipAndControlData>(`${BASE_PATH}/${linkId}/ownership`),
  }),

  postOwnership: (linkId: LinkId, data: OwnershipAndControlData) =>
    API.post(`${BASE_PATH}/${linkId}/ownership`, data),

  [WhitelistedEndpoint.GET_OWNERSHIP_KEY]: (linkId: LinkId) => [
    "ownership",
    linkId,
  ],
};

let ownership: typeof Ownership;

export function dataOwnership(access: Access) {
  if (!ownership) {
    ownership = getProxy(Ownership, access, Object.values(WhitelistedEndpoint));
  }

  return ownership;
}
