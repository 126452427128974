import React from "react";
import cx from "classnames";

interface Props {
  className?: string;
}

export function Package({ className }: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={cx("icon", "package", className)}
      viewBox="0 -960 960 960"
    >
      <path d="M450-154v-309L180-619v309l270 156Zm60 0 270-156v-310L510-463v309Zm-60 69L150-258q-14-8-22-22t-8-30v-340q0-16 8-30t22-22l300-173q14-8 30-8t30 8l300 173q14 8 22 22t8 30v340q0 16-8 30t-22 22L510-85q-14 8-30 8t-30-8Zm194-525 102-59-266-154-102 59 266 154Zm-164 96 104-61-267-154-104 60 267 155Z" />
    </svg>
  );
}
