import React, { useCallback, useEffect, useMemo, useState } from "react";
import styles from "./DocumentCollection.module.scss";
import { Logo } from "../../components/images/Logo";
import { Trans, useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { T } from "../../components/translation/T";
import { dataAssociates } from "../../data/dataAssociates";
import { useLinkId } from "../../hooks/useLinkId";
import { contractState } from "../../state/contractState";
import { getViewer } from "../DataCollection/ContractLoader";
import { dataDocuments } from "../../data/dataDocuments";
import { Document } from "./Document";
import { ContractDocument, DocumentId } from "../../data/models/ContractTypes";
import { AnimateHeight } from "../../components/animate/AnimateHeight";
import { SuccessBox } from "../../components/boxes/SuccessBox";
import { useSuspendedQueries } from "../../hooks/useSuspendedQueries";
import { TI } from "../../i18n";
import { Access } from "../../data/proxy";

export const DOCUMENT_URL = "/:linkId/additional-documents";

export const DocumentCollection: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const linkId = useLinkId();
  const contract = useRecoilValue(contractState);
  const [uploads, setUploads] = useState<Record<string, boolean>>();

  const [{ data: docs }, { data: associates }] = useSuspendedQueries<any>({
    queries: [
      dataDocuments(Access.VIEW_AND_EDIT).fetchDocuments(linkId),
      dataAssociates(Access.VIEW_AND_EDIT).fetchAssociates(linkId),
    ],
  });

  const documents = useMemo(() => {
    return (docs as ContractDocument[]).filter((doc) => !doc.uploaded);
  }, [docs]);

  useEffect(() => {
    if (!documents) {
      return;
    }
    const initial: Record<string, boolean> = {};
    const initialUploads = documents.reduce((acc, cur) => {
      acc[`key-${cur.documentId}`] = !!cur.uploaded;
      return acc;
    }, initial);
    setUploads(initialUploads);
  }, [documents]);

  const onUploadChange = useCallback((docId: DocumentId, uploaded: boolean) => {
    setUploads((prev) => ({
      ...prev,
      [`key-${docId}`]: uploaded,
    }));
  }, []);

  const viewer = useMemo(
    () => getViewer(contract.contractViewer, associates),
    [associates, contract]
  );

  const { companyName } = contract.contractData;
  const allUploaded = uploads
    ? Object.values(uploads).every((item) => !!item)
    : false;

  return (
    <section>
      <article>
        <div className={styles.wrapper}>
          <div className="logo-wrapper">
            <Logo />
          </div>
          <div className={styles.header}>
            <h2>
              <T
                id="Hi, {{name}}!"
                options={{
                  name: `${viewer?.contact.firstName} ${viewer?.contact.lastName}`,
                }}
              />
            </h2>
          </div>

          <p>
            <Trans t={t} companyName={companyName}>
              <strong>{{ companyName } as TI}</strong> has been registered for
              Worldline payment services. However, there were some documents
              that could not be verified. Would you please upload them again?
            </Trans>
          </p>

          <div className="m-top-40">
            {documents.map((doc) => (
              <Document
                key={doc.documentId}
                document={doc}
                linkId={linkId}
                disabled={allUploaded}
                onUploadChange={onUploadChange}
              />
            ))}
          </div>

          <div className={styles.done}>
            <AnimateHeight name={allUploaded ? "done" : "no"}>
              <div>
                {allUploaded ? (
                  <>
                    <SuccessBox relative>
                      <b className="text-large">
                        <T>You're done!</T>
                      </b>
                      <p>
                        <T>
                          All is set. We we'll review the documents and proceed
                          with the application. It is safe to close this tab.
                        </T>
                      </p>
                    </SuccessBox>
                  </>
                ) : (
                  <div />
                )}
              </div>
            </AnimateHeight>
          </div>
        </div>
      </article>
    </section>
  );
};
