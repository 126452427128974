import { DateTime } from "luxon";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form } from "../../../../../components/form/Form";
import { TextInput } from "../../../../../components/form/TextInput";
import { MaxDateValidator } from "../../../../../components/form/validators/MaxDateValidator";
import { MinDateValidator } from "../../../../../components/form/validators/MinDateValidator";
import { RequiredValidator } from "../../../../../components/form/validators/RequiredValidator";
import { Button } from "../../../../../components/interactions/Buttons/Button";
import { T } from "../../../../../components/translation/T";
import { isValidDate } from "../../../../../components/utils";
import { PEP_TYPE, Pep } from "../../../../../data/dataMerchant";
import { AnimateHeight } from "../../../../../components/animate/AnimateHeight";
import { Checkboxes } from "../../../../../components/interactions/Checkboxes/Checkboxes";

interface Props {
  pep: Pep;
  onChange: (pep: Pep) => void;
  onSubmit: () => void;
  onBack: () => void;
  isViewer: boolean;
}

const DATE_PATTERN = "yyyy-MM-dd";
export const MAX_PEP_AGE = 100;

function getLabels(pep: Pep, isViewer: boolean) {
  if (isViewer) {
    if (pep.type === PEP_TYPE.RELATIVE_TO_PEP) {
      return [
        "Start date of period where your relative held the public office/position",
        "End date of period where your relative held the public office/position",
      ];
    } else {
      return [
        "Start date of period where you held the public office/position",
        "End date of period where you held the public office/position",
      ];
    }
  }

  if (pep.type === PEP_TYPE.RELATIVE_TO_PEP) {
    return [
      "Start date of period where the relative held the public office/position",
      "End date of period where the relative held the public office/position",
    ];
  }

  return [
    "Start date of period where this person held the public office/position",
    "End date of period where this person held the public office/position",
  ];
}

export const PepDates: React.FunctionComponent<Props> = ({
  pep,
  onChange,
  onSubmit,
  onBack,
  isViewer,
}) => {
  const [values, setValues] = useState({ ...pep });
  const { t } = useTranslation();
  const today = DateTime.now();
  const todayString = today.toFormat(DATE_PATTERN);
  const minDate = today.minus({ years: MAX_PEP_AGE });
  const validStartDate =
    values.startedPosition && isValidDate(new Date(values.startedPosition));

  const minLeftDate = validStartDate
    ? DateTime.fromJSDate(new Date(values.startedPosition as string))
    : null;
  const minLeftDateString = minLeftDate?.toFormat(DATE_PATTERN);
  const [ongoing, setOngoing] = useState<boolean>(!pep.leftPosition);

  const leftValidators = useMemo(() => {
    if (minLeftDate) {
      return [
        new RequiredValidator("End date is required"),
        new MinDateValidator(
          (minLeftDate as DateTime).toJSDate(),
          "End date must be after start date"
        ),
        new MaxDateValidator(
          (today as DateTime).toJSDate(),
          "Assignement must have ended in the past"
        ),
      ];
    }

    return [
      new RequiredValidator("End date is required"),
      new MaxDateValidator(
        (today as DateTime).toJSDate(),
        "Assignement must have ended in the past"
      ),
    ];
  }, [minLeftDate, today]);

  const labels = useMemo(() => {
    return getLabels(pep, isViewer);
  }, [pep, isViewer]);

  return (
    <div className="m-top-30">
      <Form
        name="pepDates"
        onSubmit={(_, form) => {
          if (form.isInvalid) {
            return;
          }

          const copy = structuredClone(values);

          if (ongoing) {
            delete copy.leftPosition;
            delete pep.leftPosition;
          }

          onChange({
            ...pep,
            ...copy,
            type: pep.type, // if user has added a type but backs up,
            // the incorrect type is stored in the state. It is okey to
            // cache/reuse the other values, but, needless to say, not the type
          });
          onSubmit();
        }}
      >
        <div className="m-top-20">
          <TextInput
            name="startedPosition"
            label={labels[0]}
            type="date"
            validators={[
              new RequiredValidator("Start date is required"),
              new MinDateValidator(
                minDate.toJSDate(),
                t("Seems like a very old PEP person, no?")
              ),
              new MaxDateValidator(
                today.toJSDate(),
                t("Assignment must have started in the past")
              ),
            ]}
            value={values.startedPosition}
            attributes={{
              min: minDate.toFormat(DATE_PATTERN),
              max: todayString,
            }}
            onChange={(value) => {
              if (!values.leftPosition) {
                setValues({ ...values, startedPosition: value });
                return;
              }

              if (new Date(values.leftPosition) < new Date(value)) {
                setValues({
                  ...values,
                  startedPosition: value,
                  leftPosition: undefined,
                });
                return;
              }

              setValues({ ...values, startedPosition: value });
            }}
          />
        </div>

        <div className="m-top-20">
          <Checkboxes
            label=""
            values={ongoing ? ["true"] : []}
            onChange={(values) => {
              if (values.length) {
                setOngoing(true);
              } else {
                setOngoing(false);
              }
            }}
            alternatives={[
              {
                value: "true",
                text: "Assignment is ongoing",
              },
            ]}
          />
        </div>

        <div className="m-top-20">
          <AnimateHeight name={ongoing ? "on" : "off"}>
            <div>
              {ongoing ? null : (
                <TextInput
                  name="leftPosition"
                  label={labels[1]}
                  type="date"
                  validators={leftValidators}
                  attributes={{
                    min: validStartDate ? minLeftDateString : undefined,
                    max: todayString,
                  }}
                  value={values.leftPosition}
                  onChange={(value) => {
                    setValues({ ...values, leftPosition: value });
                  }}
                />
              )}
            </div>
          </AnimateHeight>
        </div>

        <div className="m-top-30 columns">
          <div>
            <Button onClick={onBack} ghost>
              <T>Back</T>
            </Button>
          </div>
          <div className="text-right">
            <Button type="submit">
              <T>Next</T>
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
};
