import React from "react";
import styles from "./SavedAccount.module.scss";
import { ConfirmedStatus, bankAccountSourceText } from "../../../data/dataBank";
import { SuccessBox } from "../../../components/boxes/SuccessBox";
import { Button } from "../../../components/interactions/Buttons/Button";
import { T } from "../../../components/translation/T";
import { Or } from "../../../components/or/Or";
import "./SavedAccount.module.scss";

interface Props {
  externalAccount?: ConfirmedStatus;
  next: () => void;
  disabled?: boolean;
}

export const SavedAccount: React.FunctionComponent<Props> = ({
  externalAccount,
  next,
  disabled = false,
}) => {
  if (!externalAccount) {
    return null;
  }

  if (!externalAccount.done) {
    return null;
  }

  return (
    <>
      <div className={styles.added}>
        <SuccessBox relative>
          <T>
            A payout account has already been saved. You can either keep that
            one or change account.
          </T>
        </SuccessBox>

        <div className={styles.box}>
          <T>Account</T>: <b>{externalAccount.bankAccount}</b>
          {externalAccount.source ? (
            <>
              <br />
              <T>Will be verified by </T>
              {bankAccountSourceText[externalAccount.source]}
            </>
          ) : null}
        </div>

        <div className="m-top-30">
          <Button block onClick={next} disabled={disabled}>
            <T>Continue</T>
          </Button>
        </div>
      </div>
      <Or className="bg">
        <div>&nbsp;</div>
        <div></div>
      </Or>
    </>
  );
};
