export enum Status {
  DEFAULT = "",
  DISABLED = "is-disabled",
  PENDING = "is-pending",
  ERROR = "has-error",
  SUCCESS = "was-successful",
}

export enum Validity {
  MISSING = "has-missing-information",
  PARTIAL = "has-partial-information",
  VALID = "has-required-information",
  DEFAULT = "",
}

export interface Dynamic {
  [key: string]: any;
}

export interface DynamicOfType<T> {
  [key: string]: T;
}

export interface Stringifiable {
  toString(): string;
}
