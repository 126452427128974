import React, { useCallback, useRef, useEffect } from "react";
import cx from "classnames";
import { Checkmark } from "../../icons/Checkmark";
import { Error as ErrorIcon } from "../../icons/Error";
import { Pending as PendingIcon } from "../../icons/Pending";
import { Disabled as DisabledIcon } from "../../icons/Disabled";
import { Status } from "../../../data/types";
import "./Button.scss";
import { motion } from "framer-motion";

export function getIcon(status: Status) {
  if (status === Status.ERROR) {
    return <ErrorIcon />;
  }

  if (status === Status.SUCCESS) {
    return <Checkmark />;
  }

  if (status === Status.PENDING) {
    return <PendingIcon />;
  }

  if (status === Status.DISABLED) {
    return <DisabledIcon />;
  }

  return null;
}

interface BaseProps
  extends Pick<React.ButtonHTMLAttributes<HTMLButtonElement>, "form"> {
  children: React.ReactNode;
  className?: string;
  data?: any;
  block?: boolean;
  ghost?: boolean;
  status?: Status;
  action?: boolean;
  link?: boolean;
  tabIndex?: number;
  text?: boolean;
  size?: "mini" | "small" | "medium" | "large";
  disabled?: boolean;
  stopPropagation?: boolean;
}

interface Props extends BaseProps {
  onClick: (data: any, event: React.MouseEvent<HTMLButtonElement>) => void;
  type?: "button" | "reset";
}

interface SubmitProps extends BaseProps {
  type: "submit";
}

export type ButtonProps = Props | SubmitProps;

function isSubmit(tested: Props | SubmitProps): tested is SubmitProps {
  return (tested as unknown as SubmitProps).type === "submit";
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (props, ref) => {
    const {
      children,
      className,
      data,
      block = false,
      ghost = false,
      status = Status.DEFAULT,
      action = false,
      link = false,
      tabIndex = 0,
      text = false,
      size = "medium",
      disabled = false,
      stopPropagation,
      form,
    } = props;

    let onClick: (
      data: any,
      event: React.MouseEvent<HTMLButtonElement>
    ) => void;
    let type: "button" | "reset" | "submit";

    if (isSubmit(props)) {
      onClick = () => {};
      ({ type } = props);
    } else {
      ({ onClick, type = "button" } = props);
    }

    const wasClicked = useRef<boolean>(false);
    const hasUnmounted = useRef<boolean>(false);

    useEffect(() => {
      hasUnmounted.current = false;

      return () => {
        hasUnmounted.current = true;
      };
    }, []);

    const onButtonClick = useCallback(
      (event: React.MouseEvent<HTMLButtonElement>) => {
        if (stopPropagation) {
          event.stopPropagation();
        }

        if (wasClicked.current) {
          return;
        }

        onClick?.(data, event);
        wasClicked.current = true;

        setTimeout(() => {
          if (!hasUnmounted.current) {
            wasClicked.current = false;
          }
        }, 300);
      },
      [stopPropagation, onClick, data]
    );

    let icon = null;
    if (!action) {
      icon = getIcon(status);
    }

    return (
      <button
        className={cx(className, status, size, {
          ghost,
          block,
          action,
          button: !link,
          "as-link": link,
          "as-text": text,
        })}
        disabled={disabled || status !== Status.DEFAULT}
        onClick={onButtonClick}
        {...{ tabIndex, type, ref, form }}
      >
        {children}
        {icon && (
          <motion.span
            layout
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
          >
            {icon}
          </motion.span>
        )}
      </button>
    );
  }
);

export const MotionButton = motion(Button);
