import React, { RefObject, useCallback, useMemo, useRef } from "react";
import cx from "classnames";
import { Address } from "../../data/models/ContractTypes";
import { Beacon } from "../beacon/Beacon";
import { HiddenInput } from "../form/HiddenInput";
import { RequiredValidator } from "../form/validators/RequiredValidator";

import { AddressPopover } from "./AddressPopover";
import {
  addressFieldRequiredText,
  addressFieldValidators,
} from "./addressUtils";
import { motion } from "framer-motion";
import { T } from "../translation/T";
import { AddressLocationChange, AddressSearch } from "./AddressSearch";
import { Validity } from "../../data/types";
import { Link } from "../link/Link";
import { Location } from "../icons/Location";
import "./AddressWithSearch.scss";
import styles from "./AddressWithSearch.module.scss";

interface SharedProps {
  address: Address;
  onChange: (address: Address) => void;
  disabled?: boolean;
  scrollToRef?: RefObject<HTMLElement>;
  label?: string;
  hideHeader?: boolean;
}

interface PropsWithoutCountryCode extends SharedProps {
  addressRequiredFields?: Record<keyof Omit<Address, "countryCode">, boolean>;
  showCountryCode?: false;
}

interface PropsWithCountryCode extends SharedProps {
  addressRequiredFields: Record<keyof Address, boolean>;
  showCountryCode: true;
}

type Props = PropsWithoutCountryCode | PropsWithCountryCode;

export function getGoogleMapsLink(address: Address) {
  if (!address.street || !address.city || !address.postalCode) {
    return;
  }

  const addressString = [
    address.street,
    address.city,
    address.postalCode,
    address.countryCode || "Sweden",
  ].join(", ");

  return `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
    addressString
  )}`;
}

export const AddressWithSearch = ({
  address,
  onChange,
  addressRequiredFields = {
    street: false,
    city: false,
    postalCode: false,
    countryCode: false,
  },
  disabled,
  label,
  hideHeader,
  ...props
}: Props) => {
  const ref = useRef<HTMLDivElement>(null);

  const secondAddressRow = useMemo(() => {
    return [address.city, address.postalCode].filter(Boolean).join(", ");
  }, [address]);

  const onAddressSelect = useCallback(
    (location: AddressLocationChange) => {
      onChange({
        ...address,
        ...location,
      });
    },
    [onChange, address]
  );

  const googleMapsLink = useMemo(() => getGoogleMapsLink(address), [address]);

  return (
    <div className="address-with-search" ref={ref}>
      <div
        className={cx("address-search-wrapper", styles.wrapper, {
          [styles.hideHeader]: hideHeader,
        })}
      >
        <div className="search">
          <AddressSearch
            onChange={onAddressSelect}
            {...{ disabled, label, hideHeader }}
          />
        </div>
        <AddressPopover
          onChange={onAddressSelect}
          {...{
            ...props,
            hideHeader,
            address,
            addressRequiredFields,
            disabled,
          }}
        />
      </div>
      <div className="address-row">
        <Beacon validity={Validity.DEFAULT} icon={<Location />} />

        <motion.ul
          className="short-info text-small"
          key={address.street + secondAddressRow}
          initial={{ opacity: 0, x: 50 }}
          animate={{ opacity: 1, x: 0 }}
        >
          <li>
            {address.street || (secondAddressRow ? "-" : <T>No address set</T>)}
          </li>
          <li className="short-info-address">
            {secondAddressRow || (address.street ? "-" : "")}
          </li>
        </motion.ul>

        {googleMapsLink && (
          <Link link={googleMapsLink} external className="small">
            <T>View on map</T>
          </Link>
        )}
      </div>
      <div className="error-messages">
        {Object.entries(addressRequiredFields).map(([key, required]) => {
          if (!required) {
            return null;
          }
          return (
            <HiddenInput
              key={key}
              name={key}
              value={address[key as keyof Address]}
              scrollToRef={ref}
              validators={[
                ...addressFieldValidators[key as keyof Address],
                ...(required
                  ? [
                      new RequiredValidator(
                        addressFieldRequiredText[key as keyof Address]
                      ),
                    ]
                  : []),
              ]}
              label={key}
            />
          );
        })}
      </div>
    </div>
  );
};
