import { isArray } from "lodash";
import React, {
  PropsWithChildren,
  ReactElement,
  ReactNode,
  useMemo,
} from "react";
import { ProductType } from "../../data/models/ContractTypes";

interface Props {
  children: ReactNode;
  productType: ProductType;
}

interface DefaultProps {
  children: ReactNode;
}

interface ProductProps {
  children: ReactNode;
  products: ProductType | ProductType[];
}

function propsContainsProduct(
  chosenProduct: ProductType,
  childProducts: ProductType | ProductType[]
) {
  return isArray(childProducts)
    ? childProducts.includes(chosenProduct)
    : chosenProduct === childProducts;
}

export const Products: React.FunctionComponent<Props> & {
  Default: React.FunctionComponent<DefaultProps>;
  Product: React.FunctionComponent<ProductProps>;
} = ({ children, productType }) => {
  const showDefault = useMemo(() => {
    let hasProduct = false;

    if (!productType) {
      return false;
    }

    React.Children.forEach(children, (child: ReactNode) => {
      const item = child as ReactElement<PropsWithChildren>;

      if (item.type === Product) {
        const products = (item.props as ProductProps).products;

        if (propsContainsProduct(productType, products)) {
          hasProduct = true;
        }
      }
    });

    return !hasProduct;
  }, [productType, children]);

  if (!productType) {
    return null;
  }

  return (
    <>
      {React.Children.map(children, (child: ReactNode) => {
        const item = child as ReactElement<PropsWithChildren>;
        const { type } = item;

        if (type !== Product && type !== Default) {
          throw Error(
            "Products: Child must be of type 'Products.Product' or 'Products.Default'"
          );
        }

        if (type === Product) {
          const products = (item.props as ProductProps).products;

          if (propsContainsProduct(productType, products)) {
            return child;
          }
        }

        if (showDefault && type === Default) {
          return child;
        }

        return null;
      })}
    </>
  );
};

export const Default: React.FunctionComponent<DefaultProps> = ({
  children,
}) => {
  return <>{children}</>;
};

export const Product: React.FunctionComponent<ProductProps> = ({
  children,
}) => {
  return <>{children}</>;
};

Products.Default = Default;
Products.Product = Product;
