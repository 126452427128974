import { dataAssociates } from "../../../data/dataAssociates";
import { LinkId } from "../../../data/models/ContractTypes";
import { Access } from "../../../data/proxy";
import { ValidationResponse, VALIDATION_STATE } from "../hooks/useValidation";
import { BaseValidator } from "./BaseValidator";

export class EmailBackendValidator extends BaseValidator {
  name = "Email backend validator";
  private linkId: LinkId;

  constructor(linkId: LinkId, error: string) {
    super(error);
    this.linkId = linkId;
  }

  validate(value: string): Promise<ValidationResponse> {
    return new Promise<ValidationResponse>((resolve) => {
      if (value.length === 0) {
        resolve({ status: VALIDATION_STATE.SUCCESS });
        return;
      }

      dataAssociates(Access.VIEW_AND_EDIT)
        .validateEmail(value, this.linkId)
        .then(({ valid }) => {
          if (valid) {
            resolve({
              status: VALIDATION_STATE.SUCCESS,
            });
          } else {
            resolve({
              status: VALIDATION_STATE.FAILED,
              message: this.error,
            });
          }
        })
        .catch(() => {
          resolve({
            status: VALIDATION_STATE.FAILED,
            message: "Could not validate email, try again later",
          });
        });
    });
  }
}
