import { Country } from "../../data/models/ContractTypes";
import { T } from "../translation/T";

interface Props {
  country: Country;
}

const companyRegistrationIdName: Record<Country, string> = {
  [Country.SWEDEN]: "Organisation number",
  [Country.NORWAY]: "Organisation number",
  [Country.DENMARK]: "CVR-number",
  [Country.FINLAND]: "Y-tunnus",
  [Country.GB]: "CRN number",
};

export const FormattedCompanyIdName = ({ country }: Props) => {
  return (
    <>
      <T>{companyRegistrationIdName[country]}</T>
    </>
  );
};
