import React, { ReactNode } from "react";
import cx from "classnames";
import styles from "./Paper.module.scss";

interface Props {
  children: ReactNode;
}

export const Paper: React.FunctionComponent<Props> = ({ children }) => {
  return (
    <div className={cx(styles.paperWrapper)}>
      <div className={styles.bg1} />
      <div className={styles.bg2} />
      <div className={cx(styles.paper)}>{children}</div>
    </div>
  );
};
