import React from "react";
import { Country } from "../../data/models/ContractTypes";
import { Flag } from "../flags/Flag";
import { getCountryDisplayName } from "../utils";
import { Chip } from "./Chip";
import { useTranslation } from "react-i18next";

interface Props {
  onRemove?: (data?: any) => void;
  data?: any;
  disabled?: boolean;
  country: Country;
  language?: string;
}

export const CountryChip: React.FunctionComponent<Props> = ({
  country,
  language,
  ...rest
}) => {
  const { i18n } = useTranslation();

  return (
    <Chip {...rest}>
      <Flag country={country as Country} rounded shadow height={14} />
      &nbsp;
      {getCountryDisplayName(country, language ?? i18n.language)}
    </Chip>
  );
};
