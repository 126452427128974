import { useMemo, useRef } from "react";
import { Route, useNavigate } from "react-router-dom";
import { StoryPage } from "../../components/story/StoryPage";
import { LegalEntityType, ProductType } from "../../data/models/ContractTypes";
import { RouteState } from "../../state/routeState";
import {
  DATA_COLLECTION_STORY_ROUTES,
  RouteConfig,
} from "./dataCollectionRoutes";

export const useDataCollectionRoutes = (
  legalEntityType: LegalEntityType,
  productType: ProductType,
  state: RouteState
) => {
  const navigate = useNavigate();

  const routeConfigs: Record<string, RouteConfig> = useMemo(
    () =>
      Object.entries(DATA_COLLECTION_STORY_ROUTES).reduce(
        (
          acc,
          [key, { includeLegalEntityType, includeProductType, includeCriteria }]
        ) => {
          return includeLegalEntityType[legalEntityType] &&
            includeProductType[productType] &&
            includeCriteria(state)
            ? { ...acc, [key]: DATA_COLLECTION_STORY_ROUTES[key] }
            : acc;
        },
        {}
      ),
    [legalEntityType, productType, state]
  );

  const routeElements = useMemo(() => {
    const routeArray = Object.values(routeConfigs);
    return (
      <>
        {routeArray.map((config, index) => (
          <Route
            key={config.path}
            path={config.path}
            element={
              <StoryRoute
                {...config}
                next={() => navigate(routeArray[index + 1]?.path)}
              />
            }
          />
        ))}
      </>
    );
  }, [navigate, routeConfigs]);

  const routes: Record<string, string> = useMemo(
    () =>
      Object.values(routeConfigs).reduce(
        (acc, { path }) => ({ ...acc, [path]: path }),
        {}
      ),
    [routeConfigs]
  );

  return { routes, routeElements };
};

const StoryRoute = ({
  element: Element,
  next,
}: RouteConfig & { next: () => void }) => {
  const ref = useRef<HTMLDivElement>(null);

  return (
    <StoryPage ref={ref}>
      <Element next={next} pageRef={ref} />
    </StoryPage>
  );
};
