import { Associate, AssociateRole } from "../../../data/dataMerchant";
import { AssociateId, Contact } from "../../../data/models/ContractTypes";

export enum AdditionalId {
  ADD_MANUALLY = "ADD_MANUALLY_ID",
}

export enum formInputKeys {
  EMAIL = "email",
  PHONE_NUMBER = "phoneNumber",
  FIRST_NAME = "firstName",
  LAST_NAME = "lastName",
}

export const getFullname = (associate: Associate) => {
  return `${associate.contact.firstName} ${associate.contact.lastName}`;
};

export const InitialCustomAccountHolderState: Associate = {
  associateId: AdditionalId.ADD_MANUALLY as AssociateId,
  roles: [],
  contact: {
    position: "",
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
  },
  id: {},
};

export const initialFormDataState: Contact = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  position: "",
};

export const compareContactData = (
  contact1: Partial<Contact>,
  contact2: Partial<Contact>
) => {
  return (
    contact1.firstName !== contact2.firstName ||
    contact1.lastName !== contact2.lastName ||
    contact1.email !== contact2.email ||
    contact1.phoneNumber !== contact2.phoneNumber
  );
};

export function isCustomAccountHolder(associate?: Associate) {
  return (
    !!associate &&
    associate.roles.indexOf(AssociateRole.ACCOUNT_HOLDER) > -1 &&
    associate.roles.length === 1
  );
}
