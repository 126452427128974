import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Trans, useTranslation } from "react-i18next";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { Card } from "../../../components/cards/Card";
import { Form } from "../../../components/form/Form";
import { HiddenInput } from "../../../components/form/HiddenInput";
import { RequiredValidator } from "../../../components/form/validators/RequiredValidator";
import { Button } from "../../../components/interactions/Buttons/Button";
import { StoryStepProps } from "../../../components/story/Story";
import { StoryContinueButton } from "../../../components/story/StoryContinueButton";
import { T } from "../../../components/translation/T";
import { dataAssociates, isOwner } from "../../../data/dataAssociates";
import { Associate, AssociateRole, Id, Pep } from "../../../data/dataMerchant";
import {
  AssociateId,
  Contact,
  LegalEntityType,
} from "../../../data/models/ContractTypes";
import { useLinkId } from "../../../hooks/useLinkId";
import { useSuspendedQuery } from "../../../hooks/useSuspendedQuery";
import {
  onlyDirectOwnersWithFullOwnership,
  routeState,
} from "../../../state/routeState";
import { isComplete, OwnerOverlay } from "./OwnerOverlay";
import { id as generateId } from "../../../components/utils";
import { contractState } from "../../../state/contractState";
import { Visible } from "../../../components/visible/Visible";
import { RadioGroup } from "../../../components/form/RadioGroup";
import { AnimateHeightAuto } from "../../../components/animate/AnimateHeightAuto";
import { useQueryClient } from "@tanstack/react-query";
import { Status } from "../../../data/types";
import { AnimateHeight } from "../../../components/animate/AnimateHeight";
import { GenericError } from "../../../components/Errors/GenericError";
import { ErrorBox } from "../../../components/boxes/ErrorBox";
import { NewOwner } from "./NewOwner";
import { Access } from "../../../data/proxy";
import { useAccess } from "../../../hooks/useAccess";

interface WrapperProps {
  children: ReactNode;
  hasMajorityOwners?: boolean;
}

export interface PartialAssociate {
  associateId?: AssociateId;
  roles: AssociateRole[];
  contact?: Partial<Contact>;
  id?: Partial<Id>;
  pep?: Partial<Pep>;
}

export const defaultAssociate: PartialAssociate = {
  roles: [AssociateRole.BENEFICIAL_OWNER],
  contact: {} as Partial<Contact>,
  id: {},
  pep: {} as Partial<Pep>,
};

export interface InnerProps {
  hasMajorityOwners?: boolean;
}

function getTotalOwnershipShares(owners: Associate[]) {
  let totalCapitalStake = 0;
  let totalVotingRights = 0;

  owners.forEach((item) => {
    totalCapitalStake = totalCapitalStake + (item.owner?.capitalStake || 0);
    totalVotingRights =
      totalVotingRights + (item.owner?.votingRightsStake || 0);
  });

  return {
    totalCapitalStake,
    totalVotingRights,
  };
}

function allAreMinorityOwners(associates: Associate[]) {
  const owners = associates.filter((associate) => isOwner(associate));

  if (!owners.length) {
    return false;
  }

  return owners.every((associate) => {
    if (undefined === associate.owner?.hasIndirectOwnership) {
      return false;
    }

    if (
      undefined === associate.owner.capitalStake &&
      undefined === associate.owner.votingRightsStake
    ) {
      return false;
    }

    if (
      (typeof associate.owner.capitalStake === "number" &&
        associate.owner.capitalStake > 25) ||
      (typeof associate.owner.votingRightsStake === "number" &&
        associate.owner.votingRightsStake > 25)
    ) {
      return true;
    }

    return false;
  });
}

function allAreMajorityOwners(associates: Associate[]) {
  const owners = associates.filter((associate) => isOwner(associate));

  if (!owners.length) {
    return false;
  }

  return owners.every((associate) => !!associate.contact.position);
}

function getText(hasMajorityOwners?: boolean) {
  if (hasMajorityOwners) {
    return "Please add information of the responsible person";
  }

  return "Please add information on all beneficial owners";
}

export const Owners: React.FunctionComponent<StoryStepProps> = ({
  next,
  pageRef,
}) => {
  const linkId = useLinkId();
  const contract = useRecoilValue(contractState);
  const queryClient = useQueryClient();
  const access = useAccess();
  const { t } = useTranslation();
  const [status, setStatus] = useState<Status>(Status.DEFAULT);
  const [hasMajorityOwners, setHasMajorityOwners] = useState<boolean>();
  const { legalEntityType } = contract.contractData;
  const { data: associates } = useSuspendedQuery(
    dataAssociates(access).fetchAssociates(linkId)
  );

  useEffect(() => {
    if (hasMajorityOwners !== undefined) {
      return;
    }

    if (allAreMinorityOwners(associates)) {
      setHasMajorityOwners(false);
      return;
    }

    if (allAreMajorityOwners(associates)) {
      setHasMajorityOwners(true);
      return;
    }
  }, [associates, hasMajorityOwners]);

  const changeOwnerType = useCallback(
    (value: any) => {
      const majorityOwners = value.toString() !== "false";

      setHasMajorityOwners(majorityOwners);
      if (majorityOwners) {
        setStatus(Status.DEFAULT);
        const owners = associates.filter((owner) => isOwner(owner));
        const promises: Promise<void | Associate>[] = [];

        owners.forEach((owner) => {
          if (owner.roles.length === 1 && isOwner(owner)) {
            promises.push(
              dataAssociates(access).removeAssociate(linkId, owner)
            );
          } else {
            promises.push(
              dataAssociates(access).saveAssociate(linkId, {
                ...owner,
                roles: owner.roles.filter(
                  (role) => role !== AssociateRole.BENEFICIAL_OWNER
                ),
              })
            );
          }
        });

        Promise.all(promises)
          .then(() => {
            queryClient.invalidateQueries(["associates", linkId]);
          })
          .catch((err) => {
            setStatus(Status.ERROR);
          });
      }
    },
    [associates, access, queryClient, linkId]
  );

  const opts = useMemo(() => {
    return [
      {
        text: <T>One or more person/persons owning more than 25%</T>,
        value: false,
      },
      { text: <T>No person owns more than 25%</T>, value: true },
    ];
  }, []);

  if (
    legalEntityType === LegalEntityType.SOLE_PROPRIETARY ||
    legalEntityType === LegalEntityType.PARTNERSHIP
  ) {
    return (
      <Wrapper>
        <MinorityOwners
          hasMajorityOwners={hasMajorityOwners}
          {...{ pageRef, next }}
        />
        <AnimateHeight name={status}>
          {status === Status.ERROR ? <GenericError /> : <div />}
        </AnimateHeight>
      </Wrapper>
    );
  }

  if (
    legalEntityType === LegalEntityType.ASSOCIATION ||
    legalEntityType === LegalEntityType.MUNICIPALITY ||
    legalEntityType === LegalEntityType.TRUST
  ) {
    return (
      <Wrapper>
        <MajorityOwners
          hasMajorityOwners={hasMajorityOwners}
          {...{ pageRef, next }}
        />
        <AnimateHeight name={status}>
          {status === Status.ERROR ? <GenericError /> : <div />}
        </AnimateHeight>
      </Wrapper>
    );
  }

  return (
    <>
      <h1>
        <T>Beneficial Owners</T>
      </h1>
      <div className="m-top-20">
        <div className="m-bottom-40">
          <RadioGroup
            label="Ownership structure"
            name="owner-structure"
            value={hasMajorityOwners}
            onChange={changeOwnerType}
            alternatives={opts}
            validators={[new RequiredValidator("Value is required")]}
          />
        </div>

        {/* <Visible show={hasMajorityOwners !== undefined}> */}
        <AnimateHeightAuto dependencies={[hasMajorityOwners]}>
          {hasMajorityOwners && (
            <MajorityOwners
              hasMajorityOwners={hasMajorityOwners}
              {...{ pageRef, next }}
            />
          )}
          {!hasMajorityOwners && (
            <MinorityOwners
              hasMajorityOwners={hasMajorityOwners}
              {...{ pageRef, next }}
            />
          )}
        </AnimateHeightAuto>
        {/* </Visible> */}

        {status === Status.ERROR ? (
          <ErrorBox relative>
            <b>
              <T>Oh no!</T>
            </b>{" "}
            <Trans t={t}>
              We tried to reset the owner structure but failed. If there are no
              individual that owns more than 25 % we should appoint a single
              alternative responsible person e.g a <b>CEO</b> or similar, but we
              were unable to remove the previous chosen owners. You could try to
              remove them manually or perhaps just reload the page in order to
              fix the problem. Sorry for the inconvenience.
            </Trans>
          </ErrorBox>
        ) : (
          <div />
        )}
      </div>
    </>
  );
};

const Wrapper: React.FunctionComponent<WrapperProps> = ({
  children,
  hasMajorityOwners,
}) => {
  return (
    <>
      <h2>
        <T>Beneficial Owners</T>
      </h2>
      <p>
        <T>{getText(hasMajorityOwners)}</T>
      </p>

      {children}
    </>
  );
};

const MajorityOwners: React.FunctionComponent<StoryStepProps & InnerProps> = ({
  next,
  pageRef,
  hasMajorityOwners,
}) => {
  const linkId = useLinkId();
  const ref = useRef<HTMLFormElement>(null);
  const { t } = useTranslation();
  const access = useAccess();
  const { data: associates } = useSuspendedQuery(
    dataAssociates(access).fetchAssociates(linkId)
  );
  const [owners, setOwners] = useState<Associate[]>([]);
  const setRouteState = useSetRecoilState(routeState);
  const [idOfNewOwner, setIdOfNewOwner] = useState<string | null>(null);

  useEffect(() => {
    setOwners(associates.filter((associate) => isOwner(associate)));
    setRouteState((prev) => ({
      ...prev,
      hasSimpleOwnership: onlyDirectOwnersWithFullOwnership(associates),
    }));
  }, [associates, setRouteState]);

  const addOwner = useCallback(() => {
    setIdOfNewOwner(generateId());
  }, []);

  return (
    <>
      <Visible show={hasMajorityOwners !== undefined}>
        <div className="m-top-10 flex-columns gap-20">
          {owners.map((owner) => (
            <Card key={owner.associateId}>
              <OwnerOverlay
                owner={owner}
                owners={owners}
                associates={associates}
                hasMajorityOwners={true}
              />
            </Card>
          ))}

          <NewOwner
            id={idOfNewOwner}
            setId={setIdOfNewOwner}
            hasMajorityOwners={true}
            associates={associates}
          />
        </div>

        <div>
          {!owners.length && (
            <Trans t={t}>
              Since there are no owners with a ownership stakes equal to{" "}
              <b>25% or above</b>, you will need to add an alternative
              responsible person e.g a <b>CEO</b> or similar.
            </Trans>
          )}
        </div>
      </Visible>

      <Visible show={!owners.length}>
        <Button
          ghost
          className="m-top-40"
          block
          onClick={addOwner}
          tabIndex={!owners.length ? 0 : -1}
        >
          <T>Add alternative responsible person</T>
        </Button>
      </Visible>

      <Form
        name="owners"
        id="owners"
        className="flex-1"
        ref={ref}
        onSubmit={(_, form) => {
          if (!form.isValid) {
            return;
          }

          next();
        }}
      >
        <div className="story-errors">
          <HiddenInput
            label="Missing type of ownership"
            value={hasMajorityOwners !== undefined ? true : undefined}
            validators={[
              new RequiredValidator("You must choose an ownership structure"),
            ]}
            scrollToRef={ref}
          />

          <HiddenInput
            label="Missing reposible person"
            value={owners.length ? true : undefined}
            validators={[
              new RequiredValidator("You must choose a resposible person"),
            ]}
            scrollToRef={ref}
          />

          <HiddenInput
            label="Completed owners"
            value={
              owners.every((owner) => isComplete(owner, true))
                ? true
                : undefined
            }
            validators={[
              new RequiredValidator(
                "The information of the responsible person seem to be incomplete"
              ),
            ]}
            scrollToRef={ref}
          />
        </div>
        <StoryContinueButton disabled={access === Access.VIEW} type="submit">
          <T>Continue</T>
        </StoryContinueButton>
      </Form>
    </>
  );
};

const MinorityOwners: React.FunctionComponent<StoryStepProps & InnerProps> = ({
  next,
  pageRef,
  hasMajorityOwners,
}) => {
  const linkId = useLinkId();
  const ref = useRef<HTMLFormElement>(null);
  const access = useAccess();
  const { t } = useTranslation();
  const { data: associates } = useSuspendedQuery(
    dataAssociates(access).fetchAssociates(linkId)
  );
  const [owners, setOwners] = useState<Associate[]>([]);
  const setRouteState = useSetRecoilState(routeState);
  const [idOfNewOwner, setIdOfNewOwner] = useState<string | null>(null);

  useEffect(() => {
    setOwners(associates.filter((associate) => isOwner(associate)));
    setRouteState((prev) => ({
      ...prev,
      hasSimpleOwnership: onlyDirectOwnersWithFullOwnership(associates),
    }));
  }, [associates, setRouteState]);

  const addOwner = useCallback(() => {
    setIdOfNewOwner(generateId());
  }, []);

  const infoOfTotalShares = useMemo(() => {
    const shares = getTotalOwnershipShares(owners);

    return {
      hideButton:
        shares.totalCapitalStake >= 100 || shares.totalVotingRights >= 100,
      showError:
        shares.totalCapitalStake > 100 || shares.totalVotingRights > 100,
    };
  }, [owners]);

  return (
    <>
      <Visible show={hasMajorityOwners !== undefined}>
        <div className="m-top-10 flex-columns gap-20">
          {owners.map((owner) => (
            <Card key={owner.associateId}>
              <OwnerOverlay
                owner={owner}
                owners={owners}
                associates={associates}
                hasMajorityOwners={false}
              />
            </Card>
          ))}

          <NewOwner
            id={idOfNewOwner}
            setId={setIdOfNewOwner}
            hasMajorityOwners={false}
            associates={associates}
          />
        </div>

        <div>
          {!owners.length && (
            <Trans t={t}>
              Please add all owners with an ownership stake equal to{" "}
              <b>25% or above</b>.
            </Trans>
          )}
        </div>

        {infoOfTotalShares.hideButton ? null : (
          <Button
            ghost
            className="m-top-40"
            block
            onClick={addOwner}
            disabled={infoOfTotalShares.hideButton}
          >
            <T>Add owner</T>
          </Button>
        )}
      </Visible>

      <Form
        name="owners"
        id="owners"
        className="flex-1"
        ref={ref}
        onSubmit={(_, form) => {
          if (!form.isValid) {
            return;
          }

          next();
        }}
      >
        <div className="story-errors">
          <HiddenInput
            label="Missing type of ownership"
            value={hasMajorityOwners !== undefined ? true : undefined}
            validators={[
              new RequiredValidator("You must choose an ownership structure"),
            ]}
            scrollToRef={ref}
          />

          <HiddenInput
            label="Missing owners"
            value={owners.length ? true : undefined}
            validators={[
              new RequiredValidator("There must be at least one owner"),
            ]}
            scrollToRef={ref}
          />

          <HiddenInput
            label="Completed owners"
            value={
              owners.every((owner) => isComplete(owner, false))
                ? true
                : undefined
            }
            validators={[
              new RequiredValidator(
                "There are one or a few owners that are incomplete"
              ),
            ]}
            scrollToRef={ref}
          />

          <HiddenInput
            label="Ownership overflow"
            value={infoOfTotalShares.showError ? undefined : true}
            validators={[
              new RequiredValidator("Ownerships shares are exceeding 100%"),
            ]}
            scrollToRef={ref}
          />
        </div>
        <div className="m-top-30">
          <StoryContinueButton disabled={access === Access.VIEW} type="submit">
            <T>Continue</T>
          </StoryContinueButton>
        </div>
      </Form>
    </>
  );
};
