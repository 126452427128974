import { Logo } from "../../../components/images/Logo";
import { T } from "../../../components/translation/T";
import styles from "./Top.module.scss";

export function Top() {
  return (
    <div className={styles.top}>
      <div className={styles.logo}>
        <Logo />
      </div>
      <div className={styles.list}>
        <T>Application</T>
      </div>
    </div>
  );
}
