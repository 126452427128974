import {
  AssociateId,
  CompanyRegistrationId,
  Language,
  LegalEntityType,
  NO_COUNTRY,
  ProductType,
} from "./../data/models/ContractTypes";
import { atom } from "recoil";
import { ContractVersion, Country } from "../data/models/ContractTypes";
import {
  Contract,
  ContractData,
  ContractFeature,
  ViewerStatus,
} from "../data/dataMerchant";

export const defaultContract: ContractData = {
  version: 0 as ContractVersion,
  country: Country.SWEDEN,
  companyName: "",
  organizationNumber: "" as CompanyRegistrationId,
  address: {
    street: "",
    postalCode: "",
    city: "",
    countryCode: NO_COUNTRY,
  },
  invoiceAddress: {
    street: "",
    postalCode: "",
    city: "",
    countryCode: NO_COUNTRY,
  },
  website: "",
  mccIndustry: "",
  legalEntityType: LegalEntityType.LIMITED,
};

export const contractState = atom<Contract>({
  key: "contract-state",
  default: {
    productType: ProductType.BAMBORA_ONE,
    contractData: defaultContract,
    enabledFeatures: [],
    confirmedContract: false,
    contractViewer: {
      associateId: "" as AssociateId,
      language: Language.ENGLISH,
      status: ViewerStatus.INFORMATION,
    },
  },
});

export function hasFeature(
  feature: ContractFeature,
  features: ContractFeature[]
) {
  return features.indexOf(feature) > -1;
}
