import React, { ReactNode } from "react";
import styles from "./ContractSection.module.scss";

interface Props {
  name: string | ReactNode;
  children: ReactNode;
}

export const ContractSection: React.FunctionComponent<Props> = ({
  name,
  children,
}) => {
  return (
    <div className={styles.section}>
      <div className={styles.header}>{name}</div>
      <div className={styles.wrapper}>{children}</div>
    </div>
  );
};
