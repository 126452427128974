import React, { ReactNode } from "react";
import cx from "classnames";
import styles from "./Split.module.scss";

interface Props {
  children: ReactNode;
}

export const Split: React.FunctionComponent<Props> = ({ children }) => {
  return <div className={cx(styles.split)}>{children}</div>;
};
