import { DateTimeFormatOptions } from "luxon";
import { nanoid } from "nanoid";
import { Language, UTCDate } from "../data/models/ContractTypes";
import i18n from "../i18n";
import countries from "./countries.json";
import { Alternative } from "./interactions/InputTypes";

export const MISSING_DATE = "-";

const displayNamesCache: Record<string, Intl.DisplayNames> = {};

export function isValidDate(d: any) {
  return d instanceof Date && !isNaN(d as any);
}

export function getIntlNumberFormat(
  lang: string,
  number: number,
  decimals: number = 0,
  style?: "percent"
) {
  return new Intl.NumberFormat(lang, {
    style,
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  }).format(number);
}

export const getCountryDisplayName = (
  country: string,
  currentLanguage?: Language | string
) => {
  const lang =
    currentLanguage || i18n.language
      ? i18n.language
      : Language.ENGLISH.toString();

  if (!displayNamesCache[lang]) {
    displayNamesCache[lang] = new Intl.DisplayNames(lang, {
      type: "region",
    });
  }

  return displayNamesCache[lang].of(country) || country;
};

export function getCountryOpts(
  t: any,
  language: Language | string = i18n.language,
  allowEmpty: boolean = false,
  placeholder?: string | React.ReactNode,
  availableCountries?: string[],
  excludedCountries?: string[]
) {
  let countryList;

  if (availableCountries) {
    countryList = availableCountries.map(
      (country): Alternative<string> => ({
        value: country,
        text: getCountryDisplayName(country, language),
        disabled: false,
      })
    );
  } else {
    countryList = Object.keys(countries).map(
      (country): Alternative<string> => ({
        value: country,
        text: getCountryDisplayName(country, language),
        disabled: false,
      })
    );
  }

  if (excludedCountries) {
    countryList = countryList.filter(
      (country) => !excludedCountries.includes(country.value)
    );
  }

  if (allowEmpty) {
    countryList.unshift({
      value: "",
      text: placeholder || t("None"),
      disabled: false,
    });
  } else {
    countryList.unshift({
      value: "",
      text: t("Select a country"),
      disabled: true,
    });
  }

  return countryList;
}

export function id() {
  return nanoid();
}

export const cleanNonDigits = <T extends string>(value: T): T => {
  return value.replace(/\D/g, "") as T;
};

export const getDecimalSeparator = (locale?: string) =>
  Intl.NumberFormat(locale)
    .formatToParts(1.1)
    .find((part) => part.type === "decimal")?.value ?? ".";

export function getIntlDate(
  date?: Date | string | UTCDate,
  lang?: Language,
  options?: DateTimeFormatOptions
) {
  if (!date) {
    return MISSING_DATE;
  }

  const dateObject = new Date(date);

  if (isValidDate(dateObject)) {
    return new Intl.DateTimeFormat(lang || Language.ENGLISH, options).format(
      dateObject
    );
  } else {
    return MISSING_DATE;
  }
}

export function getIntlCost(
  lang: string,
  number: number,
  currency: string,
  decimals: number = 0
) {
  return new Intl.NumberFormat(lang, {
    style: "currency",
    currency,
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  }).format(number);
}

export function getIntlPercent(
  lang: string,
  number: number,
  decimals: number = 0
) {
  try {
    return new Intl.NumberFormat(lang, {
      style: "percent",
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals,
    }).format(number / 100);
  } catch (err) {
    return "- %";
  }
}
