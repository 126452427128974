import { useEffect, useRef } from "react";
import cx from "classnames";
import { Route, Routes } from "react-router-dom";
import {
  KlarnaTestPage,
  KLARNA_TEST_PAGE_URL,
} from "./pages/Klarna/KlarnaTestPage";
import { useRecoilState, useRecoilValue } from "recoil";
import { contractState } from "./state/contractState";
import { Country } from "./data/models/ContractTypes";
import { InvalidLink } from "./pages/InvalidLink";
import {
  DataCollectionStory,
  DATA_COLLECTION_STORY_URL,
} from "./pages/DataCollection/DataCollectionStory";
import { ContractLoader } from "./pages/DataCollection/ContractLoader";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ErrorBoundary } from "@sentry/react";
import {
  SUPPLEMENTS_STORY_URL,
  SupplementsCollection,
} from "./pages/SupplementsCollection/SupplementsCollection";
import {
  SIGNATORY_URL,
  SignatoryCollection,
} from "./pages/SignatoryCollection/SignatoryCollection";
import {
  DOCUMENT_URL,
  DocumentCollection,
} from "./pages/DocumentCollection/DocumentCollection";
import {
  PAYOUT_ACCOUNT_URL,
  PayoutAccountCollectionStory,
} from "./pages/PayoutAccountCollection/PayoutAccountCollectionStory";
import { DONE_URL, Done } from "./pages/Done/Done";
import { translationStateActiveSelector } from "./components/translation/translationState";
import { TranslationManagement } from "./components/translation/TranslationManagement";
import { useQuery } from "./hooks/useQuery";
import { Analytics } from "./Analytics";
import { VIEW_STORY_URL, ViewLoader } from "./pages/ViewStory/ViewLoader";
import { ViewStory } from "./pages/ViewStory/ViewStory";
import { routeState } from "./state/routeState";
import {
  GENERIC_SUCCESS_URL,
  GenericSuccess,
} from "./pages/GenericSuccess/GenericSuccess";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      networkMode: "always",
      refetchOnWindowFocus: false,
    },
  },
});

export const OVERLAY_PORTAL_ID = "overlay-portal";
const TRANSLATE_QUERY_PARAM = "translate";

function App() {
  const contract = useRecoilValue(contractState);
  const { viewOnly } = useRecoilValue(routeState);
  const prevCountry = useRef<Country>(Country.SWEDEN);

  const [translationMode, setTranslationMode] = useRecoilState(
    translationStateActiveSelector
  );
  const query = useQuery();
  const editTranslations = query.get(TRANSLATE_QUERY_PARAM) === "1";

  useEffect(() => {
    if (!editTranslations) {
      // If set we won't remove it
      return;
    }

    setTranslationMode(editTranslations);
  }, [setTranslationMode, editTranslations]);

  useEffect(() => {
    const { country } = contract.contractData;
    if (!country) {
      return;
    }

    // cover React Portal
    document.body.classList.remove(prevCountry.current);
    prevCountry.current = country;
    document.body.classList.add(country);
  }, [contract.contractData]);

  return (
    <div
      className={cx("app", {
        viewOnly,
      })}
    >
      {translationMode && <TranslationManagement />}
      <div id={OVERLAY_PORTAL_ID} />
      <Analytics />
      <ErrorBoundary showDialog={false}>
        <QueryClientProvider client={queryClient}>
          <Routes>
            <Route
              path={SUPPLEMENTS_STORY_URL}
              element={<SupplementsCollection />}
            />

            <Route element={<ViewLoader />}>
              <Route path={`${VIEW_STORY_URL}*`} element={<ViewStory />} />
            </Route>

            <Route element={<ContractLoader />}>
              <Route path={SIGNATORY_URL} element={<SignatoryCollection />} />

              <Route path={DOCUMENT_URL} element={<DocumentCollection />} />

              <Route path={DONE_URL} element={<Done />} />

              <Route
                path={`${PAYOUT_ACCOUNT_URL}*`}
                element={<PayoutAccountCollectionStory />}
              />

              <Route
                path={`${DATA_COLLECTION_STORY_URL}*`}
                element={<DataCollectionStory />}
              />
            </Route>

            <Route path={KLARNA_TEST_PAGE_URL} element={<KlarnaTestPage />} />
            <Route path={GENERIC_SUCCESS_URL} element={<GenericSuccess />} />
            <Route path="*" element={<InvalidLink />} />
          </Routes>
        </QueryClientProvider>
      </ErrorBoundary>
    </div>
  );
}

export default App;
