import cx from "classnames";
import { motion } from "framer-motion";
import { useCallback, useEffect, useState } from "react";
import { Address } from "../../data/models/ContractTypes";
import { Button } from "../interactions/Buttons/Button";
import { AddressForm } from "./AddressForm";
import { Status } from "../../data/types";
import { T } from "../translation/T";
import "./AddressPopover.scss";
import { ESC } from "../overlay/PersistantOverlay";

interface Props {
  address: Address;
  onChange: (address: Address) => void;
  disabled?: boolean;
  addressRequiredFields:
    | Record<keyof Address, boolean>
    | Record<keyof Omit<Address, "countryCode">, boolean>;
  showCountryCode?: boolean;
}

export const AddressPopover = ({ onChange, ...props }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="address-popover">
      <Button
        ghost
        block
        onClick={() => setIsOpen(true)}
        status={props.disabled ? Status.DISABLED : Status.DEFAULT}
        className="padded"
      >
        <T>Enter manually</T>
      </Button>
      {isOpen && (
        <AddressPopoverInner
          onClose={() => setIsOpen(false)}
          {...{ onChange, ...props }}
        />
      )}
    </div>
  );
};

type InnerProps = {
  onClose: () => void;
} & Props;

const AddressPopoverInner = ({
  onChange,
  onClose,
  address,
  ...props
}: InnerProps) => {
  const [innerAddress, setInnerAddress] = useState<Address>(() => address);

  const handler = useCallback(
    (ev: KeyboardEvent) => {
      if (ev.code === ESC) {
        (document.activeElement as HTMLElement).blur();
        onClose();
      }
    },
    [onClose]
  );

  useEffect(() => {
    window.addEventListener("keydown", handler, false);
    return () => {
      window.removeEventListener("keydown", handler, false);
    };
  }, [handler]);

  return (
    <>
      <motion.div
        className="address-popover__backdrop"
        onClick={onClose}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { duration: 0.2 } }}
      />
      <motion.div
        className={cx("address-popover__container", {
          "address-popover--upwards": false,
        })}
        initial={{ borderRadius: 0 }}
        animate={{ borderRadius: 30 }}
        transition={{ duration: 0.25 }}
      >
        <div className="address-popover__content">
          <div className="address-popover__header">
            <h5>
              <T>Address</T>
            </h5>
            <Button
              className="address-popover__button m-bottom-10"
              ghost
              onClick={() => {
                onClose();
              }}
            >
              <T>Close</T>
            </Button>
          </div>

          <div className="tablet-columns address-form">
            <AddressForm
              onChange={setInnerAddress}
              address={innerAddress}
              {...{
                ...props,
              }}
            />
          </div>

          <Button
            block
            onClick={() => {
              onChange(innerAddress);
              onClose();
            }}
          >
            <T>Save</T>
          </Button>
        </div>
      </motion.div>
    </>
  );
};
