import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form } from "../../../../../components/form/Form";
import { Select } from "../../../../../components/form/Select";
import { RequiredValidator } from "../../../../../components/form/validators/RequiredValidator";
import { Button } from "../../../../../components/interactions/Buttons/Button";
import { T } from "../../../../../components/translation/T";
import { getCountryOpts } from "../../../../../components/utils";
import {
  Pep,
  PEP_POSITION,
  PEP_POSITION_READABLE,
} from "../../../../../data/dataMerchant";

interface Props {
  pep: Pep;
  onChange: (pep: Pep) => void;
  onSubmit: () => void;
  onBack: () => void;
  isViewer: boolean;
}

export const PepPosition: React.FunctionComponent<Props> = ({
  pep,
  onChange,
  onSubmit,
  onBack,
}) => {
  const { t, i18n } = useTranslation();
  const countries = useMemo(
    () => getCountryOpts(t, i18n.language),
    [t, i18n.language]
  );
  const [values, setValues] = useState({ ...pep });

  const positionAlternatives = useMemo(() => {
    const alternatives = Object.values(PEP_POSITION).map((position) => ({
      value: position,
      text: t(PEP_POSITION_READABLE[position]),
      disabled: false,
    }));

    alternatives.unshift({
      text: t("Select position"),
      value: "" as PEP_POSITION,
      disabled: true,
    });

    return alternatives;
  }, [t]);

  return (
    <div className="m-top-30">
      <Form
        name="pepPosition"
        onSubmit={(_, form) => {
          if (form.isInvalid) {
            return;
          }

          onChange({
            ...pep,
            ...values,
            type: pep.type, // if user has added another type but backs up,
            // the incorrect type is stored in the state. It is okey to
            // cache/reuse the other values, but, needless to say, not the type
          });
          onSubmit();
        }}
      >
        <div className="m-top-20">
          <Select
            onChange={(value) => {
              setValues({
                ...values,
                position: value,
              });
            }}
            name="position"
            label="Position of PEP"
            alternatives={positionAlternatives}
            value={values.position || ("" as PEP_POSITION)}
            validators={[new RequiredValidator("Required")]}
          />
        </div>
        <div className="m-top-20">
          <Select
            onChange={(value) => {
              setValues({
                ...values,
                country: value,
              });
            }}
            name="country"
            label="Country in which the PEP position was held"
            alternatives={countries}
            value={values.country}
            validators={[new RequiredValidator("Nationality is required")]}
          />
        </div>
        <div className="m-top-30 columns">
          <div>
            <Button onClick={onBack} ghost>
              <T>Back</T>
            </Button>
          </div>
          <div className="text-right">
            <Button type="submit">
              <T>Next</T>
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
};
