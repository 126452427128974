import React from "react";
import { HiddenInput } from "../../../../components/form/HiddenInput";
import { RequiredValidator } from "../../../../components/form/validators/RequiredValidator";
import { Associate } from "../../../../data/dataMerchant";

interface Props {
  owner: Associate;
  formRef: React.MutableRefObject<HTMLFormElement | null>;
}

export const ValidationErrors: React.FunctionComponent<Props> = ({
  owner,
  formRef,
}) => {
  if (!owner.owner?.capitalStake && !owner.owner?.votingRightsStake) {
    return (
      <HiddenInput
        label="ownership"
        value={undefined}
        validators={[
          new RequiredValidator(
            'Either "Ownership shares" or "Voting rights" must be added'
          ),
        ]}
        scrollToRef={formRef}
      />
    );
  }

  if (
    (owner.owner?.capitalStake || 0) > 0 &&
    (owner.owner?.votingRightsStake || 0) > 0
  ) {
    return (
      <HiddenInput
        label="shares"
        value={undefined}
        validators={[
          new RequiredValidator(
            'Leave either "Ownership shares" or "Voting rights" empty'
          ),
        ]}
        scrollToRef={formRef}
      />
    );
  }

  if (
    (owner.owner?.capitalStake || 0) < 25 &&
    (owner.owner?.votingRightsStake || 0) < 25
  ) {
    return (
      <HiddenInput
        label="Min value"
        value={undefined}
        validators={[
          new RequiredValidator(
            '"Ownership shares" or "Voting rights" must exceed 25%'
          ),
        ]}
        scrollToRef={formRef}
      />
    );
  }

  if (
    (owner.owner?.capitalStake || 0) > 100 ||
    (owner.owner?.votingRightsStake || 0) > 100
  ) {
    return (
      <HiddenInput
        label="Max value"
        value={undefined}
        validators={[
          new RequiredValidator(
            '"Ownership shares" or "Voting rights" must not exceed 100%'
          ),
        ]}
        scrollToRef={formRef}
      />
    );
  }

  return null;
};
