import { useTranslation } from "react-i18next";
import { NumberRangeInput } from "../../../components/form/NumberRangeInput";
import { GreaterThanValidator } from "../../../components/form/validators/GreaterThanValidator";
import { LessThanValidator } from "../../../components/form/validators/LessThanValidator";
import { RequiredValidator } from "../../../components/form/validators/RequiredValidator";
import { getIntlNumberFormat } from "../../../components/utils";
import { FinancialData } from "../../../data/dataFinancial";
import { useCountry } from "../../../hooks/useCountry";
import { CurrencyByCountry } from "../../../i18n";
import { Country } from "../../../data/models/ContractTypes";

interface Props
  extends Pick<
    Partial<FinancialData>,
    | "estimatedRangeOfTransactionValueMin"
    | "estimatedRangeOfTransactionValueMax"
  > {
  onChange: (minValue?: number, maxValue?: number) => void;
  disabled?: boolean;
}

const defaultExampleValues = {
  [Country.DENMARK]: 100000,
  [Country.FINLAND]: 10000,
  [Country.GB]: 10000,
  [Country.NORWAY]: 100000,
  [Country.SWEDEN]: 100000,
};

export const EstimatedRangeFields: React.FunctionComponent<Props> = ({
  onChange,
  estimatedRangeOfTransactionValueMin,
  estimatedRangeOfTransactionValueMax,
  disabled,
}) => {
  const country = useCountry();
  const { i18n } = useTranslation();
  const { language } = i18n;

  return (
    <NumberRangeInput
      label="Estimated minimum and maximum value per purchase"
      values={[
        estimatedRangeOfTransactionValueMin,
        estimatedRangeOfTransactionValueMax,
      ]}
      name="rangeOfTransactions"
      onChange={onChange}
      hints={["Minimum purchase", "Maximum purchase"]}
      messages={["Minimum purchase", "Maximum purchase"]}
      sharedValidators={[
        new GreaterThanValidator(0, "Value must be greater than 0"),
      ]}
      minValidators={[
        new RequiredValidator("Minimum purchase is required"),
        ...(typeof estimatedRangeOfTransactionValueMax !== "undefined"
          ? [
              new LessThanValidator(
                estimatedRangeOfTransactionValueMax,
                "Must be less than maximum purchase"
              ),
            ]
          : []),
      ]}
      maxValidators={[
        new RequiredValidator("Maximum purchase is required"),
        ...(typeof estimatedRangeOfTransactionValueMin !== "undefined"
          ? [
              new GreaterThanValidator(
                estimatedRangeOfTransactionValueMin,
                "Must be greater than minimum purchase"
              ),
            ]
          : []),
      ]}
      placeholders={[
        `${getIntlNumberFormat(language, 1)} ${CurrencyByCountry[country]}`,
        `${getIntlNumberFormat(language, defaultExampleValues[country])} ${
          CurrencyByCountry[country]
        }`,
      ]}
      suffix={" " + CurrencyByCountry[country]}
      min={1}
      disabled={disabled}
    />
  );
};
