import React, { useState, useCallback, ReactNode } from "react";
import cx from "classnames";
import { T } from "../translation/T";
import { Trash } from "../icons/Trash";
import { Button } from "../interactions/Buttons/Button";
import styles from "./EditRowRemove.module.scss";
import { Spinner } from "../spinner/Spinner";
import { ErrorBox } from "../boxes/ErrorBox";
import { AnimateHeight } from "../animate/AnimateHeight";
import { Status } from "../../data/types";

interface Props {
  onRemove: () => Promise<unknown>;
  onWarning?: (isVisible: boolean) => void;
  data?: any;
  children: ReactNode;
  disabled?: boolean;
}

export const EditRowRemove: React.FunctionComponent<Props> = ({
  onRemove,
  data,
  children,
  onWarning,
  disabled,
}) => {
  const [showWarning, setShowWarning] = useState<boolean>(false);
  const [status, setStatus] = useState<Status>(Status.DEFAULT);

  const tryRemove = useCallback(() => {
    onWarning && onWarning(true);
    setShowWarning(true);
  }, [onWarning]);

  const internalRemove = useCallback(() => {
    setStatus(Status.PENDING);
    setTimeout(() => {
      onRemove()
        .then(() => {
          onWarning && onWarning(false);
          setShowWarning(false);
          setStatus(Status.DEFAULT);
          return data;
        })
        .catch(() => {
          setStatus(Status.ERROR);
        });
    }, 300);
  }, [data, onRemove, onWarning]);

  return (
    <>
      <div
        className={cx(styles.remove, {
          [styles.warning]: showWarning,
        })}
      >
        <div
          className={styles.backdrop}
          onClick={() => {
            onWarning && onWarning(false);
            setStatus(Status.DEFAULT);
            setShowWarning(false);
          }}
        />
        <div className={styles.warningBody}>
          <div
            className={cx(styles.loading, {
              [styles.active]: status === Status.PENDING,
            })}
          >
            <Spinner />
          </div>
          <div className={styles.removeText}>{children}</div>

          <AnimateHeight name={status === Status.ERROR ? "error" : ""}>
            <div className={styles.removeError}>
              {status === Status.ERROR && (
                <ErrorBox relative>
                  <b>
                    <T>Oh no!</T>
                  </b>{" "}
                  <T>Something went wrong. Try again?</T>
                </ErrorBox>
              )}
            </div>
          </AnimateHeight>
          <div className={cx(styles.removeButtons)}>
            <Button
              ghost
              className="small"
              onClick={() => {
                onWarning && onWarning(false);
                setStatus(Status.DEFAULT);
                setShowWarning(false);
              }}
            >
              <T>Wait, no</T>
            </Button>
            <Button className="danger-button small" onClick={internalRemove}>
              <T>Yes, remove</T>
            </Button>
          </div>
        </div>
      </div>

      <Button
        stopPropagation
        action
        className="danger-button small"
        onClick={tryRemove}
        disabled={disabled}
      >
        <Trash />
      </Button>
    </>
  );
};
