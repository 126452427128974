import React, { ReactNode } from "react";
import cx from "classnames";
import styles from "./Field.module.scss";

interface Props {
  name: string | ReactNode;
  children?: ReactNode;
}

export const Field: React.FunctionComponent<Props> = ({ name, children }) => {
  return (
    <div className={styles.row}>
      <div className={styles.value}>{children}</div>
      <div className={cx("text-mini", styles.name)}>{name}</div>
    </div>
  );
};
