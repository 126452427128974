import React, { useCallback, useMemo } from "react";
import cx from "classnames";
import { TextInput } from "../../../../components/form/TextInput";
import { RequiredValidator } from "../../../../components/form/validators/RequiredValidator";
import { Associate, AssociateRole, Id } from "../../../../data/dataMerchant";
import { cleanNonDigits, getCountryOpts } from "../../../../components/utils";
import { Select } from "../../../../components/form/Select";
import { CountryChip } from "../../../../components/chip/CountryChip";
import { Country } from "../../../../data/models/ContractTypes";
import { HiddenInput } from "../../../../components/form/HiddenInput";
import { Form } from "../../../../components/form/Form";
import { StepButtons } from "../../../../components/steps/StepButtons";
import { T } from "../../../../components/translation/T";
import { Dropdown } from "../../../../components/interactions/Dropdown/Dropdown";
import { isSignee } from "../../../../data/dataAssociates";
import { useTranslation } from "react-i18next";
import { Status } from "../../../../data/types";
import { MaxLengthValidator } from "../../../../components/form/validators/MaxLengthValidator";
import { MinLengthValidator } from "../../../../components/form/validators/MinLengthValidator";
import styles from "../OwnerOverlay.module.scss";
import { Validations } from "../../Signatories/components/forms/AssociateIdFields";
import { useCountry } from "../../../../hooks/useCountry";

interface Props {
  owner: Associate;
  setOwner: React.Dispatch<React.SetStateAction<Associate>>;
  formRef: React.RefObject<HTMLFormElement>;
  isViewer: boolean;
  setIndex: React.Dispatch<React.SetStateAction<number>>;
  associates: Associate[];
}

export function getMatches(id: string, associates: Associate[]) {
  const strippedId = cleanNonDigits(id);
  return associates.filter(
    (associate) =>
      isSignee(associate) && associate.id?.nationalPersonId === strippedId
  );
}

function getCitizenships(prev: Associate, signee: Associate) {
  if (prev.id?.citizenships && prev.id.citizenships.length > 0) {
    return prev.id.citizenships;
  }

  if (signee.id?.citizenships && signee.id.citizenships.length > 0) {
    return signee.id.citizenships;
  }

  return [];
}

export const CitizenshipAndSSN: React.FunctionComponent<Props> = ({
  owner,
  setOwner,
  // onRemoveCountry,
  formRef,
  isViewer,
  setIndex,
  // setValues,
  associates,
}) => {
  const { t, i18n } = useTranslation();
  const country = useCountry();

  const onSSNBlur = useCallback(() => {
    const potentialMatches = getMatches(
      owner.id.nationalPersonId || "-1",
      associates
    );

    if (potentialMatches.length !== 1) {
      return;
    }

    const matchingSignee = potentialMatches[0];

    // We cherry pick some roaring values
    // ("firstName", "lastName", "roles")
    setOwner((prev) => ({
      ...matchingSignee,
      ...prev,
      contact: {
        ...prev.contact,
        firstName: prev.contact.firstName || matchingSignee.contact.firstName,
        lastName: prev.contact.lastName || matchingSignee.contact.lastName,
      },
      roles: matchingSignee.roles.includes(AssociateRole.BENEFICIAL_OWNER)
        ? matchingSignee.roles
        : [...matchingSignee.roles, AssociateRole.BENEFICIAL_OWNER],
      id: {
        ...prev.id,
        citizenships: getCitizenships(prev, matchingSignee),
        countryOfBirth:
          prev.id.countryOfBirth ||
          matchingSignee.id.countryOfBirth ||
          ("" as Country),
        countryOfResidence:
          prev.id.countryOfResidence ||
          matchingSignee.id.countryOfResidence ||
          ("" as Country),
      },
    }));
  }, [associates, owner, setOwner]);

  const onSSNChange = useCallback(
    (value: string, name: string) => {
      setOwner((prev) => ({
        ...prev,
        id: {
          ...prev.id,
          nationalPersonId: value,
        },
      }));
    },
    [setOwner]
  );

  const countries = useMemo(
    () =>
      getCountryOpts(
        t,
        i18n.language,
        false,
        undefined,
        undefined,
        owner.id.citizenships
      ),
    [owner.id.citizenships, t, i18n.language]
  );

  const residences = useMemo(
    () => getCountryOpts(t, i18n.language, false, null),
    [i18n.language, t]
  );

  const onRemove = useCallback(
    (countryCode: string) => {
      setOwner((prev) => ({
        ...prev,
        id: {
          ...prev.id,
          citizenships: (prev.id.citizenships || []).filter(
            (citizenship) => citizenship !== countryCode
          ),
        },
      }));
    },
    [setOwner]
  );

  const numberOfCitizenships = owner.id.citizenships?.length ?? 0;

  const ssnValidationsByCountry = useMemo(
    () => Validations[country],
    [country]
  );

  return (
    <div className="m-top-20">
      <Form
        onSubmit={(_, form) => {
          if (form.isInvalid) {
            return;
          }

          setIndex((prev) => prev + 1);
        }}
      >
        <br />
        {isViewer ? (
          <T>We need to know a litte more about you as an owner.</T>
        ) : (
          <T>We need to know a litte more about this owner.</T>
        )}
        <div className="m-top-20">
          <TextInput
            label="Social security number"
            onChange={onSSNChange}
            onBlur={onSSNBlur}
            value={owner.id.nationalPersonId}
            name="nationalPersonId"
            hint={<>{ssnValidationsByCountry.hint}</>} // yes, ugly, but we need to pass a ReactNode into the input in order to prevent default translations
            placeholder={`${t("E.g.")} "${
              ssnValidationsByCountry.placeholder
            }"`}
            validators={[
              new RequiredValidator("Social security number is required"),
              new MinLengthValidator(
                ssnValidationsByCountry.hint.length,
                (
                  <T
                    id="At least {{length}} characters are required ({{hint}})"
                    options={{
                      length: ssnValidationsByCountry.hint.length,
                      hint: ssnValidationsByCountry.hint,
                    }}
                  />
                )
              ),
              new MaxLengthValidator(
                ssnValidationsByCountry.hint.length,
                (
                  <T
                    id="Not more than {{length}} characters are allowed ({{hint}})"
                    options={{
                      length: ssnValidationsByCountry.hint.length,
                      hint: ssnValidationsByCountry.hint,
                    }}
                  />
                )
              ),
            ]}
          />
        </div>

        <div className="m-top-20">
          <Select
            onChange={(value) => {
              setOwner((prev) => ({
                ...prev,
                id: {
                  ...prev.id,
                  countryOfResidence: value,
                } as Id,
              }));
            }}
            name="countryOfResidence"
            label="Add residence"
            alternatives={residences}
            value={owner.id.countryOfResidence}
            validators={[
              new RequiredValidator("You must select country of residence"),
            ]}
          />

          <div className="m-top-10">
            <Select
              name="countryOfBirth"
              label="Place of birth (Country)"
              alternatives={residences}
              value={owner.id.countryOfBirth}
              onChange={(value) => {
                setOwner((prev) => ({
                  ...prev,
                  id: {
                    ...prev.id,
                    countryOfBirth: value,
                  } as Id,
                }));
              }}
              validators={[
                new RequiredValidator("You must select a place of birth"),
              ]}
            />
          </div>

          <div className="m-top-20">
            <Dropdown
              required={owner.id.citizenships?.length ? false : true}
              onChange={(value) => {
                setOwner((prev) => ({
                  ...prev,
                  id: {
                    ...prev.id,
                    citizenships: [...(prev.id.citizenships || []), value],
                  },
                }));
              }}
              name="citizenships"
              label="Add all citizenships"
              className={cx("compact", {
                "was-successful":
                  numberOfCitizenships > 0 && numberOfCitizenships !== 3,
              })}
              alternatives={countries}
              value=""
              status={
                numberOfCitizenships === 3 ? Status.DISABLED : Status.DEFAULT
              }
            />

            <div className={styles.chips}>
              {owner.id.citizenships?.map((citizenship) => {
                return (
                  <CountryChip
                    key={citizenship}
                    country={citizenship as Country}
                    onRemove={onRemove}
                    data={citizenship}
                  />
                );
              })}
            </div>

            <HiddenInput
              label="Citizenship"
              value={owner.id.citizenships?.length ? true : undefined}
              validators={[
                new RequiredValidator(
                  "You must select at least one citizenship"
                ),
              ]}
              scrollToRef={formRef}
            />
          </div>
        </div>
        <div className="m-top-30">
          <StepButtons showBack={false} setIndex={setIndex} />
        </div>
      </Form>
    </div>
  );
};
