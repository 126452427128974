import React from "react";
import {
  AnimateHeight,
  Speed,
} from "../../../../components/animate/AnimateHeight";
import { ErrorBox } from "../../../../components/boxes/ErrorBox";
import { StepButtons } from "../../../../components/steps/StepButtons";
import { T } from "../../../../components/translation/T";
import { routeState } from "../../../../state/routeState";
import { useRecoilValue } from "recoil";
import { Access } from "../../../../data/proxy";

interface Props {
  index: number;
  setIndex: React.Dispatch<React.SetStateAction<number>>;
  onSave: () => void;
  onRetry: () => void;
  isLoading?: boolean;
  isError?: boolean;
}

export const Done: React.FunctionComponent<Props> = ({
  onSave,
  index,
  setIndex,
  isError,
  isLoading,
  onRetry,
}) => {
  const { access } = useRecoilValue(routeState);

  return (
    <div className="m-top-30">
      <b>
        <T>Great!</T>
      </b>
      <p>
        <T>Thank you for providing the information.</T>
      </p>

      <AnimateHeight speed={Speed.FAST} name={isError ? "error" : ""}>
        <div>
          {isError && (
            <ErrorBox relative>
              <b>
                <T>Oh no!</T>
              </b>{" "}
              <T>Something went wrong. Try again?</T>
            </ErrorBox>
          )}
        </div>
      </AnimateHeight>

      <div className="m-top-30">
        <StepButtons
          setIndex={setIndex}
          onSave={isError ? onRetry : onSave}
          disabled={isLoading || access === Access.VIEW}
        />
      </div>
    </div>
  );
};
