import React from "react";
import cx from "classnames";
import styles from "./LanguageSwitch.module.scss";
import { T } from "../translation/T";
import { Country, Language } from "../../data/models/ContractTypes";
import { getDefaultCountryByLanguage } from "../translation/i18nUtils";
import { Flag } from "../flags/Flag";
import { useTranslation } from "react-i18next";

const SUPPORTED_LANGUAGES = [
  Language.SWEDISH,
  Language.ENGLISH,
  Language.NORWEGIAN,
  Language.DANISH,
  Language.FINNISH,
];

export const LanguageSwitch: React.FunctionComponent = () => {
  const { i18n } = useTranslation();
  const { language } = i18n;

  return (
    <div className={styles.wrapper}>
      <T>Language</T>:
      <ul className={styles.list}>
        {Object.values(Language)
          .filter((lang) => SUPPORTED_LANGUAGES.includes(lang))
          .map((item) => {
            const country = getDefaultCountryByLanguage(item);
            return (
              <li
                key={item}
                className={cx(styles.lang, {
                  [styles.active]: language === item,
                })}
              >
                <button
                  onClick={() => {
                    i18n.changeLanguage(item);
                  }}
                  className={styles.button}
                >
                  <Flag country={country as Country} rounded height={26} />
                </button>
              </li>
            );
          })}
      </ul>
    </div>
  );
};
